import { urls, userName } from "cfg-base/src";
import * as types from "cfg-base/src/types";
import { SearchLicense } from "cfg-base/src/types/search";
import { Alert, AnchorButton, Button, Highlight, LoadingSpinner, Table } from "components/src";
import * as React from "react";
import {
	masqueradeLoginURL,
	searchCustomerURL,
	searchInvoiceURL,
	searchLicenseURL,
	searchUserURL,
} from "../lib/search";
import { InternalSearcherConfig } from "../molecules/InternalSearcher";

export const DEFAULT_USER_SEARCHER_CONFIG: InternalSearcherConfig<"user"> = {
	endpoint: "search/users",
	name: "users",
	columns: [
		{ name: "User ID", selector: "uid", sortable: true },
		{ name: "Firstname", selector: "firstname", sortable: true },
		{ name: "Lastname", selector: "lastname", sortable: false },
		{ name: "Email", selector: "email", sortable: true },
	],
	initialOrder: {
		column: "email",
		direction: "ASC",
	},
	initialPagination: {
		page: 1,
		nrOfItems: 10,
	},
	initialSearch: "",
	initialData: [],
	initialActive: true,

	render: (users, loading, error, search, t, user) => {
		return (
			<SearchUserList
				key={"userlist"}
				users={users}
				loading={loading}
				error={error}
				search={search}
				t={t}
				user={user}
			/>
		);
	},
};

export const DEFAULT_CUSTOMER_SEARCHER_CONFIG: InternalSearcherConfig<"customer"> = {
	endpoint: "search/customers",
	name: "customers",
	columns: [
		{ name: "Customer ID", selector: "id", sortable: true },
		{ name: "name", selector: "name", sortable: true },
		{ name: "VatNo", selector: "vatNo" },
	],
	initialOrder: {
		column: "id",
		direction: "ASC",
	},
	initialPagination: {
		page: 1,
		nrOfItems: 10,
	},
	initialData: [],
	initialActive: true,

	render: (customers, loading, error, search, t) => {
		return (
			<SearchCustomerList
				key={"customerlist"}
				customers={customers}
				loading={loading}
				error={error}
				search={search}
				t={t}
			/>
		);
	},
};

export const DEFAULT_INVOICES_SEARCHER_CONFIG: InternalSearcherConfig<"invoice"> = {
	endpoint: "search/invoices",
	name: "invoices",
	columns: [
		{ name: "invoice Id", selector: "invoiceId", sortable: true },
		{ name: "Currency", selector: "currency" },
	],
	initialOrder: {
		column: "invoiceId",
		direction: "ASC",
	},
	initialPagination: {
		page: 1,
		nrOfItems: 10,
	},
	initialData: [],
	initialActive: true,

	render: (invoices, loading, error, search, t) => {
		return (
			<SearchInvoiceList
				key={"invoicelist"}
				invoices={invoices}
				loading={loading}
				error={error}
				search={search}
				t={t}
			/>
		);
	},
};

export const DEFAULT_CET_LICENSES_SEARCHER_CONFIG: InternalSearcherConfig<"license"> = {
	endpoint: "search/licenses",
	name: "licenses",
	columns: [
		{ name: "Id", selector: "id", sortable: true },
		{ name: "Extension", selector: "extensionName" },
		{ name: "Valid to", selector: "validTo" },
		{ name: "Allocated to", selector: "allocatedTo" },
	],
	initialOrder: {
		column: "id",
		direction: "ASC",
	},
	initialPagination: {
		page: 1,
		nrOfItems: 10,
	},
	initialData: [],
	initialActive: true,

	render: (licenses, loading, error, search, t) => {
		return (
			<SearchLicensesList
				key={"licenselist"}
				licenses={licenses}
				loading={loading}
				error={error}
				search={search}
				t={t}
			/>
		);
	},
};

export const DEFAULT_CET_EXTENSIONS_SEARCHER_CONFIG: InternalSearcherConfig<"extension"> = {
	endpoint: "search/extensions",
	name: "cet-extensions",
	columns: [
		{ name: "Id", selector: "id", sortable: true },
		{ name: "Name", selector: "name" },
	],
	initialOrder: {
		column: "id",
		direction: "ASC",
	},
	initialPagination: {
		page: 1,
		nrOfItems: 10,
	},
	initialData: [],
	initialActive: true,

	render: (extensions, loading, error, search, t, user) => {
		return (
			<SearchCetExtensionList
				key={"extensionlist"}
				extensions={extensions}
				loading={loading}
				error={error}
				search={search}
				t={t}
				user={user}
			/>
		);
	},
};

function SearchUserList(props: {
	users: SearchUserLW[];
	loading: boolean;
	error: Option<string>;
	search?: string;
	t: Translator;
	user: Option<User>;
}) {
	const { users, loading, search, t } = props;

	const wrapper = React.useCallback((children: JSX.Element) => {
		return (
			<React.Fragment key={"userlist"}>
				<h2 className="ut-margin-top-2">Users</h2>
				{children}
			</React.Fragment>
		);
	}, []);

	if (loading) {
		return <LoadingSpinner color="black" />;
	}

	if (props.error) {
		return wrapper(<Alert type="error">{t(props.error)}</Alert>);
	}

	if (!users || users.length < 1) {
		return <></>;
	}

	return wrapper(
		<Table
			key={"userlist-table"}
			t={t}
			keys={["uid", "avatar", "name", "email", ""]}
			responsive="stacked"
		>
			{users.map((user, index) => {
				return (
					<tr key={"userlist-table-row-" + user.uid}>
						<td>
							<Highlight text={user.uid.toString()} search={search} />
						</td>
						<td>
							<img
								className="avatar"
								src={
									user.avatarURL
										? user.avatarURL
										: `${urls.STATIC_URL}/web/icons/avatar.svg`
								}
								alt={userName(user)}
							/>
						</td>
						<td>
							<Highlight text={userName(user)} search={search} />
						</td>
						<td>
							<Highlight text={user.email} search={search} />
						</td>
						<td className="">
							<div className="ut-flex ut-right">
								{user.canBeLoggedInAs ? (
									<AnchorButton
										class="ut-flex-shrink-0"
										href={masqueradeLoginURL(user.uid)}
										t={t}
										text="button.login_as_user"
										size="xs"
										visualType="primary"
									/>
								) : (
									<Button
										className="ut-flex-shrink-0"
										t={t}
										text="button.login_as_user"
										disabled={!user.canBeLoggedInAs}
										size="xs"
										visualType="primary"
										title="No permission"
									/>
								)}
								<AnchorButton
									class="ut-flex-shrink-0"
									href={searchUserURL(user.uid)}
									t={t}
									text="button.details"
									size="xs"
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</Table>
	);
}

function SearchCustomerList(props: {
	customers: CustomerLW[];
	loading: boolean;
	error: Option<string>;
	search?: string;
	t: Translator;
}) {
	const { customers, loading, search, t } = props;

	const wrapper = React.useCallback((children: JSX.Element) => {
		return (
			<React.Fragment key={"customerlist"}>
				<h2 className="ut-margin-top-2">Customers</h2>
				{children}
			</React.Fragment>
		);
	}, []);

	if (loading) {
		return wrapper(<LoadingSpinner color="black" />);
	}

	if (props.error) {
		return wrapper(<Alert type="error">{t(props.error)}</Alert>);
	}

	if (!customers || customers.length < 1) {
		return <></>;
	}

	return wrapper(
		<Table
			key={"customerlist-table"}
			t={t}
			keys={["customer_id", "name", "admins", ""]}
			responsive="stacked"
		>
			{customers.map((customer, index) => {
				const admins = customer.admins?.map((admin, index) => {
					return (
						<React.Fragment
							key={"customerlist-table-row-" + customer.id + "-admin-" + admin.uid}
						>
							<a key={index} href={searchUserURL(admin.uid)}>
								{userName(admin)} <i className="svg-icon mod-offsite mod-xs"></i>
							</a>
							<br />
						</React.Fragment>
					);
				});

				return (
					<tr key={"customerlist-table-row-" + customer.id}>
						<td>
							<Highlight text={customer.id.toString()} search={search} />
						</td>
						<td>
							<Highlight text={customer.name} search={search} />
						</td>
						<td>{admins}</td>
						<td>
							<div className="ut-right ut-flex">
								<AnchorButton
									class="ut-flex-shrink-0"
									href={searchCustomerURL(customer.id)}
									t={t}
									text="button.details"
									size="xs"
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</Table>
	);
}

function SearchInvoiceList(props: {
	invoices: SearchInvoiceLW[];
	loading: boolean;
	error: Option<string>;
	search?: string;
	t: Translator;
}) {
	const { invoices, loading, search, t } = props;

	const wrapper = React.useCallback((children: JSX.Element) => {
		return (
			<React.Fragment key={"invoicelist"}>
				<h2 className="ut-margin-top-2">Invoices</h2>
				{children}
			</React.Fragment>
		);
	}, []);

	if (loading) {
		return <LoadingSpinner color="black" />;
	}

	if (props.error) {
		return wrapper(<Alert type="error">{t(props.error)}</Alert>);
	}

	if (!invoices || invoices.length < 1) {
		return <></>;
	}

	return wrapper(
		<Table
			key={"invoicelist-table"}
			t={t}
			keys={[
				"invoice_id",
				"price",
				"status",
				"buyer",
				"created_at",
				"paid_at",
				"due_at",
				"suspend_at",
				"",
			]}
			responsive="stacked"
		>
			{invoices.map((invoice, index) => {
				return (
					<tr key={"invoicelist-table-row-" + invoice.invoiceId}>
						<td>
							<Highlight text={invoice.invoiceId.toString()} search={search} />
						</td>
						<td>{invoice.totalPriceFormatted}</td>
						<td>{invoice.status}</td>
						<td>
							<a key={index} href={searchUserURL(invoice.user.uid)}>
								{userName(invoice.user)}{" "}
								<i className="svg-icon mod-offsite mod-xs"></i>
							</a>
						</td>
						<td>{invoice.createdAtDate}</td>
						<td>{invoice.paidAtDate}</td>
						<td>{invoice.suspendsAtDate}</td>
						<td>
							{invoice.extendedDueAt ? invoice.extendedDueAtDate : invoice.dueAtDate}
						</td>
						<td>
							<div className="ut-right ut-flex">
								<AnchorButton
									class="ut-flex-shrink-0"
									href={searchInvoiceURL(invoice.invoiceId)}
									t={t}
									text="button.details"
									size="xs"
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</Table>
	);
}

function SearchLicensesList(props: {
	licenses: SearchLicense[];
	loading: boolean;
	error: Option<string>;
	search?: string;
	t: Translator;
}) {
	const { licenses, loading, search, t } = props;

	const wrapper = React.useCallback((children: JSX.Element) => {
		return (
			<React.Fragment key={"licenselist"}>
				<h2 className="ut-margin-top-2">Licenses</h2>
				{children}
			</React.Fragment>
		);
	}, []);

	if (loading) {
		return <LoadingSpinner color="black" />;
	}

	if (props.error) {
		return wrapper(<Alert type="error">{t(props.error)}</Alert>);
	}

	if (!licenses || licenses.length < 1) {
		return <></>;
	}

	return wrapper(
		<Table
			key={"licenselist-table"}
			t={t}
			keys={["platform", "license_id", "type", "validity", "valid_from", "valid_to", ""]}
			responsive="stacked"
		>
			{licenses.map((license, index) => {
				return (
					<tr key={"licenselist-table-row-" + license.id}>
						<td>{license.typeOfProduct}</td>
						<td>
							<Highlight text={license.id.toString()} search={search} />
						</td>
						<td>{license.type}</td>
						<td>{license.validity}</td>
						<td>
							<Highlight text={license.validFromDate} search={search} />
						</td>
						<td>
							<Highlight text={license.validToDate} search={search} />
						</td>
						<td>
							<div className="ut-right ut-flex">
								<AnchorButton
									class="ut-flex-shrink-0"
									href={searchLicenseURL(license.id)}
									t={t}
									text="button.details"
									size="xs"
								/>
							</div>
						</td>
					</tr>
				);
			})}
		</Table>
	);
}

function SearchCetExtensionList(props: {
	extensions: types.ExtensionLW[];
	loading: boolean;
	error: Option<string>;
	search?: string;
	t: Translator;
	user?: User;
}) {
	const { extensions, loading, search, t, user } = props;

	const wrapper = React.useCallback((children: JSX.Element) => {
		return (
			<React.Fragment key={"extensionlist"}>
				<h2 className="ut-margin-top-2">Extensions</h2>
				{children}
			</React.Fragment>
		);
	}, []);

	if (loading) {
		return <LoadingSpinner color="black" />;
	}

	if (props.error) {
		return wrapper(<Alert type="error">{t(props.error)}</Alert>);
	}

	if (!extensions || extensions.length < 1) {
		return <></>;
	}

	return wrapper(
		<Table
			key={"extensionlist-table"}
			t={t}
			keys={["extension_id", "name", ""]}
			responsive="stacked"
		>
			{extensions.map((extension, index) => {
				return (
					<tr key={"extensionlist-table-row-" + extension.id}>
						<td>
							<Highlight text={extension.id.toString()} search={search} />
						</td>
						<td>
							<Highlight text={extension.name} search={search} />
						</td>
						<td>
							<div className="ut-right">
								{user !== undefined && user.permissions.group.siteAdmin === true ? (
									<AnchorButton
										href={`/my/projects/extension/${extension.id.toString()}`}
										t={t}
										text="button.details"
										size="xs"
									/>
								) : (
									<AnchorButton
										href={urls.myURL({
											page: "support",
											section: "cetextensions",
											extension_id: extension.id.toString(),
										})}
										t={t}
										text="button.details"
										size="xs"
									/>
								)}
							</div>
						</td>
					</tr>
				);
			})}
		</Table>
	);
}
