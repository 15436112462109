import { useEffect, useState } from "react";

interface IFrameModalPositionAdjuster {
	setDialogHeight: (n: number) => void;
	setScrollPosition: (n: number) => void;
	setViewPortHeight: (n: number) => void;
}

export function useIFrameModalPositionAdjuster(): IFrameModalPositionAdjuster {
	const [dialogHeight, setDialogHeight] = useState<number>(0);
	const [scrollPosition, setScrollPosition] = useState<number>(0);
	const [viewPortHeight, setViewPortHeight] = useState<number>(0);

	useEffect(() => {
		const isInIframe = window !== window.parent ? true : false;
		if (!isInIframe) return;

		const body = document.body;
		const html = document.documentElement;
		const pageHeight = Math.max(
			body.scrollHeight,
			body.offsetHeight,
			html.clientHeight,
			html.scrollHeight,
			html.offsetHeight
		);

		const modModal = document.querySelector<HTMLElement>(".container.mod-modal");
		const modal = document.querySelector<HTMLElement>(".modal");
		const menuHeight = 85;
		const footerOffset = 50;
		const centerDialogPosition =
			(viewPortHeight - dialogHeight) / 2 + scrollPosition - menuHeight;
		const scrolledNearBottom =
			scrollPosition - viewPortHeight / 4 > pageHeight - viewPortHeight;
		const newPosition = scrolledNearBottom
			? pageHeight - dialogHeight - footerOffset
			: centerDialogPosition;

		if (modModal && modal) {
			modModal.style.alignItems = "stretch";
			modModal.style.top = (newPosition > 0 ? newPosition : 0) + "px";
			// modal.style.overflowY = "hidden";
		}

		document.addEventListener("fullscreenchange", exitHandler);
		document.addEventListener("webkitfullscreenchange", exitHandler);
		document.addEventListener("mozfullscreenchange", exitHandler);
		document.addEventListener("MSFullscreenChange", exitHandler);

		function exitHandler() {
			window.parent.postMessage(
				{ setScrollPosition: scrollPosition },
				process.env.REACT_APP_WWW_URL || ""
			);
		}
	}, [dialogHeight, scrollPosition, viewPortHeight]);

	return {
		setDialogHeight: (height) => {
			setDialogHeight(height);
		},
		setScrollPosition: (position) => {
			setScrollPosition(position);
		},
		setViewPortHeight: (height) => {
			setViewPortHeight(height);
		},
	};
}
