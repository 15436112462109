import { EventEmitter } from "./EventEmitter";

export interface StackFrameData {
	args: string;
	caller: string;
	context_line: string;
	filename: string;
	line: number;
	post_context: [string, string];
	pre_context: [string, string];
}

export type DebugDict = { [key: string]: string | string[] };

export interface DebugData {
	culprit: string;
	env: DebugDict;
	env_variables: DebugDict;
	error: string;
	level: string;
	note: string;
	note_data?: string;
	request: DebugDict;
	stack_trace: StackFrameData[];
	user?: DebugDict;
}

export type DebugHandler = (data: DebugData) => void;

const handlers: DebugHandler[] = [];

export function registerDebugHandler(handler: DebugHandler) {
	const index = handlers.indexOf(handler);
	if (index !== -1) {
		return;
	}
	handlers.push(handler);
}

export function deregisterDebugHandler(handler: DebugHandler) {
	const index = handlers.indexOf(handler);
	if (index !== -1) {
		handlers.splice(index, 1);
	}
}

export function handleDebugResponse(
	response: {
		debug?: DebugData;
	},
	url?: string
) {
	if (!response) {
		return response;
	}

	if (response.debug) {
		for (const handler of handlers) {
			handler(response.debug);
		}
	}

	return response;
}

/**
 * The singleton emitter for sql logs.
 */
const SQL_INFO_EMITTER = new EventEmitter<any>();

export function logSqlInfo(message: { sqlInfo: any }) {
	if (message.sqlInfo) {
		SQL_INFO_EMITTER.emit(message.sqlInfo);
	}
}

export async function logResponseSqlInfo<T = any>(response: T): Promise<T> {
	if ("devBarData" in (response as any) && "sqlInfo" in (response as any).devBarData) {
		logSqlInfo((response as any).devBarData);
	}

	return response;
}

export function subscribeSqlInfo(listener: (event: any) => void) {
	SQL_INFO_EMITTER.subscribe(listener);
}

export function unsubscribeSqlInfo(listener: (event: any) => void) {
	SQL_INFO_EMITTER.unsubscribe(listener);
}

// FIXME: This is to support legacy pages still rendered by perl.
(window as any).updateDevBar = (message: any) => {
	if ("sqlInfo" in message) {
		logSqlInfo(message);
	}
};
