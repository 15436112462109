import React from "react";

type HeadingProps = { size?: 1 | 2 | 3 | 4 | 5 | 6; className?: string };

const useClassNames = (first: string, second?: string) => (second ? `${first} ${second}` : first);

export const H1: React.FC<HeadingProps> = props => {
	const { size = 1 } = props;
	const className = useClassNames(`heading-${size}`, props.className);
	return <h1 className={className}>{props.children}</h1>;
};

export const H2: React.FC<HeadingProps> = props => {
	const { size = 2 } = props;
	const className = useClassNames(`heading-${size}`, props.className);
	return <h2 className={className}>{props.children}</h2>;
};

export const H3: React.FC<HeadingProps> = props => {
	const { size = 3 } = props;
	const className = useClassNames(`heading-${size}`, props.className);
	return <h3 className={className}>{props.children}</h3>;
};

export const H4: React.FC<HeadingProps> = props => {
	const { size = 4 } = props;
	const className = useClassNames(`heading-${size}`, props.className);
	return <h4 className={className}>{props.children}</h4>;
};

export const H5: React.FC<HeadingProps> = props => {
	const { size = 5 } = props;
	const className = useClassNames(`heading-${size}`, props.className);
	return <h5 className={className}>{props.children}</h5>;
};

export const H6: React.FC<HeadingProps> = props => {
	const { size = 6 } = props;
	const className = useClassNames(`heading-${size}`, props.className);
	return <h6 className={className}>{props.children}</h6>;
};
