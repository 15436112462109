import { copy, findIndex, isError } from "cfg-base";
import "intersection-observer";
import React, { useEffect, useRef, useState } from "react";
// import fallbackURL from "../resources/fallback.png";
const fallbackURL = "";

// https://en.wiktionary.org/wiki/observee
interface Observee {
	element: HTMLElement;
	callback: () => void;
}

const observees: Observee[] = [];

function onIntersection(entries: IntersectionObserverEntry[]) {
	for (const entry of entries) {
		if (entry.intersectionRatio > 0) {
			observer.unobserve(entry.target);
			const index = findIndex(val => val.element === entry.target, observees);
			if (index !== undefined) {
				const thing = observees[index];
				observees.splice(index, 1);
				thing.callback();
			}
		}
	}
}

const options = {
	// If the image gets within 50px in the Y axis, start the download.
	rootMargin: "50px 0px",
	threshold: 0.01,
};

const observer = new IntersectionObserver(onIntersection, options);

function observe(thing: Observee) {
	const index = findIndex(val => val.element === thing.element, observees);
	if (index === undefined) {
		observees.push(thing);
		observer.observe(thing.element);
	}
}

function unobserve(element: HTMLElement) {
	observer.unobserve(element);
	const index = findIndex(val => val.element === element, observees);
	if (index !== undefined) {
		observees.splice(index, 1);
	}
}

interface Props {
	alt?: string;
	className?: string;
	crossOrigin?: "anonymous" | "use-credentials" | "";
	hideOnFail?: boolean;
	src: string;
	style?: React.CSSProperties;
}

function fetchImage(
	src: string,
	crossOrigin?: "anonymous" | "use-credentials" | ""
): Promise<Option<Error>> {
	return new Promise((resolve, reject) => {
		const image = new Image();
		image.crossOrigin = crossOrigin || null;
		image.src = src;
		image.onload = event => resolve();
		image.onerror = event => resolve(Error(event.toString())); // show broken image if load failed
	});
}

export function LazyImage(props: Props) {
	const { alt, className, crossOrigin, hideOnFail } = props;
	const element = useRef<HTMLImageElement>(null);
	const [failed, setFailed] = useState(false);
	const [loaded, setLoaded] = useState(false);
	let src: string | undefined = props.src;
	if (failed) {
		src = fallbackURL;
	} else if (!loaded) {
		src = undefined;
	}

	useEffect(() => {
		if (element !== null && element.current !== null) {
			observe({ element: element.current, callback: loadImage });
		}
		return () => {
			if (element !== null && element.current !== null) {
				unobserve(element.current);
			}
		};
	}, [element]);

	async function loadImage() {
		if (loaded) {
			return;
		}
		const result = await fetchImage(props.src, crossOrigin);

		if (element === null) {
			return;
		}

		if (isError(result)) {
			setFailed(true);
		} else {
			setLoaded(true);
		}
	}

	let style: React.CSSProperties | undefined;
	if (!loaded && !failed) {
		style = { visibility: "hidden" };
	} else if (failed && hideOnFail) {
		style = { display: "none" };
	}

	if (props.style) {
		style = copy(props.style, style);
	}

	return (
		<img
			alt={alt}
			className={className}
			crossOrigin={crossOrigin}
			ref={element}
			src={src}
			style={style}
		/>
	);
}
