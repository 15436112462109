import { FilterTag, Input } from "components";
import * as React from "react";
import { InternalSearcher } from "../molecules/InternalSearcher";

interface InternalSearchInputProps {
	setSearchValueIsEmpty: (value: React.SetStateAction<boolean>) => void;
	internalSearchers: Array<InternalSearcher>;
	t: Translator;
}

export const InternalSearchInput: React.FC<InternalSearchInputProps> = (props) => {
	const [searchText, setSearchText] = React.useState<string>("");
	const [callback, setCallback] = React.useState<Option<NodeJS.Timeout>>();

	const MAX_WAIT_TIME_UNTIL_SEARCH_IN_MS = 350;

	const fetchData = React.useCallback(
		(search: string) => {
			props.internalSearchers.forEach((internalSearcher, index) => {
				internalSearcher.setSearchValue(search);
			});
		},
		[props.internalSearchers]
	);

	const setTimeoutFunction = React.useCallback(
		(search: string, timeout: number) => {
			if (callback) {
				clearTimeout(callback);
			}
			setCallback(
				setTimeout(function () {
					fetchData(search);
				}, timeout)
			);
		},
		[callback, fetchData]
	);

	const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			event.preventDefault();
			const search = event.target.value;
			setSearchText(search);
			props.setSearchValueIsEmpty(search === "");
			setTimeoutFunction(search, MAX_WAIT_TIME_UNTIL_SEARCH_IN_MS);
		},
		[props, setTimeoutFunction]
	);

	return (
		<Input
			name={"internal_searcher"}
			type="text"
			onChange={onChange}
			autoFocus={true}
			label={"Search"}
			required={true}
			value={searchText}
		/>
	);
};

interface InternalSearchFilterProps {
	internalSearchers: Array<InternalSearcher>;
	t: Translator;
}

export const InternalSearchFilter: React.FC<InternalSearchFilterProps> = (props) => {
	const { internalSearchers } = props;

	const toggleInternalSearcherActive = React.useCallback((internalSearcher: InternalSearcher) => {
		internalSearcher.setActive(!internalSearcher.active);
	}, []);

	return (
		<React.Fragment key={"internal-search-filter"}>
			<span className="ut-margin-right-1">Filters:</span>
			{internalSearchers.map((internalSearcher, index) => {
				return (
					<FilterTag
						key={internalSearcher.name}
						text={`${props.t(internalSearcher.name)} (${
							internalSearcher.numberOfItems
						})`}
						color="blue"
						filled={internalSearcher.active}
						onClick={() => toggleInternalSearcherActive(internalSearcher)}
						t={props.t}
					/>
				);
			})}
		</React.Fragment>
	);
};

interface InternalSearchListProps {
	internalSearchers: Array<InternalSearcher>;
	user: User;
	t: Translator;
}

export const InternalSearchList: React.FC<InternalSearchListProps> = (props) => {
	return (
		<React.Fragment key={"internal-search-list-fragment"}>
			{props.internalSearchers.map((internalSearcher, index) => {
				if (internalSearcher.active) {
					return internalSearcher.render(props.user);
				}

				return <></>;
			})}
		</React.Fragment>
	);
};
