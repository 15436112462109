import { classNames } from "cfg-base/src";
import { SearchTypes } from "cfg-base/src/types/search";
import { AnchorButton, Button, H3, LazyImage } from "components/src";
import React from "react";
import { ApiResult } from "../molecules/ApiResult";

import { urls } from "cfg-base";
import { RecentSearchResponse } from "cfg-base/src/api";
import useApiCall from "../../hooks/useApiCall";
import {
	masqueradeLoginURL,
	searchCustomerURL,
	searchInvoiceURL,
	searchLicenseURL,
	searchUserURL,
} from "../lib/search";

// URLs of default icons for recent searches
const USER_PLACEHOLDER_URL = `${urls.STATIC_URL}/web/icons/avatar.svg`;
const INVOICE_ICON_URL = `${urls.STATIC_URL}/web/img/old/invoice_search_icon.png`;
const EXTENSION_ICON_URL = `${urls.STATIC_URL}/web/img/old/transparent.png`;

interface Props {
	t: Translator;
}

export const RecentSearchList = (props: Props) => {
	const { t } = props;
	const recentSearchesResponse = useApiCall("search/list-recent-searches");

	return (
		<React.Fragment>
			<H3 className="ut-margin-bottom-1">{t("title.most_recent_searches")}</H3>
			<ApiResult
				results={[recentSearchesResponse]}
				t={t}
				renderSuccess={(recentSearchesResponse) => {
					const recentSearchItemsInfo = toRecentSearchItemsInfo(
						recentSearchesResponse.recentSearches
					);
					return (
						<ul className="recent-search-list">
							{recentSearchItemsInfo.map((itemInfo) => {
								return (
									<RecentSearchItem
										key={`${itemInfo.type}-${itemInfo.id}`}
										t={t}
										{...itemInfo}
									/>
								);
							})}
						</ul>
					);
				}}
			/>
		</React.Fragment>
	);
};

interface LinkActions {
	action: "login" | "details";
	url: string;
	hasPermission: boolean;
}

interface RecentSearchItemInfo {
	type: keyof SearchTypes;
	id: number;
	iconURL: string;
	linkActions: LinkActions[];
	primaryDescription: string;
	subDescriptions: string[];
}

interface RecentSearchItemProps extends RecentSearchItemInfo {
	t: Translator;
}

const RecentSearchItem = (props: RecentSearchItemProps) => {
	const { type, iconURL, linkActions, primaryDescription, subDescriptions, t } = props;

	const imgClasses = classNames(["item-icon", type === "user" && "item-icon-user"]);

	return (
		<li className="recent-search-list-item">
			<div className="item-content-container">
				<LazyImage className={imgClasses} src={iconURL} />
				<div className="item-description">
					<div className="item-description-primary">{primaryDescription}</div>
					{subDescriptions.map((description, index) => {
						return <div key={index}>{description}</div>;
					})}
				</div>
			</div>

			<div className="item-action-buttons-container">
				{linkActions.map((linkAction, index) => {
					const { action, url, hasPermission } = linkAction;

					const text = action === "login" ? "button.login_as_user" : "button.details";
					const visualType = action === "login" ? "primary" : undefined;

					if (hasPermission) {
						return (
							<AnchorButton
								key={index}
								text={t(text)}
								visualType={visualType}
								size="sm"
								href={`${url}`}
							/>
						);
					}
					return (
						<Button
							key={index}
							text={t(text)}
							title={t("error.permission_denied")}
							disabled={true}
							visualType={visualType}
						/>
					);
				})}
			</div>
		</li>
	);
};

const toRecentSearchItemsInfo = (
	recentSearches: RecentSearchResponse["recentSearches"]
): RecentSearchItemInfo[] => {
	const recentSearchItemsInfo = recentSearches.map((recentSearchEntity) => {
		if (recentSearchEntity.type === "user") {
			return userListItem(recentSearchEntity.data);
		} else if (recentSearchEntity.type === "invoice") {
			return invoiceListItem(recentSearchEntity.data);
		} else if (recentSearchEntity.type === "customer") {
			return customerListItem(recentSearchEntity.data);
		} else {
			return licenseListItem(recentSearchEntity.data);
		}
	});
	return recentSearchItemsInfo;
};

const userListItem = (user: SearchTypes["user"]): RecentSearchItemInfo => {
	return {
		type: "user",
		id: user.uid,
		iconURL: user.avatarURL ? user.avatarURL : USER_PLACEHOLDER_URL,
		primaryDescription: `${user.firstname} ${user.lastname}`,
		subDescriptions: [`Account ID: ${user.uid}`, `Email: ${user.email}`],
		linkActions: [
			{
				action: "login",
				url: masqueradeLoginURL(user.uid),
				hasPermission: user.canBeLoggedInAs,
			},
			{
				action: "details",
				url: searchUserURL(user.uid),
				hasPermission: true,
			},
		],
	};
};

const invoiceListItem = (invoice: SearchTypes["invoice"]): RecentSearchItemInfo => {
	return {
		type: "invoice",
		id: invoice.invoiceId,
		iconURL: INVOICE_ICON_URL,
		primaryDescription: `Invoice ${invoice.invoiceId}`,
		subDescriptions: [
			`Buyer: ${invoice.user.firstname} ${invoice.user.lastname}`,
			invoice.customerAdmin
				? `Admin: ${invoice.customerAdmin.firstname} ${invoice.customerAdmin.lastname}`
				: "",
		],
		linkActions: [
			{
				action: "details",
				url: searchInvoiceURL(invoice.invoiceId),
				hasPermission: true,
			},
		],
	};
};

const licenseListItem = (license: SearchTypes["license"]): RecentSearchItemInfo => {
	return {
		type: "license",
		id: license.id,
		iconURL: license.extensionIconUrl ? license.extensionIconUrl : EXTENSION_ICON_URL,
		primaryDescription: `${license.extensionName}`,
		subDescriptions: [`License ID: ${license.id}`],
		linkActions: [
			{
				action: "details",
				url: searchLicenseURL(license.id),
				hasPermission: true,
			},
		],
	};
};

const customerListItem = (customer: SearchTypes["customer"]): RecentSearchItemInfo => {
	return {
		type: "customer",
		id: customer.id,
		iconURL: USER_PLACEHOLDER_URL,
		primaryDescription: `${customer.name}`,
		subDescriptions: [`Customer ID: ${customer.id}`],
		linkActions: [
			{
				action: "details",
				url: searchCustomerURL(customer.id),
				hasPermission: true,
			},
		],
	};
};
