import { TemplateDelegate } from "handlebars";
import { interpolate, TranslatableError } from "..";

export const translate = (
	translations: Translations | { [key: string]: Option<Translation> } | undefined,
	translatable: Translatable,
	loading?: boolean,
	missingTranslationCallback?: (translatable: Translatable) => void
) => {
	if (!translations) {
		return "";
	}

	let values: Option<TranslationValues>;
	if (translatable instanceof TranslatableError) {
		values = translatable.values;
		translatable = translatable.message;
	} else if (translatable instanceof Error) {
		translatable = translatable.message;
	} else if (typeof translatable !== "string") {
		values = translatable.values;
		translatable = translatable.message;
	}

	// Legacy
	if (translatable.indexOf("|") !== -1) {
		const tmp = translatable.split("|");
		translatable = tmp[0];
		values = tmp.slice(1);
	}

	let translated = translations[translatable];
	if (!translated) {
		if (loading) {
			return "";
		}

		if (missingTranslationCallback) {
			missingTranslationCallback(translatable);
		}

		return translatable;
	}

	if (typeof translated === "string") {
		if (values) {
			if (values instanceof Array) {
				let newValues: { [index: string]: string | number } = {};
				for (const [i] of values.entries()) {
					newValues[i.toString()] = values[i];
				}

				values = newValues;
			}

			return interpolate(translated, values);
		}

		return translated;
	}

	if (!values || values instanceof Array) {
		// Legacy
		return translated(values || []);
	} else if (values) {
		return (translated as TemplateDelegate)(values);
	}

	return translatable;
};
