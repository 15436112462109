import React, { useContext } from "react";
import { Link } from "react-router-dom";

interface Props {
	onNavigate?: () => void;
	t: Translator;
}
export const CollaborationMenu: React.FC<Props> = (props) => {
	const { t } = props;
	const {
		lastAccessed,
		itemRenderer: CollabItem,
		showGetStarted,
		singleCustomerId,
	} = useCollaborationMenu();

	const onClick = () => {
		props.onNavigate?.();
		return true;
	};

	return (
		<>
			{showGetStarted && singleCustomerId ? (
				<NoProjects cid={singleCustomerId} onClick={onClick} />
			) : (
				lastAccessed &&
				lastAccessed.length > 0 && (
					<>
						{lastAccessed.map(({ gi, cid }) => (
							<li key={`${cid}#${gi}`}>
								<Link
									onClick={onClick}
									to={`/my/collaboration/${cid}/projects/${gi}`}
								>
									<CollabItem gi={gi} cid={cid} gt={""} />
								</Link>
							</li>
						))}
						<li>
							<Link
								className="ut-margin-vertical-1 ut-inline-block"
								onClick={onClick}
								to="/my/collaboration"
							>
								{t("label.show_all")}
							</Link>
						</li>
					</>
				)
			)}
		</>
	);
};

const NoProjects = ({ cid, onClick }: { cid: string; onClick: () => void }) => {
	return (
		<>
			<li>
				<div className="ut-margin-vertical-1 ut-inline-block">
					You don't have any projects yet.
				</div>
			</li>
			<li>
				<Link onClick={onClick} to={`/my/collaboration/${cid}/projects/create-project`}>
					Create your first project
				</Link>
			</li>
		</>
	);
};

interface LastAccessed {
	cid: string;
	gi: string;
}
export interface CollaborationMenuState {
	lastAccessed?: LastAccessed[];
	isLoading: boolean;
	itemRenderer: React.FC<{ cid: string; gt: string; gi: string }>;
	showGetStarted?: boolean;
	singleCustomerId?: string;
	refreshProjects: () => void;
}
export const CollaborationMenuContext = React.createContext<CollaborationMenuState>({
	isLoading: true,
	itemRenderer: ({ gi }) => <>({gi})</>,
	refreshProjects: () => {},
});
export const useCollaborationMenu = () => useContext(CollaborationMenuContext);
