import { localStorage as cache, captureException, fetchJSON } from "cfg-base";
import { useEffect, useState } from "react";

export function useMenu(name: string, url: string): UseMenuResponse {
	const [trigger, setTrigger] = useState<{ name: string; url: string }>({ name, url });
	const [menu, setMenu] = useState<ParsedMenuData>(EMPTY_PARSED_MENU_DATA);
	const [error, setError] = useState<Error>();

	useEffect(() => {
		let menu = getCachedMenu(name);
		try {
			if (menu) {
				setMenu(parseMenuData(menu));
			}
		} catch (e) {
			if (e instanceof Error) {
				clearCachedMenu(name);
				captureException(e, { menu });
				setError(e);
			}
		}
	}, [name]);

	useEffect(() => {
		const handle = setInterval(() => {
			setTrigger({ name, url });
		}, 15 * 60 * 1000);

		return () => {
			clearInterval(handle);
		};
	}, [name, url]);

	useEffect(() => {
		let unmounted = false;

		// NOTE:
		// fetch() presents 304 status as 200 and the body is populated from browser cache.
		// This means we cannot detect if content has changed from the response body unless
		// we do deep compare. Currently we just re-render the menu again :(
		fetchJSON(trigger.url).then((response) => {
			if (unmounted) {
				return;
			}

			if (response) {
				try {
					setMenu(parseMenuData(response));
					cacheMenu(trigger.name, response);
				} catch (e) {
					if (e instanceof Error) {
						captureException(e, { response });
						setError(e);
					}
				}
			}
		});

		return () => {
			unmounted = true;
		};
	}, [trigger]);

	return { menu, error };
}

export interface UseMenuResponse {
	menu: ParsedMenuData;
	error: Error | undefined;
}

export function cacheMenu(name: string, menu: MenuData) {
	cache.setJSON("react-menu-data-" + name, menu);
}

export function getCachedMenu(name: string): MenuData | undefined {
	return cache.getJSON("react-menu-data-" + name);
}

export function clearCachedMenu(name: string) {
	cache.remove("react-menu-data-" + name);
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// Parsed menu data
///////////////////////////////////////////////////////////////////////////////////////////////////

export interface ParsedMenuData {
	extras?: MenuOptions;
	left: TopMenu[];
	mobileMenu: MobileMenu;
	right: TopMenu[];
	socialIcons: boolean;
	static: StaticMenu[];
	staticMobileMenu: MenuObject[];
	testMode?: boolean;
	devMode?: boolean;
	isMarketing?: boolean;
	notification?: NotificationType;
}

function parseMenuData(data: MenuData): ParsedMenuData {
	try {
	} catch (e) {}
	const parsed: ParsedMenuData = {
		devMode: !!data.extras && !!data.extras.devMode,
		extras: data.extras,
		isMarketing: !!data.isMarketing,
		left: data.left,
		mobileMenu: {},
		right: data.right,
		socialIcons: !!data.extras && !!data.extras.socialIcons,
		static: data.static,
		staticMobileMenu: [],
		testMode: !!data.extras && !!data.extras.testMode,
		notification: data.notification,
	};

	data.left.forEach((top) => {
		if (top.menu) {
			top.menu.forEach((menu) => {
				if (menu) {
					menu.map((item) => parseMobileMenu(item, parsed));
				}
			});
		} else if (top.href && top.extras && top.extras.mobile) {
			parseMobileMenu([top.title, top.href, top.extras], parsed);
		}
	});
	data.static.forEach((item) => {
		item.links.map((item) => parseMobileMenu(item, parsed));
	});

	/**
	 * Sort the static mobile menu before rendering
	 * it uses a sort parameter in extras if set
	 */
	parsed.staticMobileMenu.sort((a, b) => {
		const [, , aExtras] = a;
		const [, , bExtras] = b;

		const aSort = aExtras ? aExtras.sort : undefined;
		const bSort = bExtras ? bExtras.sort : undefined;

		if (aSort && bSort) {
			if (aSort > bSort) {
				return 1;
			}
			if (aSort < bSort) {
				return -1;
			}
			return 0;
		}
		if (!aSort && bSort) {
			return 1;
		}
		if (aSort && !bSort) {
			return -1;
		}
		return 0;
	});

	return parsed;
}

function parseMobileMenu(item: MenuObject, parsed: ParsedMenuData) {
	const extras = item[2];
	if (extras) {
		if (extras.mobile === 1 && extras.mobileGroup) {
			if (extras.mobileGroup.toLowerCase() === "static") {
				parsed.staticMobileMenu.push(item);
			} else {
				if (!parsed.mobileMenu.hasOwnProperty(extras.mobileGroup)) {
					parsed.mobileMenu[extras.mobileGroup] = [];
				}
				parsed.mobileMenu[extras.mobileGroup].push(item);
			}
		}
	}
}

const EMPTY_PARSED_MENU_DATA = {
	extras: {},
	left: [],
	mobileMenu: {},
	right: [],
	socialIcons: false,
	static: [],
	staticMobileMenu: [],
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// API response schema
///////////////////////////////////////////////////////////////////////////////////////////////////

export interface MenuData {
	left: TopMenu[];
	right: TopMenu[];
	static: StaticMenu[];
	extras?: MenuOptions;
	notification?: NotificationType;
	isMarketing?: boolean;
	language: string;
	uid?: number;
}

export interface MenuOptions {
	addCollaborationProjects?: boolean;
	"data-bits"?: string;
	"data-popup"?: string;
	altText?: string;
	class?: string;
	devMode?: boolean;
	dropdown?: string;
	icon?: string;
	image?: string;
	mobile?: number;
	mobileGroup?: string;
	mobileOnly?: boolean;
	router?: boolean;
	onClick?: string;
	socialIcons?: boolean;
	sort?: number;
	testMode?: boolean;
}

export type MenuObject = [string, string, Option<MenuOptions>];

export interface TopMenu {
	radioSelected: number | undefined;
	hideOnMobile: boolean;
	href?: string;
	menu?: MenuObject[][];
	title: string;
	extras?: MenuOptions;
	innerOnMobile: boolean;
	link?: string;
}

export interface StaticMenu {
	class: string;
	links: MenuObject[];
}

export interface MobileMenu {
	[key: string]: MenuObject[];
}

export type NotificationType = "policy" | "CLA";
