import { addClasses } from "cfg-base";
import React from "react";
import ReactDOM from "react-dom";

export function showOverlay(
	event: React.MouseEvent<HTMLElement>,
	element: React.FunctionComponentElement<any> | Array<React.FunctionComponentElement<any>>
) {
	event.preventDefault();

	let portal = document.getElementById("react-overlay-myconfigura-container");
	if (!portal) {
		portal = document.createElement("div");
		portal.id = "react-overlay-myconfigura-container";
		addClasses(portal, ["phoenix-forms", "phoenix-base"]);
		document.body.appendChild(portal);
	}
	const component: any = ReactDOM.render(element, portal);
	if (component instanceof React.Component) {
		component.setState({ hidden: false });
	}

	document.body.style.overflow = "hidden";
}
