import { classNames } from "cfg-base";
import React, { useState } from "react";

interface Props {
	account: "cet" | "configura";
	link: string;
	social: string;
	t: Translator;
	onClick?: () => void;
}

export function SocialMediaItem(props: Props) {
	const { account, link, social, t, onClick } = props;
	const [active, setActive] = useState(false);

	function handleHover() {
		setActive((prev) => !prev);
	}

	const iconClasses = classNames([
		"svg-icon",
		"mod-sm",
		`mod-${social}`,
		!active && "mod-inactive",
	]);

	return (
		<li className="social-link-item" onMouseEnter={handleHover} onMouseLeave={handleHover}>
			{!onClick ? (
				<a
					href={link}
					rel="noopener noreferrer"
					target="_blank"
					title={t("social." + account + "." + social)}
				>
					<i className={iconClasses} />
				</a>
			) : (
				<span
					className={`${iconClasses} social-button`}
					onClick={onClick}
					title={t(`social.${account}.${social}`)}
				/>
			)}
		</li>
	);
}
