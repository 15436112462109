import { localStorage } from "cfg-base";
import { SearchEndpoints, SearchTypes } from "cfg-base/src/types/search";
import * as React from "react";
import { searchCall } from "../lib/search";

const INTERNAL_SEARCHER_LOCAL_STORE_PREFIX = "internal-searcher-";
const INTERNAL_SEARCHER_LOCAL_STORE_SEARCH = "master-search";

type InternalSearcherLocalStoreCache = {
	[E in keyof SearchTypes]: {
		active: boolean;
		searchValue: string;
		order: InternalSearcherOrder;
		pagination: InternalSearcherPagination;
	};
} & { [INTERNAL_SEARCHER_LOCAL_STORE_SEARCH]: string };

const internalSearcherStoreCache =
	new localStorage.PrefixTypedCache<InternalSearcherLocalStoreCache>(
		INTERNAL_SEARCHER_LOCAL_STORE_PREFIX
	);

export const setInternalSearcherSearch = (searchValue: string) => {
	internalSearcherStoreCache.set(INTERNAL_SEARCHER_LOCAL_STORE_SEARCH, searchValue);
};

export const getInternalSearcherSearch = () => {
	return internalSearcherStoreCache.get(INTERNAL_SEARCHER_LOCAL_STORE_SEARCH);
};

type SetActive = (value: React.SetStateAction<boolean>) => void;
type SetSearchValue = (value: React.SetStateAction<string>) => void;
type SetOrder = (value: React.SetStateAction<InternalSearcherOrder>) => void;
type SetPagination = (value: React.SetStateAction<InternalSearcherConfigPagination>) => void;

type InternalSearchRender<T extends keyof SearchTypes> = (
	data: Array<SearchTypes[T]>,
	loading: boolean,
	error: Option<string>,
	search: string,
	t: Translator,
	user?: User
) => JSX.Element;

export interface InternalSearcherConfigColumns<T extends keyof SearchTypes> {
	name: string;
	selector: keyof SearchTypes[T] & string;
	sortable?: boolean;
}

export interface InternalSearcherConfigOrder<T extends keyof SearchTypes> {
	column: keyof SearchTypes[T] & string;
	direction: "ASC" | "DESC";
}

export interface InternalSearcherConfigPagination {
	page: number;
	nrOfItems: number;
}

export interface InternalSearcherConfig<T extends keyof SearchTypes> {
	endpoint: SearchEndpoints[T];
	name: string;
	columns: Array<InternalSearcherConfigColumns<T>>;
	initialOrder: InternalSearcherConfigOrder<T>;
	initialPagination: InternalSearcherConfigPagination;
	initialData: Array<SearchTypes[T]>;
	initialSearch?: string;
	initialActive?: boolean;

	render: InternalSearchRender<T>;
}

export interface InternalSearcherColumn {
	name: string;
	selector: string;
	sortable?: boolean;
}

export interface InternalSearcherOrder {
	column: string;
	direction: "ASC" | "DESC";
}

export interface InternalSearcherPagination extends InternalSearcherConfigPagination {}

export interface InternalSearcher {
	name: string;
	columns: Array<InternalSearcherColumn>;
	order: InternalSearcherOrder;
	pagination: InternalSearcherPagination;
	searchValue: string;
	numberOfItems: number;
	active: boolean;
	error: Option<string>;
	loading: boolean;

	setActive: SetActive;
	setSearchValue: SetSearchValue;
	// setOrder: SetOrder;
	// setPagination: SetPagination;
	render: (user?: User) => JSX.Element;
}

type UseInternalSearcherProps = <T extends keyof SearchTypes>(
	searcherConfig: InternalSearcherConfig<T>,
	t: Translator
) => InternalSearcher;
export const useInternalSearcher: UseInternalSearcherProps = (searcherConfig, t) => {
	const { endpoint, name, columns } = searcherConfig;

	const [results, setResults] = React.useState(searcherConfig.initialData);
	const [active, setActive] = React.useState(searcherConfig.initialActive ?? true);
	const [searchValue, setSearchValue] = React.useState(searcherConfig.initialSearch ?? "");
	const [order, setOrder] = React.useState<InternalSearcherOrder>(searcherConfig.initialOrder);
	const [pagination, setPagination] = React.useState(searcherConfig.initialPagination);
	const [error, setError] = React.useState<Option<string>>();
	const [loading, setLoading] = React.useState<boolean>(false);

	const numberOfItems = React.useMemo(() => {
		if (!results) {
			return 0;
		}

		return results.length;
	}, [results]);

	const fetchData = React.useCallback(() => {
		if (searchValue === "") {
			setResults([]);
			return;
		}

		setLoading(true);
		setResults([]);
		searchCall(endpoint, {
			search: searchValue,
			orderBy: order.column,
			orderDirection: order.direction,
			paginationPage: pagination.page,
			paginationNrOfItems: pagination.nrOfItems,
		})
			.then((res) => {
				setLoading(false);
				if (res instanceof Error) {
					setError(t(res));
				} else {
					setResults(res.results);
				}
			})
			.catch((reason) => {
				setLoading(false);
				setError(t(new Error(reason)));
			});
	}, [endpoint, order, pagination, searchValue, t]);

	React.useEffect(() => {
		fetchData();
	}, [endpoint, searchValue, order, pagination, fetchData]);

	return {
		name: name,
		columns: columns,
		order: order,
		pagination: pagination,
		searchValue: searchValue,
		numberOfItems: numberOfItems,
		active: active,
		error: error,
		loading: loading,

		fetchData: fetchData,
		setActive: setActive,
		setSearchValue: setSearchValue,
		// setOrder: setOrder,
		// setPagination: setPagination,
		render: (user?: User) =>
			searcherConfig.render(results, loading, error, searchValue, t, user),
	};
};
