import { en, urls } from "cfg-base";
import { Menu, TranslationProvider } from "components";
import React from "react";
import * as ReactDOM from "react-dom";
import "../../../scss/common.scss";
import "../../../scss/myconfigura.scss";
import { setupDebug } from "./common/lib/debug";
import { dataAttr } from "./common/lib/helpers";
import { Footer } from "./common/organisms/Footer";
import { ensureTimezoneCookie } from "./myconfigura/timezone";

ensureTimezoneCookie();
setupDebug();

const menuCallbacks: Array<() => void> = [];

const menu = document.getElementById("react-menu");
if (menu !== null) {
	const type = dataAttr(menu, "menu") || "";
	const endpoint = type === "" ? "menu/marketing" : "menu/myconfigura";

	ReactDOM.render(
		<TranslationProvider
			scopes={["menu", "social"]}
			render={(t) => {
				return (
					<Menu
						callbacks={menuCallbacks}
						logoSrc={`${urls.STATIC_URL}/web/img/logos/configura.svg`}
						menu={type}
						url={urls.apiURL(endpoint)}
						t={type === "" ? t : en}
					/>
				);
			}}
		></TranslationProvider>,
		menu
	);
}

const footer = document.getElementById("react-footer");
if (footer) {
	const marketing = !!dataAttr(footer, "marketing");
	ReactDOM.render(<Footer marketing={marketing} />, footer);
}

// You should not have to add your component here unless you are doing a page
// with custom layout. Check out https://www.configura.com/my/docs/routing
