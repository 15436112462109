import { classNames, generateRandomID } from "cfg-base";
import React, { useContext, useEffect, useState } from "react";
import { ExpandListContext } from "../organisms/ExpandList";

export const ExpandItemContext = React.createContext("");

interface Props {
	className?: string;
	open?: boolean;
	children: React.ReactNode;
}

export function ExpandItem(props: Props) {
	const { children, className, open } = props;
	const [uuid, setUuid] = useState(generateRandomID());
	const context = useContext(ExpandListContext);

	const classes = classNames(["expand-item", className]);

	useEffect(() => {
		if (!!open) {
			context.pushItem(uuid);
		} else {
			context.removeItem(uuid);
		}
	}, [open]);

	return (
		<ExpandItemContext.Provider value={uuid}>
			<div className={classes}>{children}</div>
		</ExpandItemContext.Provider>
	);
}
