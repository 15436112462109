import { classNames } from "cfg-base";
import React, { useState } from "react";

interface InitialContext extends Style {
	items: string[];
	pushItem: (uuid: string) => void;
	removeItem: (uuid: string) => void;
	toggle: (uuid: string) => void;
}

const INITIAL_CONTEXT: InitialContext = {
	items: [],
	pushItem: (uuid: string) => {},
	removeItem: (uuid: string) => {},
	toggle: (uuid: string) => {},
};

export const ExpandListContext = React.createContext(INITIAL_CONTEXT);

interface Style {
	iconColor?: "blue" | "turquoise";
}

interface Props extends Style {
	children: React.ReactNode;
	className?: string;
}

const initialItems: string[] = [];

export function ExpandList(props: Props) {
	const [items, setItems] = useState(initialItems);
	const { className, children, iconColor } = props;

	const classes = classNames(["expand-list", className]);
	return (
		<div className={classes}>
			<ExpandListContext.Provider
				value={{
					iconColor,
					items,
					pushItem: (uuid: string) => setItems(prev => prev.concat(uuid)),
					removeItem: (uuid: string) => setItems(prev => prev.filter(i => i !== uuid)),
					toggle: (uuid: string) => {
						setItems(prev => {
							const copyItems = prev.slice();
							const index = copyItems.indexOf(uuid);
							if (index === -1) {
								copyItems.push(uuid);
							} else {
								copyItems.splice(index, 1);
							}
							return copyItems;
						});
					},
				}}
			>
				{children}
			</ExpandListContext.Provider>
		</div>
	);
}
