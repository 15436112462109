import { CatalogueAPI } from "@configura/web-api";
import React, { useMemo } from "react";

export const ExternalStageAPIContext = React.createContext<CatalogueAPI>(new CatalogueAPI());

export const ExternalStageAPIWrapper: React.FC = (props) => {
	const stageAPI = useMemo(() => {
		const externalStageAPI = new CatalogueAPI();

		externalStageAPI.auth = {
			endpoint: "https://catalogueapi.configura.com",
			secretToken: "STEUCE13JIVJ54VHZNCP2DSRNZB4I3EH.2UYEZLMYCIBIBQXR7VPPCHV2DVXATQ3R",
			apiSession: {
				expires: "",
			},
		};

		return externalStageAPI;
	}, []);

	return (
		<ExternalStageAPIContext.Provider value={stageAPI}>
			{props.children}
		</ExternalStageAPIContext.Provider>
	);
};
