import { api, cookies as Cookies, isError, translate, urls } from "cfg-base";
import { compile } from "handlebars";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useBodyClasses } from "..";

export const LANGUAGES: api.Language[] = ["en", "de", "zh", "ja"];
export interface TranslationsRegistry {
	scopes: {
		[propName: string]:
			| Promise<api.TranslationsResponse | undefined | Error>
			| undefined
			| "loaded";
	};
	translations: Translations;
}
const translationsRegistry: TranslationsRegistry = {
	scopes: {},
	translations: {},
};

export function i18nPath(path: string) {
	return `/:lang(en|de|zh|ja)?${path}`;
}

const LANG_GLOBAL_BODY_CLASSES = ["lang-global"];
export function TranslationProvider(props: {
	scopes: string[];
	render: (t: Translator) => React.ReactElement | null;
}) {
	const t = useTranslation(props.scopes);
	useBodyClasses(LANG_GLOBAL_BODY_CLASSES);

	return props.render(t);
}

async function loadTranslations(language: api.Language, scopes?: string[]) {
	const result = await api.call("translations/load", { language, scopes });
	if (isError(result)) {
		return result;
	}

	return result;
}

const COMMON_SCOPES = ["label", "button", "error", "demo_form", "success", "standard"];
const DEFAULT_LANGUAGE = "en";
const localeCookie = urls.isDevDomain() ? `${Cookies.NAMES.LANGUAGE}-dev` : Cookies.NAMES.LANGUAGE;

export function useTranslation(guaranteedScopes?: string[]) {
	const [cookies] = useCookies([localeCookie]);

	const [translations, setTranslations] = useState<Translations | undefined>(
		translationsRegistry.translations
	);
	const language = cookies[localeCookie];

	const [, setState] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		translationsRegistry.scopes = {};
	}, [language]);

	useEffect(() => {
		(async function () {
			let scopes = guaranteedScopes
				? [...guaranteedScopes, ...COMMON_SCOPES]
				: [...COMMON_SCOPES];
			const scopesToLoad: string[] = [];
			const scopesToWait: string[] = [];
			for (const scope of scopes) {
				if (translationsRegistry.scopes[scope] === undefined) {
					scopesToLoad.push(scope);
				} else if (translationsRegistry.scopes[scope] !== "loaded") {
					scopesToWait.push(scope);
				}
			}

			if (scopesToLoad.length) {
				const translationsPromise = loadTranslations(
					language || DEFAULT_LANGUAGE,
					scopesToLoad
				);

				for (const scope of scopesToLoad) {
					translationsRegistry.scopes[scope] = translationsPromise;
				}

				let res = await translationsPromise;
				if (isError(res)) {
					setState(() => {
						throw res;
					});
					return;
				}

				let handlebarsTemplates: Translations = {};
				for (const key in res.data) {
					if (res.handlebars[key]) {
						handlebarsTemplates[key] = res.data[key];
						delete res.data[key];
					}
				}

				translationsRegistry.translations = {
					...translationsRegistry.translations,
					...res.data,
				};

				// Compile handlebars template async so we don't delay the rest
				setTimeout(() => compileHandlebarsTemplates(handlebarsTemplates), 0);

				for (const scope of scopesToLoad) {
					translationsRegistry.scopes[scope] = "loaded";
				}
			}

			for (const scope of scopesToWait) {
				let promise = translationsRegistry.scopes[scope];
				if (promise) {
					await promise;
				}
			}

			setTranslations(translationsRegistry.translations);
			setLoading(false);
		})();
	}, [language, guaranteedScopes]);

	// // Callback to create missing translation.
	// const createTranslation = useCallback(async (translatable: Translatable) => {
	// 	if (process.env.NODE_ENV !== "production") {
	// 		const name = typeof translatable === "string" ? translatable : translatable.message;

	// 		const result = await api.call("translations/create", { string: name });

	// 		if (isError(result)) {
	// 			// debugLog(result);
	// 		} else {
	// 			debugLog(result.success);
	// 		}
	// 	}
	// }, []);

	const t: Translator = useCallback(
		(translatable: Translatable) => translate(translations, translatable, loading),
		[translations, loading]
	);

	return t;
}

export function Text(props: { content: string }) {
	return <span dangerouslySetInnerHTML={{ __html: props.content }} />;
}

export function useLanguage() {
	return useMemo(() => {
		return (Cookies.getMyConfiguraCookie(localeCookie) as Readonly<api.Language>) || "en";
	}, []);
}

function compileHandlebarsTemplates(translations: Translations) {
	for (const key in translations) {
		translationsRegistry.translations[key] = compile(translations[key]);
	}
}
