import { useEffect, useState } from "react";
import { EventEmitter } from "./EventEmitter";

// Values are in relative unit EM
export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

export interface BreakpointEvent {
	breakpoint: Breakpoint;
}

export const BREAKPOINTS: { [K in Breakpoint]: number } = {
	xs: 0,
	// tslint:disable-next-line:object-literal-sort-keys
	sm: 31.5,
	md: 47,
	lg: 62.5,
	xl: 78.5,
};

export function calculateBreakpoint(): Breakpoint {
	const html = document.documentElement;
	if (html === null) {
		return "xs";
	}

	const htmlStyles = getComputedStyle(html);
	const caculatedFontSize = parseFloat(htmlStyles.fontSize || "16");
	const fontSize = caculatedFontSize / 0.625; // Compensate our 62.5% font-size
	const emWindowWidth = html.getBoundingClientRect().width / fontSize;

	if (emWindowWidth >= BREAKPOINTS.xl) {
		return "xl";
	} else if (emWindowWidth >= BREAKPOINTS.lg) {
		return "lg";
	} else if (emWindowWidth >= BREAKPOINTS.md) {
		return "md";
	} else if (emWindowWidth >= BREAKPOINTS.sm) {
		return "sm";
	}
	return "xs";
}

export function overBreakpoint(current: Option<Breakpoint>, threshold: Breakpoint) {
	if (current === undefined) {
		return false;
	}
	return BREAKPOINTS[current] >= BREAKPOINTS[threshold];
}

export function underBreakpoint(current: Option<Breakpoint>, threshold: Breakpoint) {
	if (current === undefined) {
		return false;
	}
	return BREAKPOINTS[current] < BREAKPOINTS[threshold];
}

let emitter: EventEmitter<BreakpointEvent> | undefined;

export function initEmitter() {
	if (emitter) {
		return emitter;
	}

	const e = new EventEmitter<BreakpointEvent>();
	emitter = e;

	e.emit({ breakpoint: calculateBreakpoint() });
	window.addEventListener(
		"resize",
		(event) => {
			const breakpoint = calculateBreakpoint();
			if (e.last !== undefined && e.last.breakpoint !== breakpoint) {
				e.emit({ breakpoint });
			}
		},
		{ passive: true }
	);

	return e;
}

export function getBreakpoint(): Breakpoint {
	return emitter?.last?.breakpoint || "xs";
}

export function useBreakpoint(): Breakpoint {
	const [event, setEvent] = useState<BreakpointEvent>();
	useEffect(() => {
		const emitter = initEmitter();
		emitter.subscribe(setEvent);
		return () => emitter.unsubscribe(setEvent);
	}, []);
	return event?.breakpoint || "xs";
}
