import { classNames, urls } from "cfg-base";
import React, { useCallback, useState } from "react";
import { ModalPortal } from "..";
import { LazyImage } from "../atoms/LazyImage";
import { SocialMediaItem } from "../atoms/SocialMediaItem";

export const CET_SOCIAL = [
	{
		handle: "/CETsoftware",
		key: "facebook",
		name: "Facebook",
		url: "https://www.facebook.com/CETsoftware/",
	},
	{
		handle: "@cet_software",
		key: "instagram",
		name: "Instagram",
		url: "https://www.instagram.com/cet_software/",
	},
	{
		handle: "/CET_software",
		key: "pinterest",
		name: "Pinterest",
		url: "https://www.pinterest.se/CET_software/",
	},
	{
		handle: "@CET_software",
		key: "twitter",
		name: "Twitter",
		url: "https://twitter.com/CET_software",
	},
];
export const CONFIGURA_SOCIAL = [
	{
		handle: "/ConfiguraIntl",
		key: "facebook",
		name: "Facebook",
		url: "https://www.facebook.com/ConfiguraIntl/",
	},
	{
		handle: "@configurans",
		key: "instagram",
		name: "Instagram",
		url: "https://www.instagram.com/configurans/",
	},
	{
		handle: "/configura",
		key: "linkedin",
		name: "LinkedIn",
		url: "https://www.linkedin.com/company/configura",
	},
	{
		handle: "@configura",
		key: "twitter",
		name: "Twitter",
		url: "https://www.twitter.com/configura",
	},
	{
		handle: "/configura",
		key: "vimeo",
		name: "Vimeo",
		url: "https://vimeo.com/configura",
	},
	{
		handle: "/ConfiguraAB",
		key: "youtube",
		name: "Youtube",
		url: "https://www.youtube.com/user/configuraab",
	},
	{
		handle: "/configura",
		key: "xing",
		name: "Xing",
		url: "https://www.xing.com/companies/configura",
	},
	{
		handle: "configura",
		key: "wechat",
		name: "WeChat",
	},
	{
		handle: "configura",
		key: "qq",
		name: "QQ",
		url: "tencent://message/?uin=3306693844&Site=https://www.configura.com.cn",
	},
];

interface Props {
	account: "cet" | "configura";
	className?: string;
	facebook?: boolean;
	instagram?: boolean;
	linkedin?: boolean;
	pinterest?: boolean;
	shareUrl?: string;
	t: Translator;
	twitter?: boolean;
	vimeo?: boolean;
	xing?: boolean;
	youtube?: boolean;
	wechat?: boolean;
	qq?: boolean;
}

export function SocialMedia(props: Props) {
	const { account, className, t } = props;
	const [isQrOpen, setIsQrOpen] = useState<boolean>(false);
	const group = account === "cet" ? CET_SOCIAL : CONFIGURA_SOCIAL;
	const socialAccounts: Array<[string, string]> = [];
	const accounts: Array<keyof Props> = [
		"facebook",
		"instagram",
		"linkedin",
		"pinterest",
		"twitter",
		"vimeo",
		"youtube",
		"xing",
		"wechat",
		"qq",
	];

	for (const a of accounts) {
		group
			.filter((media) => {
				return media.key === a;
			})
			.forEach((filtermedia) => {
				if (filtermedia.key) {
					socialAccounts.push([a, filtermedia.url || ""]);
				}
			});
	}

	const classes = classNames(["social-links", className && className]);

	const openQrModal = useCallback(() => setIsQrOpen(true), []);
	const closeQrModal = useCallback(() => setIsQrOpen(false), []);

	return (
		<>
			<ul className={classes}>
				{socialAccounts.map((socialInfo, i) => {
					const [social, link] = socialInfo;

					return (
						<SocialMediaItem
							account={account}
							key={i + 1}
							link={link}
							social={social}
							t={t}
							onClick={social === "wechat" ? openQrModal : undefined}
						/>
					);
				})}
			</ul>
			{isQrOpen && (
				<ModalPortal closeHandler={closeQrModal} title="" t={t}>
					<div className="ut-flex-center ut-padding-top-3 ut-padding-bottom-3">
						<LazyImage
							alt="wechat qr code"
							src={`${urls.STATIC_URL}/web/img/company/contact/wechat_qr_large.jpg`}
						/>
					</div>
				</ModalPortal>
			)}
		</>
	);
}
