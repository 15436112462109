import React from "react";
import { FormHandler } from "../organisms/Form";
import { Button, ButtonBaseProps, ButtonType } from "./Button";

interface Props extends ButtonBaseProps {
	type: ButtonType;
	handler: FormHandler;
	size?: "xs" | "sm" | "lg" | "xl" | "xxl";
}

export function FormButton(props: Props) {
	const disabled = props.disabled === undefined ? props.handler.disabled() : props.disabled;
	const loading = props.loading === undefined ? props.handler.loading() : props.loading;
	const text =
		props.text === undefined ? props.handler.label("button." + props.name) : props.text;
	let visualType = props.visualType;
	if (!visualType && props.type === "submit") {
		visualType = "primary";
	}

	return (
		<Button
			className={props.className}
			color={props.color}
			disabled={disabled}
			expand={props.expand}
			icon={props.icon}
			loading={loading}
			name={props.name}
			size={props.size}
			text={text}
			type={props.type}
			visualType={visualType}
		/>
	);
}
