type Listener<T> = (event: T) => void;

export class EventEmitter<T> {
	last?: T;
	listeners: Set<Listener<T>> = new Set();

	subscribe(callback: Listener<T>) {
		if (this.last !== undefined) {
			callback(this.last);
		}
		this.listeners.add(callback);
	}

	unsubscribe(callback: Listener<T>) {
		this.listeners.delete(callback);
	}

	emit(event: T) {
		this.last = event;
		this.listeners.forEach(listener => listener(event));
	}

	clear() {
		this.listeners.clear();
	}
}
