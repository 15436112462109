import { captureException } from "./helpers";

let fake: { [key: string]: any } = {};

// generic

export function remove(key: string) {
	try {
		localStorage.removeItem(key);
	} catch (e) {
		delete fake[key];
	}
}

// string

export function set(key: string, value: string) {
	try {
		localStorage.setItem(key, value);
	} catch (e) {
		fake[key] = value;
	}
}

export function get(key: string): string {
	try {
		return localStorage.getItem(key) || "";
	} catch (e) {
		return fake[key] || "";
	}
}

// JSON

export function setJSON(key: string, value: any) {
	try {
		set(key, JSON.stringify(value));
	} catch (e) {
		captureException(e, { key, value });
	}
}

export function getJSON(key: string): any {
	const value = get(key);

	if (value !== "") {
		try {
			return JSON.parse(value);
		} catch (e) {
			remove(key);
			captureException(e, { key, value });
		}
	}

	return;
}

export function setJSONArray(key: string, value: any[]) {
	setJSON(key, value);
}

export function getJSONArray(key: string): any[] {
	return getJSON(key) || [];
}

export function clear() {
	try {
		localStorage.clear();
	} catch (e) {
		fake = {};
	}
}

export class TypedCache<T> {
	get<K extends keyof T & string>(key: K): T[K] | undefined {
		return getJSON(key);
	}

	set<K extends keyof T & string>(key: K, val: T[K]): void {
		setJSON(key, val);
	}

	getVersioned<K extends keyof T & string>(key: K, version: number): T[K] | undefined {
		return getJSON(`${key}-${version}`);
	}

	setVersioned<K extends keyof T & string>(key: K, version: number, val: T[K]): void {
		setJSON(`${key}-${version}`, val);
	}
}

export class PrefixTypedCache<T> extends TypedCache<T> {
	constructor(readonly prefix: string) {
		super();
	}

	get<K extends keyof T & string>(key: K): T[K] | undefined {
		return super.get(this.prefix + key as any);
	}

	set<K extends keyof T & string>(key: K, val: T[K]): void {
		super.set(this.prefix + key as any, val);
	}

	getVersioned<K extends keyof T & string>(key: K, version: number): T[K] | undefined {
		return getJSON(`${this.prefix + key}-${version}`);
	}

	setVersioned<K extends keyof T & string>(key: K, version: number, val: T[K]): void {
		setJSON(`${this.prefix + key}-${version}`, val);
	}
}
