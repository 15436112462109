import { classNames } from "cfg-base";
import React from "react";

interface Props {
	color?: "black" | "white";
	size?: "xs" | "sm" | "lg";
}

export function LoadingSpinner(props: Props) {
	const { color, size } = props;
	const spinnerClasses = classNames([
		"load-spinner",
		color !== undefined && color === "black" && "mod-black",
		size !== undefined && `mod-${size}`,
	]);

	return <i className={spinnerClasses} />;
}
