import { classNames, TypedKeys } from "cfg-base";
import React, { useCallback } from "react";
import { HookFormRequestUpdater } from "./HookForm";

export interface HookFormRadioInputProps<
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<string | number, Request["values"]>,
	Value extends Request["values"][Name]
> extends RadioProps {
	formState: Request;
	name: Name;
	t: Translator;
	value: Value;
	hideLabel?: boolean;
}

export const HookFormRadioInput = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<string | number, Request["values"]> = any, // `any` is needed here for sane error mesages
	Value extends Request["values"][Name] = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<HookFormRadioInputProps<Request, Name, Value>>
): React.ReactElement => {
	const { name, t, value, hideLabel } = props;

	// here we are using `any` to cast the request into a shape we know it has because we have
	// constrained the type of `Name` to only the keys with `Value` type values
	const request = (props.formState as any) as HookFormRequestUpdater<{ [_ in Name]: Value }>;
	const { setValue } = request;

	const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => setValue(name, value),
		[name, value, setValue]
	);

	const message = request.validation[name];
	const checked = value === request.values[name];

	return (
		<RadioInput
			checked={checked}
			disabled={props.disabled || request.loading}
			helpText={props.helpText}
			id={`${request.id}-${name}-${value}`}
			label={hideLabel ? "" : props.label || t("label." + name)}
			labelBold={props.labelBold}
			message={typeof message === "string" ? t(message) : undefined}
			name={name}
			onChange={onChange}
			required={props.required}
			value={`${props.value}`}
		></RadioInput>
	);
};

interface RadioProps {
	className?: string;
	disabled?: boolean;
	helpText?: string;
	required?: boolean;
	label: string;
	labelBold?: boolean;
}

interface RadioInputProps extends RadioProps {
	value: string;
	checked: boolean;
	id: string;
	message?: string;
	name: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioInput: React.FC<RadioInputProps> = (props) => {
	const {
		labelBold,
		name,
		id,
		checked,
		required,
		disabled,
		message,
		onChange,
		label,
		className,
		helpText,
	} = props;

	let value = props.value;
	if (value === undefined) {
		value = "on";
	}

	const error = message ? <small className="form-message">{message}</small> : "";
	const help = helpText ? <small dangerouslySetInnerHTML={{ __html: helpText }}></small> : "";

	const fieldClass = classNames([
		"form-field",
		disabled && "mod-disabled",
		error !== "" && "mod-error",
		className,
	]);

	const labelClass = classNames(["form-label", labelBold && "geo-bold"]);

	return (
		<div className={fieldClass}>
			<label className={labelClass} htmlFor={id}>
				<input
					className="form-radio"
					checked={checked}
					disabled={disabled}
					id={id}
					name={name}
					onChange={onChange}
					required={required}
					type="radio"
					value={value}
				/>
				{label}
			</label>
			{error}
			{help}
		</div>
	);
};
