import { translate } from "./translate";

const lang: { [key: string]: Option<string | ((args: Array<string | number>) => string)> } = {
	// Buttons
	"button.accept": "Accept",
	"button.add": "Add",
	"button.add_another_admin": "Add another admin",
	"button.add_another_contact": "Add another contact",
	"button.add_another_participant": "Add another participant",
	"button.add_another_support_contact": "Add another support contact",
	"button.add_support_contact": "Add support contact",
	"button.add_application": "Add new application",
	"button.add_award": "Add award",
	"button.add_category": "Add category",
	"button.add_customer": "Add customer",
	"button.add_discount": "Add discount",
	"button.add_email": "Add email",
	"button.add_event": "Add event",
	"button.add_job": "Add Job",
	"button.add_manufacturer": "Add Manufacturer",
	"button.add_member": "Add member",
	"button.add_participant": "Add participant",
	"button.add_session": "Add session",
	"button.add_slot": "Add slot",
	"button.add_speaker": "Add speaker",
	"button.add_sponsor": "Add sponsor",
	"button.agree": "Agree",
	"button.allocate_licenses": "Allocate licenses",
	"button.apply": "Apply",
	"button.approve": "Approve",
	"button.attend_webinar": "Academy",
	"button.awards": "Awards",
	"button.back": "Back",
	"button.back_to_myconfigura": "Back to MyConfigura",
	"button.briefcase_delete": "Delete Briefcase",
	"button.briefcase_leave": "Leave Briefcase",
	"button.build_center": "Build Center",
	"button.bulk_action": "Bulk action",
	"button.buy_now": "Buy now",
	"button.buy_tickets": "Buy tickets",
	"button.cancel": "Cancel",
	"button.change_membership": "Change Membership",
	"button.check_all": "Check all",
	"button.check_out_other_class": "Check out other classes",
	"button.claim_license": "Claim license",
	"button.clear": "Clear",
	"button.clear_output": "Clear Output",
	"button.clear_production_keys": "Delete Production Keys",
	"button.clear_test_keys": "Delete Test Keys",
	"button.close": "Close",
	"button.confirm": "Confirm",
	"button.confirm_and_save": "Confirm & Save",
	"button.create": "Create",
	"button.decline": "Decline",
	"button.delete": "Delete",
	"button.delete_post": "Delete post",
	"button.details": "Details",
	"button.download": "Download",
	"button.download_pdf": "Download PDF",
	"button.edit": "Edit",
	"button.edit_all": "Edit all",
	"button.edit_award": "Edit award",
	"button.edit_bank_info": "Edit bank info",
	"button.edit_event": "Edit event",
	"button.edit_extension": "Edit Extension",
	"button.edit_job": "Edit job",
	"button.edit_prices": "Edit Prices",
	"button.edit_product": "Edit Product",
	"button.edit_post": "Edit post",
	"button.error": "Error",
	"button.export_to_excel": "Export to Excel",
	"button.extend_licenses": (a) => `Extend licenses (${a[0]})`,
	"button.feedback": "Feedback",
	"button.filter": "Filter",
	"button.find_licenses": "Find licenses",
	"button.find_users": "Find users",
	"button.generate_in_progress_since": (a) => `Generation in progress since ${a[0]}`,
	"button.generate_now": "Generate now",
	"button.generate_report": "Generate report",
	"button.get_directions": "Get directions",
	"button.get_information": "Get Info",
	"button.give_licenses": "Give licenses",
	"button.go_back": "Go back",
	"button.go_to_awards": "Go to awards",
	"button.go_to_schedule": "Go to schedule",
	"button.got_it": "Got it",
	"button.grant": "Grant",
	"button.here": "Here",
	"button.import_xml": "Import XML",
	"button.i_understand": "I Understand",
	"button.hide": "Hide",
	"button.hide_arguments": "Hide Arguments",
	"button.industry_solutions": "Industry solutions",
	"button.leave": "Leave",
	"button.let_your_boss_know": "Let your boss know",
	"button.link": "Link",
	"button.list_licenses": "List Licenses",
	"button.log_out": "Log out",
	"button.login": "Login",
	"button.login_as_user": "Login as user",
	"button.logs": "Logs",
	"button.make_available": "Make available",
	"button.make_available_in_stage": "Make available in Stage",
	"button.make_available_to_other_editions": "Setup new edition",
	"button.manage_discounts": "View discounts and customers",
	"button.manage_grandfathered": "View grandfathered",
	"button.marketplace": "Marketplace",
	"button.material": "Material",
	"button.merge": "Merge",
	"button.na_yet": "N/A Yet",
	"button.new_post": "New post",
	"button.next": "Next",
	"button.no": "No",
	"button.notify_your_boss": "Notify your boss",
	"button.offline": "Offline",
	"button.our_platform": "Our platform",
	"button.pdf": "PDF",
	"button.pin_post_to_top": "Pin post to top",
	"button.play_learn_more": "Play video and learn more",
	"button.post": "Post",
	"button.post_delete": "Delete Post",
	"button.prev": "Prev",
	"button.preview": "Preview",
	"button.proceed_checkout": "Proceed to checkout",
	"button.proceed_summary": "Proceed to summary",
	"button.refresh": "Refresh",
	"button.refresh_all_data": "Refresh all data",
	"button.register": "Register",
	"button.registration_closed": "Registration closed",
	"button.release": "Release",
	"button.remove": "Remove",
	"button.remove_customer": "Remove customer",
	"button.reply": "Reply",
	"button.request_reset": "Reset password",
	"button.reset": "Reset",
	"button.reset_password": "Reset password",
	"button.revert": "Revert",
	"button.revoke": "Revoke",
	"button.return_to_support_page": "Return to support page",
	"button.roles": "Roles",
	"button.save": "Save",
	"button.save_changes": "Save changes",
	"button.scan_keys": "Scan Keys",
	"button.search": "Search",
	"button.settings": "Settings",
	"button.select": "Select",
	"button.send": "Send",
	"button.send_me_link": "Send me the link",
	"button.service": "Service",
	"button.show": "Show",
	"button.show_arguments": "Show Arguments",
	"button.show_less": "Show less",
	"button.show_more": "Show more",
	"button.show_more_frames": (a) => `Show more frames (${a[0]})`,
	"button.sign": "Sign",
	"button.sign_up": "Sign up",
	"button.sign_up_stage": "Sign up for Stage",
	"button.sign_up_now": "Sign up now",
	"button.show_pending": "Show pending",
	"button.sold_out": "Sold out",
	"button.speakers": "Speakers",
	"button.start": "Start",
	"button.submit": "Submit",
	"button.take_survey": "Take the survey",
	"button.try_cet": "Try CET for free",
	"button.restrict_visibility": "Restrict visibility",
	"button.tickets": (a) => `Tickets ${a[0]}`,
	"button.tickets_later_on": "Ticket later on",
	"button.undo": "Undo",
	"button.unlink": "Unlink",
	"button.unpin_post": "Unpin post",
	"button.update": "Update",
	"button.update_password": "Update password",
	"button.venue_map": "Venue map",
	"button.view": "View",
	"button.view_and_sign": "View and sign",
	"button.view_all": "View all",
	"button.view_session": "View session",
	"button.view_summary": "View summary",
	"button.vote": "Vote",
	"button.warning": "Warning",
	"button.watch_video": "Watch video",
	"button.want_to_upgrade": "Want to upgrade?",
	"button.whats_included_tier": "What's in my tier?",
	"button.welcome_events": "Welcome events",
	"button.yes": "Yes",

	// Labels
	"label.about": "About",
	"label.academy": "Academy",
	"label.account": "Account",
	"label.account_iban": "IBAN or Account number",
	"label.access_token": "Access token",
	"label.accessories": "Accessories",
	"label.active": "Active",
	"label.activate": "Activate",
	"label.active_subscriptions": "Active subscriptions",
	"label.activity_logs": "Activity logs",
	"label.add": "Add",
	"label.add_domain": "Add Domain",
	"label.add_new_manager": "Add new manager",
	"label.add_people": "Add People",
	"label.added": "Added",
	"label.additional_settings": "Additional settings",
	"label.address": "Address",
	"label.address1": "Address line 1",
	"label.address2": "Address line 2",
	"label.address_line_1": "Address Line 1",
	"label.address_line_2_optional": "Address line 2 (Optional)",
	"label.address_line_3_optional": "Address line 3 (Optional)",
	"label.address_shenzhen":
		"4502, 45th Floor, Building A, Kingkey Timemark, No. 9289 Binhe Avenue, Futian District, Shenzhen, China",
	"label.additions": "Additions",
	"label.admin_doer": "Admin Doer",
	"label.admin_uid": "Admin User",
	"label.admins": "Admins",
	"label.affected_uid": "Affected User",
	"label.agreement": "Agreement",
	"label.service_agreement": "Service Agreement",
	"label.agreements": "Agreements",
	"label.alerts": "Alerts",
	"label.all": "All",
	"label.all_activity": "All activity (all posts and comments)",
	"label.all_campaigns": "All campaigns",
	"label.all_subscriptions_disabled": "All subscriptions are disabled",
	"label.allocate_licenses": "Allocate Licenses",
	"label.allocated_to": "Allocated to",
	"label.allow_add_build_to_official": "Allow Builds to be Added to Official Distribution",
	"label.allow_offline": "Allow offline licenses export functionality",
	"label.allow_offline_tag": "Allow offline",
	"label.allow_license_pool": "Allow license pools functionality",
	"label.allow_license_pool_tag": "Allow license pool",
	"label.alternative": "Alternative",
	"label.always_valid": "Always valid",
	"label.amount_transfered": "Amount transfered",
	"label.analytics": "Analytics",
	"label.anchor_buttons": "Anchor buttons",
	"label.annual_reports": "Annual reports",
	"label.api_key": "API Key",
	"label.api_result": "ApiResult",
	"label.app_id": "App Id",
	"label.applicable_to": "Applicable to",
	"label.application": "Application",
	"label.application_partner": "Application partner",
	"label.apply_to_all_customers": "Apply to all customers",
	"label.archive": "Archive",
	"label.asia": "Asia",
	"label.atomic_design": "Atomic design",
	"label.atoms": "Atoms",
	"label.attachment": "Attachment",
	"label.available_in_stage": "Available in Stage",
	"label.available_soon": "Not available yet, coming soon.",
	"label.avatar": "Avatar",
	"label.avg_count": "Average Count",
	"label.avg_duration": "Average Duration",
	"label.authorized_representative_owner":
		"I am the authorized representative/owner for the manufacturer.",
	"label.authorized_representative_owner_email": "Authorized Representative/Owner Email Address",
	"label.awards": "Awards",
	"label.back": "Back",
	"label.bank_code": "Bank code",
	"label.bank_id": "Bank ID",
	"label.bank_name": "Bank name",
	"label.base": "Base",
	"label.become_a_partner": "Become a partner",
	"label.block_catalogues": "Block catalogues",
	"label.blocked_catalogues": "Catalogues Blocked",
	"label.berlin": "Berlin",
	"label.beta": "Beta",
	"label.bic_swift": "BIC/Swift code",
	"label.blue": "Blue",
	"label.blue_light": "Blue light",
	"label.block_update": "Block update",
	"label.board_and_management": "Board and management",
	"label.body": "Body",
	"label.body_de": "Body (de)",
	"label.body_en": "Body (en)",
	"label.body_ja": "Body (ja)",
	"label.body_zh": "Body (zh)",
	"label.bought_licenses": "Bought licenses",
	"label.branch_name": "Branch name (for example: version/13.5/BETA)",
	"label.breadcrumbs": "Breadcrumbs",
	"label.briefcase": "Briefcase",
	"label.briefcase_confirm_delete": "Are you sure you want to delete this briefcase?",
	"label.briefcase_confirm_delete_comment": "Are you sure you want to delete this comment?",
	"label.briefcase_confirm_delete_invite": "Are you sure you want to remove the invitation?",
	"label.briefcase_confirm_delete_post": "Are you sure you want to delete this post?",
	"label.briefcase_confirm_leave": "Are you sure you want to leave this briefcase?",
	"label.briefcase_description": "Briefcase description",
	"label.briefcase_feed": "Briefcase Feed",
	"label.briefcase_leave": "Leave/Delete Briefcase",
	"label.briefcase_logo": "Briefcase logo",
	"label.briefcase_name": "Briefcase name",
	"label.briefcase_schedule_posting": "Schedule posting",
	"label.briefcases": "Briefcases",
	"label.bronze": "Bronze",
	"label.browser_support": "Browser support",
	"label.build": "Build",
	"label.build_center": "Build Center",
	"label.bullet_point": "Type to add bullet text, leave empty if not used",
	"label.building": "Building",
	"label.buttons": "Buttons",
	"label.buy_cet_designer": "Buy CET",
	"label.buy_now": "Buy now",
	"label.buy_tickets": "Buy tickets",
	"label.buyer": "Buyer",
	"label.by": "By",
	"label.cache_management": "Cache Management",
	"label.cache_stage_management": "Stage Cache Management",
	"label.cache_myconfigura_management": "MyConfigura Cache Management",
	"label.campaign_name": "Campaign name",
	"label.campaign_save_extension_preset": (a) => `Save extensions as preset for ${a[0]}.`,
	"label.can_register_multiple_participants": "Can register multiple participants",
	"label.cancel": "CANCEL",
	"label.capacity": "Capacity",
	"label.card_image": "Card image",
	"label.card_text": "Card text",
	"label.cards": "Cards",
	"label.car_park": "Car park and transport benefits",
	"label.case_studies": "Case studies",
	"label.catalogue": "Catalogue",
	"label.catalogue_creator": "Catalogue Creator",
	"label.catalogue_in_stage_since": (a) =>
		`This catalogue have been available in Stage since ${a[0]}.`,
	"label.photos": "Photos",
	"label.pick_an_edition": "Pick an edition",
	"label.platform": "Platform",
	"label.participants": "Participants",
	"label.pm_data_name": "PM Data name",
	"label.pm_id": "PM ID",
	"label.presentation": "Presentation",
	"label.presentation_slides": "Presentation slides",
	"label.canceled": "Canceled",
	"label.catalogue_status_info": "If any job is taking too long, contact the Webteam.",
	"label.catalogues": "Catalogues",
	"label.catalogue_pay_status": "Pay Status",
	"label.catalogue_pay_status_comment": "Pay Status Comment",
	"label.category": "Category",
	"label.caughtat": "Caught At",
	"label.certificate": "Certificate",
	"label.cet": "CET",
	"label.cet_designer": "CET",
	"label.cet_designer_64": "CET 64-bit",
	"label.cet_designer_trial": "CET Trial",
	"label.cet_designer_usage_description":
		"The usage reports are collected and submitted monthly and goes back to March 2017. This page shows the top 100 events for each month.",
	"label.cet_designer_usage_disclaimer":
		"Note: Only data from CET Core developers is shown here.",
	"label.cet_designer_usage_title": "CET Usage",
	"label.cet_developer": "CET Developer",
	"label.cet_developer_subextension": "CET Developer sub-extension",
	"label.cet_experience": "CET Experience",
	"label.cet_extensions": "CET Extensions",
	"label.cet_migration_guides": "CET Migration Guides",
	"label.cet_qa": "CET QA",
	"label.cet_release": "CET Release",
	"label.change_participant_from_all_event": "Change participant from all related events",
	"label.chat_support": "Chat support",
	"label.chinese": "Chinese",
	"label.choose_alternative": "Choose alternative",
	"label.choose_datetime": "Choose date and time",
	"label.choose_event_group": "Choose an event group",
	"label.choose_file_type": "Choose a file type",
	"label.choose_industry": "Choose an industry",
	"label.choose_registration_event": "Choose registration event",
	"label.choose_speaker": "Choose a speaker",
	"label.choose_tag": "Choose a tag",
	"label.city": "City",
	"label.class_title": "Title",
	"label.classes": "Classes",
	"label.client": "Client",
	"label.closed": "Closed",
	"label.closing_date": "Closing date",
	"label.cn_version": "中文版",
	"label.cnt": "Total",
	"label.cntExpiredDates": "Different Expired Dates",
	"label.cny": "CNY",
	"label.code_conventions": "Code conventions",
	"label.collab_workspace": "Collaboration Workspace",
	"label.color": "Color",
	"label.colors": "Colors",
	"label.comment": "Comment...",
	"label.comments_are_disabled": "Comments are disabled for this post",
	"label.commercial_furniture": "Commercial Interior",
	"label.commercial_interiors": "Commercial Interiors",
	"label.company": "Company",
	"label.company_de": "Configura GmbH i.G.",
	"label.company_gr": "Configura Inc.",
	"label.company_gr_info": "Main entrance of building on Commerce Ave SW",
	"label.company_kl": "Configura Pacific Sdn Bhd",
	"label.company_se": "Configura Sverige AB",
	"label.competitor_verification":
		"Agree to potential verification by current competitor licenses",
	"label.competitor_verify": "Competitor verify",
	"label.configura": "Configura",
	"label.configura_account_manager_optional": "Configura Account Manager (optional)",
	"label.configura_billing": "Configura billing",
	"label.configura_employee": "Configura Employee",
	"label.configura_news": "Configura News",
	"label.configura_software": "Configura Software",
	"label.configura_support": "Configura Support",
	"label.confirm_value": "Confirm value",
	"label.confirmed": "Confirmed",
	"label.connected_extensions": "Connected to",
	"label.contact": "Contact",
	"label.contact_person": "Contact person",
	"label.contact_person_name": "Contact person's name",
	"label.contact_us": "Contact Us",
	"label.content_admin": "Content admin",
	"label.content_tier": "Content Package",
	"label.content_extension": "Content Extension",
	"label.continent_as": "Asia",
	"label.continent_eu": "Europe",
	"label.continent_na": "North America",
	"label.control": "Control",
	"label.convince_your_boss": "Convince your boss",
	"label.corporate_social_responsibilities": "Corporate social responsibilities",
	"label.cost": "Cost",
	"label.cost_control": "Cost Control",
	"label.count": "Count",
	"label.countdowns": "Countdowns",
	"label.country": "Country",
	"label.country_cn": "China",
	"label.country_de": "Germany",
	"label.country_my": "Malaysia",
	"label.country_se": "Sweden",
	"label.country_us": "United States",
	"label.cpi": "CPI",
	"label.crashedat": "Crashed At",
	"label.create": "Create",
	"label.created": "Created",
	"label.created_at": "Created Date",
	"label.created_by": "Created By",
	"label.created_on": "Created On",
	"label.cron_jobs": "Cron Jobs",
	"label.currency": "Currency",
	"label.currency_cny": "CNY",
	"label.currency_eur": "EUR",
	"label.currency_gbp": "GBP",
	"label.currency_jpy": "JPY",
	"label.currency_sek": "SEK",
	"label.currency_usd": "USD",
	"label.current_logo": "Current logotype",
	"label.customer": "Customer",
	"label.customer_id": "Customer ID",
	"label.customer_no": "Customer No",
	"label.customer_type": "Customer type",
	"label.customer_confirm_allow_license_pool":
		'You have enabled the "Allow license pools functionality" setting. The action is irreversible upon form submission.',
	"label.customers": "Customers",
	"label.dashboard": "Dashboard",
	"label.dashboard_welcome":
		"Welcome to MyConfigura! If you are new here, please have a look at our ",
	"label.date": "Date",
	"label.date_show_on_marketplace": "Subscription Date",
	"label.day": "Day",
	"label.days_until_payment": "Days until payment",
	"label.datetime": "Date and Time",
	"label.de": "German",
	"label.deactivate": "Deactivate",
	"label.debug": "Debug",
	"label.delete_user": "Delete user",
	"label.delete_key": "Delete Key",
	"label.demo": "Demo",
	"label.demote_manager": "Demote manager",
	"label.deprecated": "Deprecated",
	"label.deprecated_info":
		"Since we are rebranding CET into industry products, the CET marketplace is in the process of being phased out.",
	"label.description": "Description",
	"label.design_software": "Design Software",
	"label.design_studio": "Design Studio",
	"label.designer_forum": "CET Forum",
	"label.dev_center": "Dev center",
	"label.dev_cet": "CET Supervisors",
	"label.dev_configura": "Configura Admins",
	"label.dev_core": "Core Developers",
	"label.dev_test": "Test Users",
	"label.dev_training": "Instructors",
	"label.dev_webteam": "Webteam",
	"label.developer": "Developer",
	"label.developer_conference": "Developer Conference",
	"label.developer_forum": "CET Developer Forum",
	"label.developer_portal": "Developer Portal",
	"label.developers": "Developers",
	"label.development_partner": "Development partner",
	"label.diamond": "Diamond",
	"label.diff": "Diff",
	"label.domain": "Domain",
	"label.done_reviewed": "Done",
	"label.disable_comments": "Disable comments",
	"label.disable_sso": "Disable SSO",
	"label.disabled": "Disabled",
	"label.discard_changes": "Are you sure you want to discard your changes?",
	"label.disclaimer_document": "Disclaimer document",
	"label.discount": "Discount",
	"label.discounts": "Discounts",
	"label.doer_uid": "Doer User",
	"label.download": "Download",
	"label.download_extensions": "Download Extensions",
	"label.download_material": "Download material",
	"label.downloads": "Downloads",
	"label.drawing": "Drawing",
	"label.drivers": "Drivers",
	"label.drivers_upload_description":
		"This page is to let MyConfigura employee who are Core access right to upload external drivers. When all files are uploaded, the list at the bottom will be updated.",
	"label.dropdown": "Dropdown",
	"label.dropdowns": "Dropdowns",
	"label.due_at": "Due Date",
	"label.duration": "Duration",
	"label.eab_configura": "EAB - Configura",
	"label.escrow_agreement": "Escrow agreement",
	"label.escrow_information": "Escrow information",
	"label.escrow_subscription": "Escrow subscription",
	"label.essential": "Essential",
	"label.essentials": "Essentials",
	"label.experimental": "Experimental",
	"label.economy": "Economy",
	"label.edit": "Edit",
	"label.edit_log": "Edit Log",
	"label.edited": "Edited",
	"label.edition": "Edition",
	"label.editionType": "Edition type",
	"label.edition_image": "Edition image",
	"label.editions": "Editions",
	"label.education": "Education",
	"label.education_development": "Education/Development",
	"label.education_program": "Education program",
	"label.email": "Email address",
	"label.email_addresses": "Email addresses",
	"label.email_text": "Email text",
	"label.email_or_domain": "Email address or domain",
	"label.emails": "Emails",
	"label.embed_link": "Google Maps embed link",
	"label.en": "English",
	"label.end_date": "End date",
	"label.end_time": "End time",
	"label.english": "English",
	"label.ent_code": "PM Enterprise Code",
	"label.enhanced": "Enhanced",
	"label.environmental": "Environmental",
	"label.error": "Error",
	"label.eur": "EUR",
	"label.europe": "Europe",
	"label.evaluating": "Evaluating",
	"label.event": "Event",
	"label.event_group": "Event group",
	"label.event_name": "Event name",
	"label.event_url": "Event url",
	"label.events": "Events",
	"label.events_old": "Events (Old)",
	"label.example": "Example",
	"label.example_upload_page_description":
		"This page is here to demonstrate how to use file file upload component. You can upload files with type 'public' and when all files are uploaded, the list at the bottom will be updated.",
	"label.exchange_rates": "Exchange rates",
	"label.exec_interval": "Execution Interval",
	"label.exec_window": "Execution Window",
	"label.existing": "Existing",
	"label.exp_date": "Expire Date",
	"label.expand_list": "Expand list",
	"label.expiration_date": "Expiration Date",
	"label.expired_date": "Different Expiration Dates",
	"label.expiry_date": (a) => `Expiry Date: ${a[0]}`,
	"label.explore_industry_solutions": "Explore our industry solutions",
	"label.export": "Export",
	"label.export_all": "Export all",
	"label.export_extension_users": "Export extension users",
	"label.extend_license": "Extend License",
	"label.extend_licenses": "Extend Licenses",
	"label.extension": "Extension",
	"label.extension_edition": "Extension (Edition)",
	"label.extension_id": "Extension",
	"label.extension_info": "Extension Info",
	"label.extension_name": "Extension Name",
	"label.extension_in_official": "In Official",
	"label.extensions": "Extensions",
	"label.extension_supervisor_approval": "Supervisor approval",
	"label.external_api_keys": "External API Keys",
	"label.extra_data": "Extra data",
	"label.fact": "Fact",
	"label.favorite": "Favorite",
	"label.favorites": "Favorites",
	"label.features": "Features",
	"label.field": "Field",
	"label.file": "File",
	"label.file_archive": "File archive",
	"label.files": "Files",
	"label.filespace": "Filespace",
	"label.filespace_cat": "Catalogues Filespace",
	"label.filespace_catalogues_block": (a) =>
		`You are about to ${a[0]} catalogue for manufacturer ${a[1]}`,
	"label.filespace_catalogues_unblock":
		"You are about to unblock catalogue for this manufacturer",
	"label.filespace_ext": "Extension Filespace",
	"label.filter": "FILTER",
	"label.filter_cronjob_help":
		"Type something from 'Name' and 'Description' column or keywords: watch, unwatch, active, or inactive",
	"label.filter_help": "Start typing to filter the list",
	"label.filter_tags": "Filters tags",
	"label.filters": "Filters",
	"label.find": "Find",
	"label.find_a_partner": "Find a partner",
	"label.find_users_emails": "E-mails",
	"label.first": "First",
	"label.first_received_readable": "First Received",
	"label.firstname": "First Name",
	"label.flexible_worktime": "Flexible worktime",
	"label.float": "Float",
	"label.footers": "Footers",
	"label.force_sso": "Force SSO",
	"label.force_uninstall": "Force uninstall",
	"label.forgot_your_password": "Forgot your password?",
	"label.forms_deprecated": "Forms (Deprecated)",
	"label.forum": "Forum",
	"label.forum_developer": "Developer",
	"label.forum_other": "Other",
	"label.forum_support": "User Support",
	"label.forums": "Forums",
	"label.free": "Free",
	"label.free_webinars": "Free webinars",
	"label.from_china": "From China",
	"label.front_end_docs": "Front-end docs",
	"label.full_time_percent": "Full-time (100%)",
	"label.fun_and_games": "Fun and Games",
	"label.gbp": "GBP",
	"label.general": "General",
	"label.general_inquiries": "General & media inquiries",
	"label.generated": "Generated",
	"label.german": "German",
	"label.get_to_know": "Get to know CET",
	"label.getting_started": "Getting started with CET",
	"label.git_manufacturer": "Gitlab manufacturer",
	"label.git_project": "Gitlab project",
	"label.give_free_license": "Give free license",
	"label.gltf_viewer": "GLTF Viewers",
	"label.gold": "Gold",
	"label.gothenburg": "Göteborg",
	"label.grand_total": "Grand total",
	"label.grandfathered_discounts": "[This is a grandfathered discount]",
	"label.grandrapids": "Grand Rapids, MI",
	"label.gray": "Gray",
	"label.gray_dark": "Gray dark",
	"label.gray_darker": "Gray darker",
	"label.gray_light": "Gray light",
	"label.gray_lighter": "Gray lighter",
	"label.gray_lightest": "Gray lightest",
	"label.green": "Green",
	"label.green_light": "Green light",
	"label.grid": "Grid",
	"label.groupIds": "Group Reference",
	"label.group_id": "Group ID",
	"label.halfyear": "Half Year",
	"label.handlebars_enabled": "Enable Handlebars",
	"label.hash": "Hash",
	"label.having_trouble_signin": "Having trouble logging in?",
	"label.health_sports": "Health/Sports",
	"label.health_fitness": "Health & fitness benefits",
	"label.heart_statistics": "Heart statistics",
	"label.hearts": "Hearts",
	"label.hero": "Hero",
	"label.help_center": "Help Center",
	"label.hidden": "Hidden",
	"label.hide": "Hide",
	"label.hide_comment": "Hide comment",
	"label.hide_comments": "Hide comments",
	"label.home": "Home",
	"label.home_url": "Home page URL",
	"label.hooks": "Hooks",
	"label.hook_forms": "Hook Forms",
	"label.how_can_we_help_you": "How can we help you?",
	"label.hr": "Hr",
	"label.icons": "Icons",
	"label.id": "ID",
	"label.idcec": "IDCEC",
	"label.identifier": "Identifier",
	"label.image": "Image",
	"label.implementation": "Implementation",
	"label.implementation_partner": "Service and Development partner",
	"label.in_review": "In Review",
	"label.in_the_press": "In The Press",
	"label.incomplete": "Incomplete",
	"label.industry": "Industry",
	"label.industry_description": "Industry description",
	"label.industry_library": "Industry Library",
	"label.industry_solutions": "Industry solutions",
	"label.industry-specific": "Industry-specific",
	"label.info": "Info",
	"label.information": "Information",
	"label.inscape": "Inscape",
	"label.installer": "Installer",
	"label.installed_licenses": "Installations",
	"label.instance_type": "Instance type",
	"label.instant": "Instant",
	"label.instant_light": "Instant light",
	"label.instantplanner": "InstantPlanner",
	"label.instructor": "Instructor",
	"label.instructors": "Instructors",
	"label.insurance_package": "Insurance package",
	"label.integer": "Integer",
	"label.internal": "Internal",
	"label.internal_voting": "Internal voting",
	"label.invite": " Invite",
	"label.invite_members": " Invite members",
	"label.invites": " Invites",
	"label.invites.add.manager": "Add new manager",
	"label.invites.add.member": " Add new member",
	"label.invoice": "Invoice",
	"label.invoiced": "Invoiced",
	"label.invoice_id": "Invoice ID",
	"label.invoice_link": "Invoice",
	"label.invoice_status": "Invoice Status",
	"label.invoices": "Invoices",
	"label.invoices_near_suspension": "Invoices Near Suspension",
	"label.is_shown": "Show session",
	"label.item": "Item",
	"label.ja": "Japanese",
	"label.japanese": "Japanese",
	"label.jira_project_name": "Jira project name",
	"label.job_errors_in_last_24h": "Job errors in the last 24 hours",
	"label.job_opportunities": "Job opportunities",
	"label.jpy": "JPY",
	"label.key": "Key",
	"label.keynote": "Keynote",
	"label.keep_logged_in": "Keep me logged in",
	"label.kitchen": "Kitchen",
	"label.kitchen_and_bath": "Kitchen and Bath",
	"label.kualalumpur": "Kuala Lumpur",
	"label.language": "Language",
	"label.last_exec_time": "Last Execution Time",
	"label.last_finish": "Last Finish",
	"label.last_post": "Last Post",
	"label.last_viewed": "Last viewed",
	"label.lastname": "Last Name",
	"label.latest": "Latest",
	"label.lazy_images": "Lazy images",
	"label.learning": "Learning",
	"label.leave_delete_briefcase": "Leave/Delete Briefcase",
	"label.leave_feedback": "Leave feedback",
	"label.length": "Length",
	"label.level": "Level",
	"label.license": "License",
	"label.licenses_and_channels": "Licenses & Channels",
	"label.license_admin_email": "License administrator email",
	"label.license_id": "License ID",
	"label.license_logs": "License logs",
	"label.license_manager": "License Manager",
	"label.license_name": "License Name",
	"label.license_pool": "License pool",
	"label.license_type": "License Type",
	"label.license_used_allocated": `Used: (indicates "Allocated" license status)`,
	"label.license_purchased_payable": `Purchased: (indicates "Payable" license type)`,
	"label.licenses": "Licenses",
	"label.licenses_expire": "Licenses expire at",
	"label.licenses_extended": "License(s) extended.",
	"label.like_placeholder": "Be the first to like this post",
	"label.like_post": "Like this post",
	"label.limit": "Limit",
	"label.linked_sentry_extension": "Linked Sentry Extension",
	"label.link_to": "Link To",
	"label.linked_to": "Linked To",
	"label.linked_pm_data": "Linked PM Data",
	"label.linkoping": "Linköping",
	"label.links": "Links",
	"label.list": "List",
	"label.live_link": "Live link",
	"label.loading": "Loading",
	"label.loading_spinners": "Loading spinners",
	"label.location": "Location",
	"label.log": "Log",
	"label.login_passwd": "Password",
	"label.login_user": "Your MyConfigura ID (Email Address)",
	"label.login_with_email": "Login with MyConfigura ID (Email Address) and Password",
	"label.login_with_sso": "Login with Single Sign-On (SSO)",
	"label.logs": "Logs",
	"label.log_out": "Log out",
	"label.logo": "Logo",
	"label.lost_discounts": "Lost discounts",
	"label.maars": "Maars",
	"label.machine": "Machine",
	"label.machine_statistics": "Machine statistics",
	"label.manager": "Manager",
	"label.managers": "Managers",
	"label.manufacturer": "Manufacturer",
	"label.manufacturer_id": "Manufacturer ID",
	"label.manufacturers": "Manufacturers",
	"label.manufacturer_name": "Manufacturer name",
	"label.market": "Market",
	"label.marketplace": "Marketplace",
	"label.material_zip_file": "Material (ZIP File)",
	"label.material_handling": "Material Handling",
	"label.material_handling_essentials": "CET Essentials for Material Handling",
	"label.material_handling_gonvarri": "CET Material Handling Gonvarri Edition",
	"label.may": "May",
	"label.max_attendees": "Max attendees",
	"label.media_kit": "Media kit",
	"label.member": "Member",
	"label.members": "Members",
	"label.metadata": "Metadata",
	"label.menus": "Menus",
	"label.message": "Message",
	"label.midmark": "Midmark",
	"label.migration": "Migration",
	"label.modal": "Modal",
	"label.mode": "Crash Mode",
	"label.modified": "Modified",
	"label.molecules": "Molecules",
	"label.month": "Month",
	"label.monthly_cost": "Monthly Cost",
	"label.more": "More",
	"label.more_details": "More details",
	"label.more_info_soon": "More info soon.",
	"label.my_account": "My account",
	"label.my_events": "My Events",
	"label.my_licenses": "My licenses",
	"label.myconfigura": "MyConfigura",
	"label.name": "Name",
	"label.nav": "Nav",
	"label.nav_list": "Nav list",
	"label.need_industry": "Require industry",
	"label.never": "Never",
	"label.new": "New",
	"label.new_briefcase": "New briefcase",
	"label.new_briefcases": "New Briefcases",
	"label.new_comments_in_post": "Comments on posts you have participated in.",
	"label.new_dashboard": "New Dashboard",
	"label.new_expiration_date": "New Expiration Date",
	"label.new_posts": "New posts",
	"label.new_value": "New value",
	"label.need_info": "Need info",
	"label.news": "News",
	"label.news_configura": "Configura News",
	"label.no_active_subscriptions": "You don't have any active subscriptions at the moment",
	"label.no_account_sign_up": "Don't have an account?",
	"label.no_agreements": "No agreements",
	"label.no_agreements_signed_yet": "There isn't any agreements signed yet",
	"label.no_industry_description": "No industry description",
	"label.no_image": "No image",
	"label.no_briefcase": "No briefcase",
	"label.no_color": "No color",
	"label.no_description": "No description",
	"label.no_escrow_subscription": "No Escrow subscription",
	"label.no_discounts": "No discounts",
	"label.no_external_rendering": "No external rendering",
	"label.no_external_rendering_notice":
		"I acknowledge that I have not used external rendering software in my submission.",
	"label.no_fact": "No fact right now.",
	"label.no_hero": "No hero right now.",
	"label.no_jobs": "There are no jobs at the moment.",
	"label.no_matches": "No matches",
	"label.no_products": "No products",
	"label.no_result": "No result",
	"label.no_subscriptions": "There is no subscriptions",
	"label.no_versions": "No versions",
	"label.none": "None",
	"label.none_selected": "None selected",
	"label.north_america": "North America",
	"label.notes_optional":
		"Notes (optional) (If you have a Beta Version or Additional Information about the extension, please indicate here.)",
	"label.notifications": "Notifications",
	"label.november": "November",
	"label.number_increments": "Number Increments",
	"label.number_of_active_subscriptions": "Number of active subscriptions",
	"label.number_of_available_positions": "Number of available positions",
	"label.number_of_licenses": "Number of licenses",
	"label.office": "Office",
	"label.offline": "Offline",
	"label.old_value": "Old value",
	"label.oldest_created_cat": "Oldest Created",
	"label.oldest_pending_files": (a) => `Oldest ${a[0]} files`,
	"label.on_going": "On going",
	"label.only_allocated_licenses": "Only Allocated Licenses",
	"label.only_show_my_favorites": "Only show my Favorites",
	"label.only_valid_licenses": "Only Valid Licenses",
	"label.open_for_comment": "Members can comment.",
	"label.open_for_post": "Members can post.",
	"label.operation": "Operation",
	"label.orange": "Orange",
	"label.orange_light": "Orange light",
	"label.order_by": "Order By",
	"label.order_licenses": "Order licenses",
	"label.order_summary": "Order Summary",
	"label.organisms": "Organisms",
	"label.organization": "Organization",
	"label.other": "Other",
	"label.our_offices": "Our offices",
	"label.our_partners": "Our partners",
	"label.our_platform": "Our platform",
	"label.output": "Output",
	"label.overview": "Overview",
	"label.owner_email": "Email address of owner",
	"label.owned_products": "Owned products",
	"label.owned_licenses": "Owned Licenses",
	"label.pager": "Pager",
	"label.paid": "Paid",
	"label.paid_at": "Paid Date",
	"label.paid_from": "Paid from",
	"label.paid_to": "Paid to",
	"label.parent_edition": "Parent Edition",
	"label.parentid": "Parent ID",
	"label.part_time_percent": "Part-time (50%)",
	"label.participant_registered": "Participants registered",
	"label.participant_to_be_registered": "Participants to be registered",
	"label.partner": "Partner",
	"label.password": "Password",
	"label.password_confirm": "Repeat password",
	"label.path": "Path",
	"label.pay": "Pay",
	"label.paypal": "PayPal",
	"label.pdf": "PDF",
	"label.pending_files_in_queue": (a) => `Pending files in queue (${a[0]})`,
	"label.pending_invites": "Pending Invites",
	"label.pending_sync_queues": "Pending Sync Queues",
	"label.period": "Period",
	"label.periodical": "Periodical",
	"label.permanent_redirect": "Auto redirect future crash with similar crash location.",
	"label.permanent_remove": "Remove permanently",
	"label.permissions": "Permissions",
	"label.phone": "Phone number",
	"label.picture_url": "Picture URL",
	"label.pid": "Product ID",
	"label.pin_node": "Pin this post",
	"label.pkg": "Package",
	"label.placeholders": "Placeholders",
	"label.plan": "Plan",
	"label.play_video": "Play video",
	"label.pool_id": "Pool ID",
	"label.portfolios": "Portfolios",
	"label.positions": "Positions",
	"label.post": "Post",
	"label.post.delete": "Delete",
	"label.post.edit": "Edit",
	"label.post.pin": "Pin",
	"label.postal": "ZIP code",
	"label.posts": "Posts",
	"label.postal_code": "Postal code",
	"label.power_user": "Power user",
	"label.pre_study": "Pre-study",
	"label.prerequisites": "Prerequisites",
	"label.press_url": "Press URL",
	"label.preview": "Preview",
	"label.preview_de": "Preview (de)",
	"label.preview_en": "Preview (en)",
	"label.preview_ja": "Preview (ja)",
	"label.preview_zh": "Preview (zh)",
	"label.price": "Price",
	"label.price_free": "Free",
	"label.price_per_person": (a) => `${a[0]} / Person`,
	"label.prices": "Prices",
	"label.pricelist": "Pricelist",
	"label.primary_access_token": "Primary access token",
	"label.pro": "Pro",
	"label.product": "Product",
	"label.products": "Products",
	"label.progress": "Progress",
	"label.progresses": "Progresses",
	"label.project": "Project",
	"label.project_name": "Project name",
	"label.project_no": "Project No",
	"label.project_size": "Project size",
	"label.projects": "Projects",
	"label.promo": "Promotion code (if any)",
	"label.promotions": "Promotions",
	"label.provider": "Provider",
	"label.public": "Public",
	"label.published": "Published",
	"label.purchase": "Purchase",
	"label.qa": "QA",
	"label.quantity": "Quantity",
	"label.quarter": "Quarter",
	"label.question_about": "I have a question about:",
	"label.react": "React",
	"label.react_one": "React one",
	"label.react_two": "WebGL",
	"label.read_less": "Read less",
	"label.read_more": "Read more",
	"label.ready_for_release": "Ready for release",
	"label.receive_news": "I want to receive news from Configura",
	"label.received": "Received",
	"label.red": "Red",
	"label.redirects": "Redirects",
	"label.ref_id": "Reference Id",
	"label.reference": "Reference",
	"label.referred_by_rep_group": "Were you referred by a rep group?",
	"label.region": "Region",
	"label.region_lowercase": "region",
	"label.register_for": (a) => `Register for ${a[0]}`,
	"label.registration_closes": "Registration closes",
	"label.registration_opens": "Registration opens",
	"label.rejected": "Rejected",
	"label.release": "Release",
	"label.release_date": "Release date",
	"label.release_notes": "Release notes",
	"label.release_statistics": "Release statistics",
	"label.released": "Released",
	"label.owner_reminder_sent": "Reminder Sent to Owner",
	"label.renewed_licenses_valid": (a) => `Renewed licenses valid between ${a[0]} and ${a[1]}`,
	"label.remove_file": "Remove file",
	"label.remove_force_uninstall": "Remove force uninstall",
	"label.remove_invite": "Remove Invite",
	"label.remove_participant_from_all_event": "Remove participant from all related events",
	"label.remove_discount_model": "Remove this discount model",
	"label.remove_support_end": "as Support Contact",
	"label.remove_support_start": "This will completely remove",
	"label.removed": "Removed",
	"label.render": "Render",
	"label.renewal_date": (a) => `Renewal Date: ${a[0]}`,
	"label.rep_group": "Rep group",
	"label.report": "Report",
	"label.reports": "Reports",
	"label.requests": "Requests",
	"label.require_drawing": "Require drawing",
	"label.require_myconfigura_account": "Require MyConfigura account",
	"label.require_supervisor_approval": "Require supervisor approval",
	"label.reset_password": "Reset password",
	"label.resources": "Resources",
	"label.result": "Result:",
	"label.result_found": (a) => `${a[0]} found for "${a[1]}"`,
	"label.role": "Role",
	"label.roles": "Roles",
	"label.routing": "Routing",
	"label.royalty": "Royalty",
	"label.runtimeversion": "Runtime Version",
	"label.sales_pipeline": "Sales pipeline",
	"label.save": "SAVE",
	"label.schedule": "Schedule",
	"label.schedule_posting": "Schedule posting",
	"label.scheduled": "Scheduled",
	"label.scheduled_for": "Scheduled for",
	"label.scheduled_training": "Scheduled training",
	"label.scope": "Scope",
	"label.scopes": "Scopes",
	"label.search": "Search",
	"label.second": "Second",
	"label.search_customer": "Search Customer",
	"label.seat_left": "Seat(s) left",
	"label.seat_price": "Price / Person",
	"label.seats_taken": "Seats Taken",
	"label.see_agreement": "See agreement",
	"label.see_current_job_openings": "See current job openings",
	"label.see_whats_new": "See what's new.",
	"label.sek": "SEK",
	"label.self_paced": "Self-Paced",
	"label.self_paced_training": "Self-Paced training",
	"label.self_support": "Self Support",
	"label.select_all": "Select All",
	"label.select_discount_tier": "Select discount tier",
	"label.select_to_renew": "Select to renew",
	"label.send": "Send",
	"label.send_confirmation_email_to_participant": "Send confirmation email to participant",
	"label.send_email": "Send email",
	"label.sentry_organization": "Sentry Organization",
	"label.sentry_team": "Sentry Team",
	"label.separate_emails": "Multiple emails should be separated by semi-colon.",
	"label.services": "Services",
	"label.set_as_fixed_year": "Set as fixed year price",
	"label.set_as_industry_library": "Set as Industry Library",
	"label.set_as_target_extension": "Allow Content Pack Publishing",
	"label.settings": "Settings",
	"label.share": "Share",
	"label.share_info": "Share info",
	"label.share_information": "I agree to share my email address",
	"label.share_sentry_projects": "Share Sentry Projects",
	"label.shenzhen": "Shenzhen",
	"label.show_all": "Show all",
	"label.show_all_briefcases": "Show all briefcases",
	"label.show_all_collab_projects": "Show all >",
	"label.show_all_forums": "Show all forums",
	"label.show_all_manufacturers": "Show all manufacturers",
	"label.show_all_portfolios": "Show all portfolios",
	"label.show_comment": "Show 1 comment",
	"label.show_comments": (a) => `Show ${a[0]} comments`,
	"label.show_deprecated_extensions": "Show deprecated extensions",
	"label.show_files": "Show files",
	"label.show_only_manufacturer_portfolios": "Show only {{manufacturerName}} portfolios",
	"label.show_release_notes": "Show release notes",
	"label.show_value": "Show Value",
	"label.delete_value": "Delete Value",
	"label.decide_later": "Decide later",
	"label.not_set": "Not set",
	"label.not_signed": "Not signed",
	"label.not_reviewed": "Not reviewed",
	"label.show_less_comments": "Show less comments",
	"label.show_members": "Show members",
	"label.show_more": "Show more",
	"label.show_more_comments": "Show more comments ...",
	"label.show_on_archives": "Show on archives page",
	"label.show_on_events_page": "Show on events page",
	"label.show_on_marketplace": "Show on Marketplace",
	"label.show_on_sharepoint": "Show on sharepoint",
	"label.show_previous_winners": "Show previous winners",
	"label.show_special_sessions": "Show special sessions",
	"label.sign_agreement": "Sign Agreement",
	"label.sign_date": "Sign date",
	"label.sign_in_session_timeout": "Your sign-in session has timed out",
	"label.sign_up": "Sign up",
	"label.sign_up_for_export":
		"I want to sign up for the additional service, Export, and is aware that my manufacturer will be billed for the service.",
	"label.sign_up_for_render":
		"I want to sign up for the additional service, Render, and is aware that my manufacturer will be billed for the service.",
	"label.signed": "Signed",
	"label.signed_by": "Signed by",
	"label.signed_on": "Signed on",
	"label.signed_up": "Signed Up",
	"label.silver": "Silver",
	"label.site_admin": "Site admin",
	"label.size": "Size",
	"label.slug": "Slug",
	"label.solutions": "Solutions",
	"label.spacesaver": "Spacesaver",
	"label.speaker": "Speaker",
	"label.speaker_image_large": "Speaker image (large)",
	"label.speaker_image_small": "Speaker image (small)",
	"label.speakers": "Speakers",
	"label.special": "Special",
	"label.spec_workspace_notify": "Spec, Workspace, Notify, CIL for CAD",
	"label.sponsor": "Sponsor",
	"label.sponsored": "Sponsored",
	"label.sponsor_logo": "Sponsor logo",
	"label.sponsors": "Sponsors",
	"label.sponsorship_request_details": "Sponsorship Request Details",
	"label.sso_url": "SSO Url",
	"label.stage": "Stage",
	"label.stage_access_for": "Stage access for",
	"label.stage_browser": "Stage browser",
	"label.cmsym_viewer": "CmSym viewer",
	"label.stage_pro_sign_up": "Stage Pro sign up",
	"label.stage_sign_up": "Stage sign up",
	"label.start": "Start",
	"label.start_date": "Start date",
	"label.start_time": "Start time",
	"label.state": "State",
	"label.static": "Static",
	"label.statistics": "Statistics",
	"label.status": "Status",
	"label.steelcase": "Steelcase",
	"label.sticky": "Sticky",
	"label.string": "String",
	"label.subject": "Subject",
	"label.sub_extensions": "Sub Extensions",
	"label.subscribe_all": "All activity (all posts and comments).",
	"label.subscribe_comments_participated": "Comments on post you participated.",
	"label.subscribe_new_post": "New posts.",
	"label.subscription": "Subscription",
	"label.subscription_licenses": "Subscription licenses",
	"label.subscriptions": "Subscriptions",
	"label.summary": "Summary",
	"label.superior": "Superior",
	"label.supervisor": "Supervisor",
	"label.support": "Support",
	"label.support_agreement": "Support Agreement",
	"label.support_tool": "Support tool",
	"label.support_type": "Support type",
	"label.support_url": "Support URL",
	"label.supportive_colleagues": "Supportive colleagues",
	"label.survey": "Survey",
	"label.suspended": "Suspended",
	"label.suspend_at": "Suspend Date",
	"label.system_recommendations": "System recommendations",
	"label.tables": "Tables",
	"label.tabs": "Tabs",
	"label.tag": "Tag",
	"label.tags": "Tags",
	"label.task_handler": "Task handler",
	"label.terminate": "Terminate",
	"label.termination_date": "Termination date",
	"label.testing_and_qa": "Testing and QA",
	"label.text": "Text",
	"label.text_field": "Text Field",
	"label.thank_you_for_your_message": "Thank you for your message",
	"label.thesis_work_and_internships": "Thesis work and internships",
	"label.this_price_and_discounts": "this price and connected discounts",
	"label.third": "Third",
	"label.threads": "Threads",
	"label.time": "Time",
	"label.time_and_date": "Time and date",
	"label.time_frame": "Time frame",
	"label.time_with_cet": "Time using CET",
	"label.timeoccured": "Time Occured",
	"label.timezone": "Timezone",
	"label.title": "Title",
	"label.title_de": "Title (de)",
	"label.title_en": "Title (en)",
	"label.title_ja": "Title (ja)",
	"label.title_zh": "Title (zh)",
	"label.to_be_invoiced": "To be invoiced",
	"label.to_china": "To China",
	"label.toggle_filter_on_off": "Toggle Filter on/off",
	"label.topic": "Topic",
	"label.tools": "Tools",
	"label.total": "Total",
	"label.total_paid_licenses": "Total Paid Licenses",
	"label.total_size": "Total size",
	"label.total_reports": "Total Reports",
	"label.total_reports_submitted_for_period": (a) =>
		`A total of ${a[0]} reports were submitted for ${a[1]}.`,
	"label.training": "Training",
	"label.training_activity_logs": "Activity Logs",
	"label.timeframe": "Timeframe",
	"label.training_and_support": "Training and Support",
	"label.training_dates": "Training Dates",
	"label.training_logs_for_training_dates": "Logs for Training Dates",
	"label.training_participants": "Training Participants",
	"label.translations": "Translations",
	"label.travel": "Travel opportunities",
	"label.trial": "Trial",
	"label.turquoise": "Turquoise",
	"label.turquoise_light": "Turquoise light",
	"label.tutorials": "Tutorials",
	"label.type": "Type",
	"label.type_of_initiative": "Type of initiative",
	"label.typography": "Typography",
	"label.uid": "User ID",
	"label.uid_doer": "Doer",
	"label.ultimate": "Ultimate",
	"label.unallocated": "Unallocated",
	"label.unauthorized_representative":
		"I am not the authorized representative/owner for the manufacturer.",
	"label.unauthorized_on_behalf":
		"I am not authorized. I am adding the manufacturer on behalf of an authorized representative/owner.",
	"label.unblock_catalogues": "Unblock catalogues",
	"label.unblock_update": "Unblock update",
	"label.unconfirmed": "Unconfirmed",
	"label.unlinked_pm_data": "Unlinked PM Data",
	"label.unrenewable_extensions": "Unrenewable Extensions",
	"label.unsticky": "Unsticky",
	"label.updated": "Updated",
	"label.updated_briefcase": (a) => `Updated ${a[0]} by ${a[1]} ${a[2]}`,
	"label.upload": "Upload",
	"label.upload_file": "Upload file",
	"label.upload_new_logo": "Upload new logotype",
	"label.upload_job_queue": "Upload Job Queues",
	"label.uploads": "Uploads",
	"label.url": "URL",
	"label.url_database": "URL Database",
	"label.us_retirement": "401K retirement savings plan",
	"label.usd": "USD",
	"label.use": "Use",
	"label.use_cpi": "CPI (consumer price index) adjusted",
	"label.use_event_title": "Use event title",
	"label.user": "User",
	"label.user_card_popups": "User card popups",
	"label.user_conference": "User Conference",
	"label.user_conference_day_one": "OCT 16",
	"label.user_conference_day_three": "OCT 18",
	"label.user_conference_day_two": "OCT 17",
	"label.user_data": "User Data",
	"label.user_density": "User density",
	"label.user_message": "Describe your problem to support",
	"label.user_login": "User login",
	"label.users": "Users",
	"label.valid_from": "Start date",
	"label.valid_to": "Expire Date",
	"label.validated": "Validated",
	"label.validity": "Validity",
	"label.value": "Value",
	"label.version": "Version",
	"label.versionid": "Version ID",
	"label.versions": "Versions",
	"label.video": "Video",
	"label.video_link": "Video link",
	"label.video_tutorials": "Video tutorials",
	"label.view_document": "View document",
	"label.view_members": "View Members",
	"label.view_invoices": "View invoices",
	"label.create_invoice": "Create invoice",
	"label.view_presentation": "View presentation",
	"label.view_session_in_schedule": "View session in schedule",
	"label.visible": "Visible",
	"label.voting_closes": "Voting closes",
	"label.voting_opens": "Voting opens",
	"label.vr_image": "VR-image",
	"label.watch": "Watch",
	"label.webinars": "Webinars",
	"label.webteam": "Webteam",
	"label.welcome_event": "Welcome event",
	"label.what_happened": "What happened?",
	"label.what_sponsors_receive": "What sponsor receives?",
	"label.will_end": "When will it end?",
	"label.will_start": "When will it start?",
	"label.work_life_balance": "Work life balance",
	"label.work_time": "Work time",
	"label.write_a_comment": "Add a comment",
	"label.write_your_post": "Write your post",
	"label.year": "Year",
	"label.yearly_fee": "Yearly fee",
	"label.yearly_cost": "Yearly Cost",
	"label.yellow": "Yellow",
	"label.yellow_light": "Yellow light",
	"label.you_can_search_for_name_email_account_manufacturer":
		"Search for name, email, account number or manufacturer",
	"label.your_boss_email": "Your Boss' Email",
	"label.your_boss_name": "Your Boss' Name",
	"label.your_email": "Your Email",
	"label.your_name": "Your Name",
	"label.youre_registered": "You're registered",
	"label.zh": "Chinese",
	"label.zip_code": "Zip Code",

	// Links
	"link.create_an_access_token_here": "Create an Access Token here.",
	"link.download_original_file": "Download original file",
	"link.find_a_portfolio": "Find a portfolio to make available.",
	"link.join_now": "Join now",
	"link.join_zoom_meeting": "Join Zoom Meeting",
	"link.view_document": "View document",
	"link.votes": "Votes",
	"link.watch_now": "Watch now",

	// Error messages
	"error.access_denied": "Access denied",
	"error.account_closed": (a) => `The account '${a[0]}' has been closed.`,
	"error.agreement_already_signed": "Agreement is already signed",
	"error.agreement_already_terminated": "Agreement is already terminated",
	"error.agreement_not_accepted": "Agreement has not been accepted.",
	"error.all_fields_required": "Please fill in all fields.",
	"error.already_registred": "Already registred.",
	"error.already_voted": "This email has already a confirmed vote in this category.",
	"error.award_name_required": "Award name is required",
	"error.bad_request": "Bad Request.",
	"error.bad_login": "Bad Login",
	"error.block_catalogues": "Could not block catalogues for this manufacturer",
	"error.briefcase_doesnt_exists": "Briefcase doesn't exists.",
	"error.briefcase_logo_not_found": "Briefcase logotype not found.",
	"error.briefcase_name_exists": "Briefcase name already exists.",
	"error.briefcase_not_found": "Briefcase not found.",
	"error.cache_data_not_found": "Failed to get cache data.",
	"error.campaign_doesnt_exist": "Campaign does not exist",
	"error.campaign_incorrect_date": "Incorrect start or end date",
	"error.cannot_give_more_than_100_licenses": "You cannot give more than 100 licenses",
	"error.cannot_recover_password_user_is_validating":
		"Cannot reset the password since the email is not validated. Check your inbox for validation email.",
	"error.cannot_recover_password_user_not_yet_registered":
		"Cannot reset the password since the email is not fully registred. Check your inbox for validation email.",
	"error.cannot_remove_self": "You cannot remove yourself.",
	"error.catadmin_internal_api_host_missing": "CATADMIN_INTERNAL_API_HOST is not set",
	"error.catalogue_already_available_in_api": "Catalogue already available in API",
	"error.catalogue_with_same_details_not_allowed":
		"A Catalogue with the same Enterprise, PrdCat and PrdCatVersion already exists.",
	"error.catalogue_doesnt_exist_in_api": "Catalogue doesn't exist in the API",
	"error.class_ended": "Class ended",
	"error.class_invalid": "This registration URL is invalid, please contact webrrt@configura.com",
	"error.class_not_found": "Class not found",
	"error.comment_is_required": "Please fill in the comment.",
	"error.could_not_delete_file": "Could not delete file.",
	"error.could_not_fetch_post": "Could not fetch post(s).",
	"error.could_not_fetch_versions": "Could not fetch build versions",
	"error.could_not_find_user": "Could not find user.",
	"error.could_not_load_admins": "Could not load admins.",
	"error.could_not_load_portfolios": "Could not load Portfolios.",
	"error.could_not_read_file": "Could not read file.",
	"error.could_not_remove_member": "Could not remove member.",
	"error.could_not_update_customer": "Could not update customer.",
	"error.could_not_update_product": "Could not update product.",
	"error.country_doesnt_exists": "Country doesn't exists.",
	"error.crash_has_been_submitted": "Crash has been submitted previously",
	"error.currency_no_decimal": "Decimal not allowed for this currency",
	"error.crash_not_found":
		"We could not find the crash in our system. Please contact webrrt@configura.com",
	"error.crash_expired": "This link has expired and the crash can no longer be submitted",
	"error.create_ticket": "Failed to create ticket.",
	"error.customer_already_admin": "This user is already an admin of this customer group.",
	"error.customer_already_in_discount_model": "Customer is already in a discount model",
	"error.database_error": "Database error",
	"error.distribution_does_not_exist": "Distribution does not exist",
	"error.delete_catalogue_briefcase":
		"This briefcase has a catalogue tied to it and can therefore not be deleted'.",
	"error.drawing_file_not_found":
		"Drawing file (.cmdrw) not found. Please refresh your browser if the problem keeps happening.",
	"error.duplicate_email": "This email is a duplicate. Make sure all emails are unique.",
	"error.edition_license_not_included": "Edition license was not included",
	"error.email_already_registered": "This email is already registered.",
	"error.email_mismatch":
		"The email you specified is not the same that you signed with. Please try again",
	"error.empty_field": "Insert either phone number, email or url.",
	"error.empty_name": "Name is required",
	"error.field_is_required": "Please fill in this field.",
	"error.file_not_found": "File not found.",
	"error.forbidden_domain": (a) =>
		`The email domain is not permitted on this site. ${a[0] || ""}`,
	"error.form_contains_errors":
		"The form contains errors. Please correct them before being able to proceed.",
	"error.fully_booked": "Fully booked",
	"error.fully_booked_choose_other": "Event is already fully booked please choose another one.",
	"error.has_not_signed_cetrpl": "CETRPL signing is required for this action.",
	"error.id_doesnt_exist": "Cannot edit this because the given id doesn't match anything.",
	"error.incomplete_prerequisites": "Participant must complete the prerequisite classes",
	"error.incorrect_support_type": "Support type does not exist",
	"error.invalid_campaign": "Invalid campaign",
	"error.invalid_campaign_preset": "Invalid campaign preset",
	"error.invalid_data": "Invalid data",
	"error.invalid_date": "Invalid date",
	"error.invalid_description": "Invalid description",
	"error.invalid_email": (a) => `Invalid email address. ${a[0] || ""}`,
	"error.invalid_email_or_domain": (a) => `Invalid email or domain address. ${a[0] || ""}`,
	"error.invalid_expiration_date": "Invalid expiration date.",
	"error.invalid_extension": "Invalid extension.",
	"error.invalid_extension_id": "Invalid extension id. Extension id must be a number",
	"error.invalid_float": "Not a valid float.",
	"error.invalid_form": "Please correct the form.",
	"error.invalid_group_id": "Invalid group id.",
	"error.invalid_id": "Invalid id.",
	"error.invalid_integer": "Not a valid integer.",
	"error.invalid_license": "Invalid license.",
	"error.invalid_login": "Invalid login",
	"error.invalid_manufacturer": "Invalid manufacturer.",
	"error.invalid_manufacturer_status":
		"Manufacturer is either terminated or haven't finalised creation process",
	"error.invalid_month": "Invalid month.",
	"error.invalid_number": (a) => `${a[0]} is not a valid number.`,
	"error.invalid_parameter": "Invalid parameter",
	"error.invalid_product": "Invalid product",
	"error.invalid_product_ids": "Invalid product ids. Product ids must be an array of numbers",
	"error.invalid_promocode": "Invalid promo code.",
	"error.invalid_release_version_format": "Invalid CET release version format",
	"error.invalid_role": "Invalid role",
	"error.invalid_search_type": "Search type must be either user, invoice, customer or license.",
	"error.invalid_search_id": "Search id must be a number.",
	"error.invalid_sentry_project": "Invalid sentry project.",
	"error.invalid_slug": "Invalid slug. It may only contain characters, numbers and dashes.",
	"error.invalid_support_type": "Invalid support type.",
	"error.invalid_training": "Invalid training",
	"error.invalid_type": "Invalid type",
	"error.invalid_url": "Invalid URL.",
	"error.invalid_url_help": "Invalid URL. Please include http:// OR https:// .",
	"error.invalid_url_parameter": "Invalid URL parameter.",
	"error.invalid_year": "Invalid year.",
	"error.invite_does_not_exist": "The invite does not exist.",
	"error.instructor_cannot_be_participant": "Instructor cannot be a participant",
	"error.no_customer_set":
		'Price does not have any customer and "Apply to all customers" has not been set',
	"error.no_distribution": "There is currently no distribution for this release",
	"error.no_selected_support_option": "Please select a support option or decide later.",
	"error.no_user_associated_with_role": "There isn't any user associated with this role",
	"error.no_user_sso":
		"The email you specified when logging in is not an existing account. Would you like to sign up using this email?",
	"error.prerequisite_time_limit_not_met":
		"This participant can only register after two weeks of completing the prerequisite course(s).",
	"error.pp_form_user_not_found":
		"Unable to proceed with publishing program enrollment: Cannot identify currently logged in user.",
	"error.last_owner": "It's not possible to remove the last owner.",
	"error.leave_last_admin": "Cant leave if you are the last mananager in briefcase.",
	"error.manufacturer_already_has_stage_pro": "Your manufacturer already have Stage Pro.",
	"error.manufacturer_does_not_exist": "Manufacturer does not exist.",
	"error.manufacturer_extension_catalogue_exist":
		"The manufacturer has existing catalogue or extension",
	"error.manufacturer_is_not_finalised":
		"Unauthorized. Please sign CETPRL first to access this functioncality",
	"error.manufacturer_name_exists":
		"There is already a manufacturer registered with this name. Please choose another name.",
	"error.manufacturer_or_service_undefined": "The manufacturer or the service is undefined",
	"error.max_attendees_exceeded": "Maximum number of attendees exceeded",
	"error.minimum_admin": "There has to be at least one manager.",
	"error.missing_briedcase_id": "Missing briefcase id.",
	"error.missing_briedcase_type": "Missing briefcase type.",
	"error.missing_edition_requirements":
		"Edition of type 'Manufacturer' requires manufacturer to be selected",
	"error.missing_email": "Please fill in your email address.",
	"error.missing_inquiry": "Please fill in your inquiry.",
	"error.missing_invites": "Please fill in email addresses.",
	"error.missing_key_extension": "Extension and API Key fields must have value",
	"error.missing_logo": "Missing logotype id",
	"error.missing_name": "Please fill in your name.",
	"error.missing_phone": "Please fill in your phone number.",
	"error.missing_prerequisites": "Please complete the prerequisite courses.",
	"error.missing_release_version": "Missing CET release version",
	"error.missing_ticket":
		"This page was not opened correctly. Please follow the link in your email again.",
	"error.merge_failed": "Merge was unsuccessful. Please contact the web team.",
	"error.move_crash_link_expired": "Move crash link has expired.",
	"error.must_agree_cetrpl_agreement": "You must acknowledge and agree to the above terms.",
	"error.name_exists": "Name already exists. Please choose another name.",
	"error.no_admin_access": "No admin access.",
	"error.no_contacts": "No contacts provided",
	"error.no_contacts_found": "No contacts found",
	"error.no_email_sso": "Could not get credentials from IDP provider",
	"error.no_extension_found": "No extension found",
	"error.no_extension_found_in_version":
		"No extension found in the version. At least 1 extension is required.",
	"error.no_file_selected": "No file selected.",
	"error.no_license_group": "No license group.",
	"error.no_license_selected": "No license selected.",
	"error.no_login_data": "Login credentials are expired or no longer valid.",
	"error.no_manufacturer_found": "No manufacturer found.",
	"error.no_media_found": "No media found.",
	"error.no_name":
		"Your user doesn't have a name set. Please go to your account settings and update before proceeding.",
	"error.no_participant": "At least one participant needs to be registered.",
	"error.no_prices": "At least one price needed",
	"error.no_stage_for_manufacturer":
		"Your manufacturer haven't signed up for Stage, you must sign up for that first.",
	"error.no_support_agreement_signed": "Missing signed support agreement",
	"error.no_support_contacts": "Missing support contact.",
	"error.no_ticket": "Ticket failed to be created.",
	"error.no_ticket_sso": "Login credentials have expired. Please try again",
	"error.no_valid_alternatives": "All alternatives are closed for registration.",
	"error.not_available_in_stage": "This portfolio is not longer available in Stage.",
	"error.not_business_customer_type": "Current customer is not a business type",
	"error.not_found": "Not found.",
	"error.not_logged_in": "Not logged in.",
	"error.not_myconfigura_user": "User does not exist in MyConfigura",
	"error.not_number": "Not a number",
	"error.not_registered_for_campaign": "User did not register for any campaign.",
	"error.not_registered_uc": "You are not registered for the CET Experinece event.",
	"error.not_sso": "SSO is not enabled for this email. ",
	"error.number_less_than_min": (a) => `Please select a value that is no less than ${a[0]}`,
	"error.number_more_than_max": (a) => `Please select a value that is no more than ${a[0]}`,
	"error.one_edition_required": "At least one edition is required.",
	"error.one_field_required": "At least one field is required.",
	"error.ongoing_class": "This is an ongoing class. Please register from instructor.",
	"error.only_configura_employee_can_terminate":
		"Only Configura Employee can terminate an agreement",
	"error.password_invalid":
		"Your password must be at least 8 characters long, include 1 uppercase, and 1 special character.",
	"error.passwords_do_not_match": "The password doesn't match, perhaps a typo?",
	"error.permission_denied": "You are not authorized to do this.",
	"error.portfolio_doesnt_have_any_catalogues": "Portfolio doesn't have any Catalogues.",
	"error.post_not_found": "The post you are looking for could not be found.",
	"error.post_length_too_long": "Post is too long. Please amend and resubmit again.",
	"error.price_model_delete": "Discount model could not be removed.",
	"error.project_name_exists": "A project with that name already exists.",
	"error.project_package_name_invalid": 'Package name is invalid: "{{pkg}}"',
	"error.project_package_exists": "A project with that package already exists.",
	"error.project_package_name_prefix": 'Package name must start with "{{pkgPrefix}}" prefix',
	"error.project_slug_exists": "A project with that slug already exists.",
	"error.quantity_at_least_one": "Please add at least one element",
	"error.quantity_exists": "A discount with this quantity already exists.",
	"error.redirect_exists": "Redirect for the fingerprint already exists.",
	"error.registration_closed": "Registration closed",
	"error.registration_date_invalid": "Invalid registration date",
	"error.release_candidate_does_not_exist": "There is no release candidate for this CET release",
	"error.release_date_not_within_range":
		"The release that you specified is not within the allowed range.",
	"error.remove_last_admin": "Cant remove last manager in briefcase.",
	"error.resource_not_found": "Resource not found.",
	"error.requires_owner_permissions": "You have to be an Owner to do this",
	"error.date_range_undefined":
		"Could not calculate acceptable release date range for release candidate",
	"error.select_one_edition": "Please select at least one edition",
	"error.sentry": "Sentry service is not available.",
	"error.slug_exists": "Slug already exists. This needs to be unique.",
	"error.slug_invalid":
		"Not possible to create a project with the following slug: -, badges, blame, blob, builds, commits, create, create_dir, edit, environments, folders, files, find_file, gitlab-lfs, objects, info, lfs, objects, new, preview, raw, refs, tree, update, wikis",
	"error.some_manufacturers_have_signed":
		"Some manufacturers specified in the bulk action has signed against this CET release",
	"error.sso_enabled": "SSO is enabled for this email. Please proceed with SSO login",
	"error.start_date_cannot_be_later_than_expiry_date":
		"Start date cannot be later than expiry date",
	"error.state_doesnt_exists": "State doesn't exists.",
	"error.submission_file_not_found": "Award file submission not found.",
	"error.support_agreement_already_signed": "Support agreement is already signed",
	"error.support_contact_required": "At least 1 support contact is required",
	"error.support_option_not_selected": "Support option is not selected",
	"error.test_confirmation_is_required": "Testing confirmation is required",
	"error.titile_already_exists": "Title already exists",
	"error.too_long": "Too long. Please amend.",
	"error.too_long_inquiry": "Inquiry too long. Please amend.",
	"error.training_deadline_passed": "Training registration has closed",
	"error.trial_period_expired": "The trial period has expired and cannot be extended",
	"error.twinmotion_bundle_not_allowed": "Twinmotion Bundle is not allowed to be selected",
	"error.unblock_catalogues": "Could not unblock catalogues for this manufacturer",
	"error.unfortunately_fully_booked": "Unfortunately this is fully booked.",
	"error.unknown_error": "Unknown error.",
	"error.unknown_file": "Unknown file.",
	"error.update": "Failed to update.",
	"error.user_has_pending_invite": (a) => `${a[0]} has a pending invite.`,
	"error.user_in_briefcase": (a) => `${a[0]} is a member of the briefcase`,
	"error.user_is_customer_admin": "User is an admininstrator for another customer",
	"error.user_is_briefcase_admin": (a) => `${a[0]} is an admin of the briefcase`,
	"error.user_is_not_admin": "The user is not an Administrator.",
	"error.user_is_not_license_admin": "The user is not a License Administrator.",
	"error.user_need_invite": "User need an invitation first",
	"error.user_not_a_top_level_admin": "User is not an admin of a top level pool ",
	"error.user_not_found": "User not found.",
	"error.user_not_found_try_again": "There is no user with the requested email address.",
	"error.user_not_in_briefcase": "User is not a member of the briefcase",
	"error.user_registered_class": "User has already registered for the class",
	"error.user_whitelisted": "User is already whitelisted",
	"error.vote_doesnt_exists": "This link doesn't match any vote.",
	"error.voting_date_invalid": "Invalid voting date",
	"error.voting_isnt_open": "Voting isn't open.",
	"error.while_loading_data":
		"We encountered a problem while loading this page, please reload the page. If the problem persists, contact support.",
	"error.while_loading_menu":
		"We encountered a problem while loading the menu, please reload the page. If the problem persists, contact ",
	"error.you_already_registred": "You are already registred.",
	"error.you_are_not_an_owner": "You are not an Owner.",
	"error.you_cannot_be_removed_from_this_event": "You can't be removed from this event.",
	"error.you_cannot_sign_logged_in_as_other":
		"You are logged in as another user and it's not possible to sign legal documents as another person.",
	"error.you_cannot_terminate_logged_in_as_other":
		"You are logged in as another user and it's not possible to terminate legal documents as another person.",
	"error.your_manufacturer_already_signed": "Your manufacturer already signed this.",

	// Help texts
	"help.active":
		"Only one award can be active. When active it will be the one used on the public awards page.",
	"help.accessories":
		"From plants to computers, you’ll find all the bells and whistles in this category. For example, Plants, Flowers and Kitchen Accessories.",
	"help.allow_debug":
		"By checking this you allow Configuras developers to have access to this specific portfolio for debugging and testing purposes. Configura perform tests on available portfolios on a regular basis to strive for highest quality. Access by Configuras developers will not have an impact on number of requests made.",
	"help.allow_add_build_to_official":
		"Enabling this will allow builds to be added to the official distribution regardless if it has gone through extension review or its product has been released.",
	"help.award_extra_data": "If checked it will add the extra data form to the user.",
	"help.award_need_industry":
		"If checked it will add the industry field to award submission form",
	"help.authorised_representative_owner_email":
		"The authorized representative/owner will receive an email notification regarding the manufacturer's creation, and approval will be required to proceed with the process.",
	"help.briefcase_logo":
		"The image will be scaled and cropped to image dimension of 115px width and 55px height. For best quality display, make sure the the image fit within the defined image dimension and use non white background colour.",
	"help.building":
		"Anything related to buildings and architectural features. For example, Structural and Stairs.",
	"help.catalogues": "Catalogues related extensions.",
	"help.campaign_merge_email_input": "Enter the email address of the license admin to merge into",
	"help.cet_developer_subextension":
		"Users allocated with CET Developer licenses will also receive this edition.",
	"help.commercial_interiors": "Extensions with products for the Commerical Interiors.",
	"help.contact_dev_support_pkg": "Contact developer support to request for Package change.",
	"help.cronjob.to_run": "The cron job is not activated. Do you want to run it anyway?",
	"help.cronjob.to_stop": "Do you want to set this cron job as not running?",
	"help.cronjob.to_toggle": "Do you want to activate/deactivate this cron job?",
	"help.customer_admin_not_found":
		"Customer admin is not found, please try another name or email",
	"help.email_addresses": "Separate multiple email addresses with ;",
	"help.edition_image": "Edition image",
	"help.email_content_confirmation": "This content will be added to the email confirmation.",
	"help.email_to_participant":
		"An email will be sent to the participant with information about the event.",
	"help.end_date": (a) => `Current end date: ${a[0]}`,
	"help.essentials": " ",
	"help.experimental": " ",
	"help.event_url": "URL to the event.",
	"help.extension_supervisor_approval":
		"The visibility of the extension to users needs to be approved by a supervisor.",
	"help.extension_slug":
		'Slug is a URL-friendly and human readable name. Lowercase letters and "-" only with maximum length of 25 characters. First character is not "-".',
	"help.free": "If the Extension is released, anyone can install it, no purchase needed.",
	"help.fully_boked": "Fully booked",
	"help.fun_and_games": "Fun!",
	"help.make_available_in_stage":
		"Stage is what makes it possible to show your products on the web, or fetch data about your products through API calls. (The products need to be in the newer CmSym-format.) Once you have made this catalogue available it will take a few minutes before all data is available.",
	"help.manufacturer_slug":
		'Slug is a URL-friendly and human readable name. Lowercase letters and "-" only with maximum length of 25 characters. First character is not "-".',
	"help.no_end_date": "End date is not selected yet",
	"help.marketplace_categories":
		"Please select categories where your extension belongs. If it is specifically made for the industry you selected, choose Industry-specific",
	"help.marketplace_logotype":
		"The Extension needs a logotype for the Marketplace website. The logotype must be 100 x 100 pixels. Preferably you use the Company's logotype. White or transparent background.",
	"help.marketplace_information":
		"This information goes on the Marketplace page. Try to keep it between 150-300 words.",
	"help.material_handling": "Extensions with products for the Material Handling industry.",
	"help.no_longer_than_128": "Title should not be longer than 128 characters",
	"help.no_prices_for_product": "There is currently no prices set for this product",
	"help.no_start_date": "Start date is not selected yet",
	"help.not_extension_owner": (a) =>
		`You can hire Configura to aid you with support errands for your specific Extension. This service includes a Basic Extension Support or a Superior Extension Support, which includes an annual retainer fee plus a per-ticket charge. You have to be an Extension Owner to sign the <a class="link link--hover" href=${a[0]} target="_blank" rel="noopener noreferrer">Support agreement</a>.`,
	"help.add_support_contact":
		"This means you handle your own Support within your company. Please add the contact information for your Support contacts.",
	"help.decide_later": "You can decide later if you don’t know which one to choose right now.",
	"help.default_price_set":
		"This is default price that is applicable for all customers. You can't add customers for this model",
	"help.developer": "Tools for Development. For example, Catalogue Creator.",
	"help.hidden_alternative": "Alternative will be hidden in registration form.",
	"help.if_you_leave_this_blank_published_directly":
		"If you leave this blank it will be published directly.",
	"help.industry-specific":
		"Choose this if your extension is specifically made for the industry you selected",
	"help.industry_library": " ",
	"help.info_for_user":
		"Will be sent in the license allocation email. Please write contact infomation for user support such as email adress or phone number. You can include manufacturer specific information as well.",
	"help.internal_voting":
		"If checked it will NOT be open for public voting. Instead we have an inhouse jury deciding the winners.",
	"help.kitchen_and_bath": "Extensions with products for the Kitchen and Bath industry.",
	"help.presentation":
		"Extensions that enable or aid the user in creating presentations. For example, 3D PDF and Virtual Viewer.",
	"help.require_drawing": "Drawing files (.cmdrw) submission will be mandatory.",
	"help.require_supervisor_approval":
		"If this is checked, a User Admin will have to approve new users that would like to use the extension.",
	"help.require_supervisor_approval_access":
		"You need access to approval for this extension to change this setting.",
	"help.require_supervisor_approval_click": "Click edit to change this setting.",
	"help.latest_updates_inbox":
		"Get the latest updates and current events delivered straight to your inbox.",
	"help.pm_data_logotype":
		"The PM Data needs a logotype for the Marketplace website. The logotype must be square, at least 100 x 100 pixels and smaller than 1000 x 1000 pixels. Preferably you use the Company's logotype. White or transparent background.",
	"help.pm_data_show_on_marketplace":
		"Logo will only appear in the Marketplace if a logo is present for this data.",
	"help.license_icon":
		"The Extension needs a license icon, which is shown in the License Administration. The icon will be scaled to 16x16 pixels.",
	"help.license_used_allocated": "Total licenses currently allocated across all types.",
	"help.license_active": "Total licenses currently active across all types.",
	"help.license_trial": "Total trial licenses currently allocated.",
	"help.license_purchased_payable": "Total payable licenses currently allocated.",
	"help.license_free": "Total free licenses currently allocated.",
	"help.license_sponsored": "Total sponsored licenses currently allocated.",
	"help.license_education": "Total education licenses currently allocated.",
	"help.license_internal": "Total internal licenses currently allocated.",
	"help.related_events": "Related events means the events in the same event group.",
	"help.release_date_help_text": (a) => `You must release between ${a[0]}`,
	"help.add_email_customer_admin": "Add the email of the customer you want to set the price for",
	"help.select_customer_plan_to_filter":
		"Select customer name or plan to filter list. You can combine filters. To see all please restore values to default",
	"help.session_special":
		"If checked this will be displayed differently and be persistant when filtering.",
	"help.set_as_target_extension":
		"Enabling this allows content pack targeting this extension to be created",
	"help.set_as_industry_library":
		"Enabling this will show link to the list of portfolios on the  extension's product page in Marketplace",
	"help.start_date": (a) => `Current start date: ${a[0]}`,
	"help.session_visibility":
		"If checked this session will be shown on the CET Experience Archives page.",
	"help.show_on_marketplace":
		"Checking this will allow the edition on the Marketplace, however, the edition must be set to released or visible.",
	"help.show_previous_winners":
		"When checked this will display previous winner on the public awards page.",
	"help.show_release_notes": "Show release notes on marketplace page",
	"help.slug_information": "A slug is an unique identifier that can't be changed after creation.",
	"help.suggestion_slug": (a) => `Suggestion: ${a[0]}`,
	"help.sync_marketplace_extension_name":
		"The extension name change will also be reflected in the Marketplace.",
	"help.table_scrollable":
		"This table is scrollable. You can scroll horizontally to see more columns.",
	"help.tools":
		"Extensions with functionality that aids the user in their workflow. For example, Reconfiguration Tool, Revision History and CRM Connect.",
	"help.trial": "Make a 30 days trial available.",
	"help.no_external_rendering":
		"Participants are required to agree to no external rendering software policy in submission",
	"help.visible": "By checking this the Extension will show up on Marketplace.",
	"help.you_can_enter_html": "You can enter HTML directly here. Make sure it is good code.",
	"help.whitelist":
		"The product will only be visible to the users with email address/domain in the list. NOTE: The product has to be set to be visible on Marketplace for the whitelist to apply.",

	// Success messages
	"success.accepted_cetrpl_agreement": "Accepted CETRPL agreement.",
	"success.accepted_policy": "Accepted policy",
	"success.added": "Successfully added.",
	"success.added_briefcase": "Successfully added a new briefcase.",
	"success.added_campaign": "Successfully added a new campaign.",
	"success.added_edition": "Successfully added a new edition.",
	"success.added_extension": "Successfully added a new extension.",
	"success.added_content_pack": "Successfully added a new content pack.",
	"success.added_manager": "Successfully added manager.",
	"success.added_project": "Successfully added a new project.",
	"success.added_role": "Successfully added a new role.",
	"success.allocated": "Successfully allocated.",
	"success.bulk_action_saved": "Bulk action successfully saved.",
	"success.block_catalogues": "Successfully blocked catalogues for this manufacturer",
	"success.check_your_email_we_have_sent_link": "We have sent you an email with instructions.",
	"success.clear_cache_key": "Successfully cleared cache key.",
	"success.crash_moved": "The crash has been move to the specified project.",
	"success.crash_submitted": "A support ticket has been created for your issue.",
	"success.cron_job_executed": (a) => `Cron job ${a[0]} was executed in ${a[1]}`,
	"success.customer_add": "Successfully created customer",
	"success.customer_edit": "Successfully edited customer information.",
	"success.deleted_briefcase": "Successfully deleted briefcase",
	"success.deleted_post": "Successfully deleted post",
	"success.demoted": "Successfully demoted",
	"success.edit": "Edit successful",
	"success.form_submitted": "Form successfully submitted",
	"success.imported": "The import was successful.",
	"success.invite": "The invitation was successful.",
	"success.job_added": "Job added.",
	"success.job_updated": "Job updated.",
	"success.job_already_in_queue": "Job already in queue.",
	"success.left_briefcase": "Successfully left briefcase.",
	"success.licenses_add": "Successfully added license(s).",
	"success.licenses_given": "License(s) was given to buyergroup/admingroup/discount group",
	"success.licenses_remove": "Successfully removed license(s).",
	"success.licenses_update": "Successfully updated license(s).",
	"success.merge_successful": "Customer merge successful.",
	"success.post_pinned": "Post pinned.",
	"success.post_unpinned": "Post unpinned.",
	"success.price_model_add": "Sucessfully added price model",
	"success.price_model_delete": "Sucessfully removed discount model",
	"success.price_model_edit": "Sucessfully updated price model",
	"success.price_set_edit": "Sucessfully updated price set",
	"success.price_set_delete": "Sucessfully removed prices",
	"success.product_settings_edit": "Sucessfully updated product settings",
	"success.removed": "Successfully removed",
	"success.removed_manager": "Successfully removed manager",
	"success.roles_set": "Successfully set roles.",
	"success.role_updated": "Successfully updated role.",
	"success.search_saved": "Successfully saved search.",
	"success.set_notification": "Successfully updated notifications",
	"success.signed_cetcl": "Successfully signed CETCL",
	"success.signed_cwalt": "Successfully signed CWATL",
	"success.signed_export_service": "Successfully signed up for Export Service",
	"success.signed_render_service": "Successfully signed up for Render Service",
	"success.thank_you_for_voting": "Thank you for voting!",
	"success.thanks_for_updating_us_regarding_testing":
		"Thanks for updating us regarding your testing!",
	"success.thanks_sign_off":
		"Thank you for signing off on migration and testing. Please remember to publish your Extension to Official Distributions in the Build Center on your planned release date.",
	"success.unallocated": "Successfully unallocated",
	"success.unsigned_test_confirmation_form": "Unsigned the test confirmation form",
	"success.unblock_catalogues": "Successfully unblocked catalogues for this manufacturer",
	"success.support_contact_added": "Support contact is successfully added",
	"success.support_contact_updated": "Support contact is successfully updated",
	"success.support_option_selected": "Support option is successfully selected",
	"success.support_updated": "Support information is successfully updated",
	"success.thank_you_for_voting_confirm":
		"Thank you for voting! We have sent you an email with a confirmation link that you need to open to confirm the vote.",
	"success.thank_you_for_your_feedback": "Thank you for your feedback!",
	"success.thank_you_for_your_message": "Thank you for your message!",
	"success.thank_you_participating": "Thank you for participating!",
	"success.thank_you_registering": "Thank you for registering",
	"success.ticket_created": "Review request sent successfully.",
	"success.training_registered":
		"The participants have been registered. We have sent a confirmation email to them.",
	"success.translation_updated": "The translation has been updated",
	"success.updated": "Successfully updated",
	"success.updated_campaign": "Successfully updated campaign.",
	"success.upload_success": "Successfully uploaded",
	"success.vote_has_been_confirmed": "Your vote has been confirmed.",
	"success.you_have_been_removed_from_the_event": "You have been removed from the event.",
	"success.your_message_has_been_sent": "Your message has been sent.",
	"success.your_password_has_been_changed": "Your password has been updated.",
	"success.your_submission_has_been_updated": "Your submission has been updated.",
	"success.trial_period_extended": "The trial period has been extended",
	"success.limit_updated": "The limit for this project has been updated",

	// Warnings
	"warning.already_added_to_session": (a) => `${a[0]} is already added to the session.`,
	"warning.unsign_test_confirmation": "This will unsign the test confirmation form.",
	"warning.event_doesnt_have_alternatives":
		"This event doesn't have any alternatives. Make sure you add them at some point, because without them users cannot register.",
	"warning.fill_out_all_necessary_fields_language":
		"Fill out all necessary fields for least one language!",
	"warning.missing_license_icon": "The Extension does not have a license icon.",
	"warning.missing_manufacturer_address": "The manufacturer does not have an address.",
	"warning.missing_manufacturer_bank_info":
		"The manufacturer does not have any bank information.",
	"warning.missing_owner": "The manufacturer does not have an owner.",
	"warning.missing_product": "The Extension does not have a product.",
	"warning.missing_product_category": "The product has not been put in any categories",
	"warning.missing_product_description": "The product does not have a description",
	"warning.missing_product_logo": "The product does not have a logo",
	"warning.missing_product_price":
		"The product is not set to be installed directly from Marketplace.",
	"warning.missing_released_product": "The Extension does not have a released edition.",
	"warning.extension_review_incomplete": "Extension review incomplete.",
	"warning.missingCETRPL": "Before releasing CETRPL needs to signed by an owner.",
	"warning.user_has_pending_invite": (a) => `${a[0]} has a pending invite.`,
	"warning.user_in_briefcase": (a) => `${a[0]} is a member of the briefcase`,
	"warning.user_is_briefcase_admin": (a) => `${a[0]} is an admin of the briefcase`,
	"warning.user_need_invite": "User need an invitation first",
	"warning.earlier_date_than_current": "You cannot set an earlier date than the current date",
	"warning.customer_already_added": "The customer is already added.",

	// Confirmations
	"confirm.user_will_be_created":
		"There is no MyConfigura user with this email address. Press the 'Add' button again to create an account for the user and to continue.",
	"confirm.clear_test_keys": "Are you sure you want clear the Test keys from cache database",
	"confirm.clear_production_keys":
		"Are you sure you want clear the Production keys from cache database",
	"confirm.remove_cache_data": (a) => `Are you sure you want to remove <b>${a[0]}</b>?`,

	// Resources
	resource_interest_1: "For your interest in the document",
	resource_interest_2: "An email is on its way to you with a link to the file.",

	// Direct translations
	activity: "Activity",
	comment: "comment",
	comments: "comments",
	hide_comments: "Hide comments",
	leave_feedback: "Leave feedback",
	like: "like",
	likes: "likes",
	participant_to_register: "Participant to be registered",
	participants_to_register: "Participants to be registered",
	scheduled: "Scheduled",
	thank_you: "Thank you",
	visited: "Visited",

	// Social media
	"social.cet.facebook": "CET on Facebook",
	"social.cet.instagram": "CET on Instagram",
	"social.cet.pinterest": "CET on Pinterest",
	"social.cet.twitter": "CET on Twitter",
	"social.configura.facebook": "Configura on Facebook",
	"social.configura.instagram": "Configurans on Instagram",
	"social.configura.linkedin": "Configura on Linkedin",
	"social.configura.twitter": "Configura on Twitter",
	"social.configura.vimeo": "Configura on Vimeo",
	"social.configura.youtube": "Configura on YouTube",

	// Access
	"access.developer": "Developer admins",
	"access.developer_explanation":
		"A Developer admin administrates projects. They can edit and create Extensions and also add developers and other Developer admins. The developer admin can also see the usage statistic of the extension.",
	"access.economy": "Economy admins",
	"access.economy_explanation":
		"An Economy admin administrates royalty and can add other Economy admins.",
	"access.owner": "Owners",
	"access.owner_explanation":
		"An Owner is the manufacturer representative who signs agreements and is also the legal owner of the product. The Owner has the same editing privileges as all other administrative roles.",
	"access.project": "Project access licensees",
	"access.project_explanation":
		"A project access admin has access to the Jira system where all their Basic Support requests or escalations from Configura will be going to.",
	"access.training": "Training admins",
	"access.training_explanation":
		"A Training admin administrates training and can add other Training admins.",
	"access.user": "User admins",
	"access.user_explanation":
		"A User admin administrates requests, filters, and users. They can also add other User admins.",

	// Titles
	"title.1_place": "1st Place",
	"title.2_place": "2nd Place",
	"title.3_place": "3rd Place",
	"title.about": "About",
	"title.academy": "Academy",
	"title.acknowledgement": "Acknowledgement",
	"title.access_explanation": "Access explanation",
	"title.access_token_owner_lookup": "Access Token Owner Lookup",
	"title.active_license_summary": "Active License Summary",
	"title.active_subscriptions": "Active Subscriptions",
	"title.activity": "Activity",
	"title.activity_logs": "Activity Logs",
	"title.add_alternative": "Add alternative",
	"title.add_attachments": "Add Attachments",
	"title.add_award": "Add award",
	"title.add_as_subextension": "Add as sub-extension",
	"title.add_briefcase": "Add Briefcase",
	"title.add_campaign": "Add campaign",
	"title.add_category": "Add category",
	"title.add_customer": "Add Customer",
	"title.add_contentpack": "Add Content Pack",
	"title.add_edition": "Add Edition",
	"title.add_event": "Add event",
	"title.add_event_group": "Add event group",
	"title.add_event_tag": "Add event tag",
	"title.add_extension": "Add Extension",
	"title.add_extensions": "Add extensions",
	"title.add_extension_roles": "Add Extension Roles",
	"title.add_job": "Add Job",
	"title.add_links": "Add Links",
	"title.add_manufacturer": "Add manufacturer",
	"title.add_manufacturer_roles": "Add Manufacturer Roles",
	"title.add_participant": "Add participant",
	"title.add_post": "Add post",
	"title.add_prices": "Add Prices",
	"title.add_product": "Add Product",
	"title.add_project": "Add project",
	"title.add_role": "Add Role",
	"title.add_session": "Add session",
	"title.add_slot": "Add slot",
	"title.add_speaker": "Add speaker",
	"title.add_sponsor": "Add sponsor",
	"title.add_support": "Add Support",
	"title.add_support_details": "Add support details",
	"title.add_task": "Add task",
	"title.adding_manufacturer_requires":
		"Adding a Manufacturer Requires an Authorized Representative Becoming the Owner.",
	"title.additional_information": "Additional information",
	"title.admins": "Admins",
	"title.administrators": "Administrators",
	"title.agreement": "Agreement",
	"title.agreement_status": "Agreement Status",
	"title.agreements": "Agreements",
	"title.all_about_virutal": "All about virtual",
	"title.allocate_project_access_license": "Allocate Project Access License",
	"title.alternative": "Alternative",
	"title.alternatives": "Alternatives",
	"title.anchor_buttons": "Anchor buttons",
	"title.applicable_to_customers": "Applicable to {{customers_count}} customer(s)",
	"title.archive": "Archive",
	"title.are_you_sure": "Are you sure?",
	"title.atomic_design": "Atomic design",
	"title.attachment": "Attachment",
	"title.attachments": "Attachments",
	"title.attend_webinar": "Attend a webinar",
	"title.authority_declaration": "Authority Declaration",
	"title.award_vote_statistics": (a) => `${a[0]} - Vote statistics`,
	"title.award_votes_for": (a) => `Votes for ${a[0]}`,
	"title.awards": "Awards",
	"title.aws-status": "AWS Status",
	"title.block": "Block",
	"title.body": "Body",
	"title.branch_name": "Git branch information",
	"title.breadcrumbs": "Breadcrumbs",
	"title.briefcase": "Briefcase",
	"title.briefcase_description": "Briefcase description",
	"title.briefcase_info": "Briefcase info",
	"title.briefcase_members": "Briefcase Members",
	"title.briefcase_post_add": "New Post",
	"title.briefcase_post_edit": "Edit Post",
	"title.briefcase_settings": "Briefcase Settings",
	"title.briefcases": "Briefcases",
	"title.bronze_level": "Bronze level",
	"title.browse_catalogues": "Browse Catalogues",
	"title.browse_stage_enabled_catalogues": "Browse Stage enabled catalogues",
	"title.browser_support": "Browser support",
	"title.build_details": "Build details",
	"title.bulk_action": "Bulk action",
	"title.bulk_action_details": "Bulk action details",
	"title.bullets": "Bullets",
	"title.bundled_extensions": "Bundled extensions",
	"title.button": "Button",
	"title.buttons": "Buttons",
	"title.by_attachment": "By attachment",
	"title.by_url": "By URL",
	"title.cache_data": "Cache Data",
	"title.cache_management": "Cache Management",
	"title.cache_stage_management": "Stage Cache Management",
	"title.cache_myconfigura_management": "MyConfigura Cache Management",
	"title.campaign": "Campaign",
	"title.campaign_participants": "Campaign Participants",
	"title.campaigns": "Campaigns",
	"title.campaign_merge_tool": "Campaign Merge Tool",
	"title.cards": "Cards",
	"title.catalogue_api": "Catalogue API",
	"title.catalogue_key": "Catalogue Key",
	"title.catalogue_keys": "Catalogue Keys",
	"title.catalogues": "Catalogues",
	"title.categories": "Categories",
	"title.cet_designer_awards": "CET Awards",
	"title.cet_extensions": "CET Extensions",
	"title.cetrpl_agreement": "Configura CET Royalty and Publishing License Agreement (CETRPL)",
	"title.change_roles": "Change Roles",
	"title.chat_support": "Chat support",
	"title.color": "Color",
	"title.colors": "Colors",
	"title.comments": "Comments",
	"title.conference": "Conference",
	"title.configura_support": "Configura Support",
	"title.configurans": "Configurans",
	"title.confirm_vote": "Confirm vote",
	"title.contact_details": "Contact Details",
	"title.contact_information": "Contact information",
	"title.content_packs": "Content Packs",
	"title.content_pack": "Content Pack",
	"title.content_admin": "Content Admin",
	"title.countdowns": "Countdowns",
	"title.cover_image": "Cover image",
	"title.crash": "Crash!",
	"title.cronjob_info": "Cron Job info",
	"title.customer": "Customer",
	"title.customers": "Customers",
	"title.demote": "Demote",
	"title.design_studio": "Design Studio",
	"title.details": "Details",
	"title.developer": "Developer",
	"title.developers": "Developers",
	"title.diamond_level": "Diamond level",
	"title.differ_expired_dates": "Different Expiration Dates",
	"title.digital_tickets": "Digital tickets",
	"title.disclaimer_information": "Disclaimer information",
	"title.drivers_upload": "Drivers Upload",
	"title.dropdowns": "Dropdowns",
	"title.dynamodb": "DynamoDB",
	"title.economy": "Economy",
	"title.edit": "Edit",
	"title.edit_api_settings": "Edit API Settings",
	"title.edit_campaign": "Edit campaign",
	"title.edit_customer": "Edit Customer",
	"title.edit_content_pack": "Edit Content Pack",
	"title.edit_details": "Edit Details",
	"title.edit_edition": "Edit edition",
	"title.edit_editions": "Setup new editions",
	"title.edit_extension": "Edit Extension",
	"title.edit_extension_roles": "Edit Extension Roles",
	"title.edit_job": "Edit Job",
	"title.edit_manufacturer_roles": "Edit Manufacturer Roles",
	"title.edit_prices": "Edit Prices",
	"title.edit_product": "Edit Product",
	"title.edit_role": "Edit Role",
	"title.edit_subscription": "Edit Subscription",
	"title.edit_support": "Edit Support",
	"title.edit_sso": "Edit Single Sign On",
	"title.editions": "Editions",
	"title.editions_availibility": "Editions availability",
	"title.event_hearts": "Event hearts",
	"title.event_info": "Event info",
	"title.event_settings": "Event settings",
	"title.events": "Events",
	"title.example_upload_page": "Example Upload Page",
	"title.expand": "Expand",
	"title.export_service": "Export service",
	"title.extend_trial_period": "Extend trial period",
	"title.extension": "Extension",
	"title.extensions": "Extensions",
	"title.extension_details": "Extension details",
	"title.extension_approval": "Approval",
	"title.extension_status": "Extension status",
	"title.extension_review": "Extension Review",
	"title.extension_roles": "Extension Roles",
	"title.external_api_keys": "External API Keys",
	"title.factbox": "Factbox",
	"title.faq": "FAQ",
	"title.favorite_briefcases": "Favorite Briefcases",
	"title.favorite_post": "Favorite post",
	"title.favorite_posts": "Saved Posts",
	"title.favorites": "Favorites",
	"title.files": "Files",
	"title.filespace": "Filespace",
	"title.forum": "Forum",
	"title.frequently_asked_questions": "Frequently asked questions",
	"title.front_end_docs": "Front-end docs",
	"title.general_information": "General Information",
	"title.get_in_touch": "Get in touch",
	"title.get_ready": "Get ready to level up",
	"title.gold_level": "Gold level",
	"title.gitlab_sentry": "Gitlab & Sentry",
	"title.give_campaign_licenses": "Give Campaign Licenses",
	"title.gitlab_sentry_access": "Gitlab & Sentry Access",
	"title.grandfathered_discounts": "Grandfathered discounts for {{product_name}}",
	"title.grandfathered_discounts_simple": "Grandfathered discounts",
	"title.gray": "Gray",
	"title.handle_access_tokens": "Handle access tokens",
	"title.hearts": "Hearts",
	"title.height": "Height",
	"title.hero": "Hero",
	"title.hey_whos_going": "Hey, who's going?",
	"title.how_can_we_help_you": "How can we help you?",
	"title.how_its_done": "How it's done",
	"title.icons": "Icons",
	"title.images": "Images",
	"title.info_for_user": "Information for user",
	"title.information": "Information",
	"title.installable_buyable_extensions": "Installable/Buyable extensions",
	"title.internal": "Internal",
	"title.invoices_near_suspension": "Invoices Near Suspension",
	"title.jira_project_name": "Jira Project Name",
	"title.job_opportunities": "Job opportunities",
	"title.keep_up_with_cet": "Keep up with CET",
	"title.keynotes": "Keynotes",
	"title.kitchen_rendering": "Kitchen rendering",
	"title.lazy_images": "Lazy images",
	"title.learning": "Learning",
	"title.legal_terms": "Legal terms",
	"title.let_your_boss_know": "Let your boss know",
	"title.license_admin": "License Administrator",
	"title.license_tool": "License Tool",
	"title.license_icon": "License Icon",
	"title.licenses": "Licenses",
	"title.like_this_comment": "Like this comment",
	"title.likes": "Likes",
	"title.links": "Links",
	"title.link_pm_data": "Link PM Data",
	"title.linked_manufacturer": "Linked Manufacturer",
	"title.list_of_speakers": "List of speakers",
	"title.loading_spinners": "Loading spinners",
	"title.log_details": "Log Details",
	"title.logotype": "Logotype",
	"title.logs": "Logs",
	"title.manufacturer": "Manufacturer",
	"title.manufacturer_authority_declaration": "Manufacturer Authority Declaration",
	"title.manufacturer_authorized_representative_owner":
		"Manufacturer Authorized Representative/Owner",
	"title.manufacturer_details": "Manufacturer Details",
	"title.manufacturer_info": "Manufacturer info",
	"title.manufacturer_list": "Manufacturer List",
	"title.manufacturer_product_support": "Manufacturer's Product Support",
	"title.manufacturer_subscription_tier": "Manufacturer Subscription Tier",
	"title.manufacturer_roles": "Manufacturer Roles",
	"title.manufacturers": "Manufacturers",
	"title.marketing": "Marketing",
	"title.marketplace": "Marketplace",
	"title.members": "Members",
	"title.merge_tool": "Merge tool",
	"title.migration": "Migration",
	"title.my_events": "My Events",
	"title.myconfigura": "MyConfigura",
	"title.new_licenses": "New Licenses",
	"title.new_manufacturer": "New manufacturer",
	"title.not_signed_off": (a) => `Hasn't signed off: ${a[0]}/${a[1]}`,
	"title.not_signed_off_updated": (a) => `Haven't updated: ${a[0]}/${a[1]}`,
	"title.notify": "Notify",
	"title.other_level": "Other", // Translation doesn't match on purpose to make sense on CET Experience
	"title.our_lovely_login": "Our lovely login",
	"title.our_lovely_sponsors": "Our lovely sponsors",
	"title.participants": "Participants",
	"title.pending_signature": "Pending Signature",
	"title.past": "Past",
	"title.picture": "Picture",
	"title.pinned_posts": "Pinned posts",
	"title.pins": "My Pins",
	"title.placeholders": "Placeholders",
	"title.planned_release_date": "Planned release date",
	"title.pm_data": "Project Matrix Data",
	"title.portfolios": "Portfolios",
	"title.posts": "Posts",
	"title.power_users": "Power users",
	"title.pre_register_for": (a) => `Pre-register for ${a}`,
	"title.presentation_material": "Presentation material",
	"title.preview": "Preview",
	"title.previous_attachments": "Previous Attachments",
	"title.previous_winners": "Previous winners",
	"title.prices": "Prices",
	"title.primary": "Primary",
	"title.privacy_notice": "Privacy notice",
	"title.product_er": "Product extension review",
	"title.products": "Products",
	"title.project_detail": "Project progress details",
	"title.publishing_program_agreement": "Publishing Program Agreement",
	"title.publishing_program_enrollment": "Publishing Program Enrollment",
	"title.publishing_program_fee": "Publishing Program Fee",
	"title.total_project_storage": "Total project storage",
	"title.max_seats": "Number of purchased users",
	"title.current_tier": "Current tier",
	"title.projects": "Projects",
	"title.props": "Props",
	"title.qa": "QA",
	"title.beta_distribution": "Beta Distribution",
	"title.region": "Region",
	"title.register": "Register",
	"title.register_for": (a) => `Register for ${a}`,
	"title.registration": "Registration",
	"title.release_statistics": "Release statistics",
	"title.remove": "Remove",
	"title.render_service": "Render service",
	"title.renewal": "Renewal",
	"title.reports": "Reports",
	"title.requests": "Requests",
	"title.role": "Role",
	"title.role_title": (a) => `Role: ${a}`,
	"title.roles": "Roles",
	"title.royalty": "Royalty",
	"title.royalty_agreement": "AGREEMENT",
	"title.royalty_bank_info": "Royalty bank info",
	"title.saved_preset": "Saved Preset",
	"title.schedule_overview": "Schedule Overview",
	"title.search": "Search",
	"title.most_recent_searches": "Most Recent Searches",
	"title.secondary": "Secondary",
	"title.secondary_browsers": "Secondary browsers",
	"title.select_support": "Select Support",
	"title.service_we_offer": "The services we offer",
	"title.services": "Services",
	"title.session_visibility": "Session Visibility",
	"title.sessions": "Sessions",
	"title.settings": "Settings",
	"title.share_info": "Consent on sharing your email address",
	"title.shortcuts": "Shortcuts",
	"title.show_manufacturer": "Show Manufacturer",
	"title.show_sessions": (a) => `${a[0]} of ${a[1]} sessions`,
	"title.sign_up": "Sign up",
	"title.signed_off": (a) => `Signed off: ${a[0]}/${a[1]}`,
	"title.signed_off_summary": "Planned release dates for manufacturers",
	"title.signed_off_updated": (a) => `Updated: ${a[0]}/${a[1]}`,
	"title.silver_level": "Silver level",
	"title.site_admin": "Site Admin",
	"title.size": "Size",
	"title.slots": "Slots",
	"title.sso_apps": "SSO Apps",
	"title.sso_list": "SSO Apps List",
	"title.sso_onboarding": "SSO Onboarding",
	"title.speakers": "Speakers",
	"title.sponsors": "Sponsors",
	"title.stage": "Stage",
	"title.stage_documentation_external": "Documentation for Stage (External)",
	"title.stage_links": "Stage links",
	"title.stage_management": "Stage Managament",
	"title.student_rendering": "Student rendering",
	"title.subscribe_to_configura_news": "Subscribe to Configura News",
	"title.subscription": "Subscription",
	"title.success_story": "Success story",
	"title.summary": "Summary",
	"title.summary_for": (a) => `Summary for ${a[0]}`,
	"title.support": "Support",
	"title.superior_support": "Superior Support",
	"title.support_agreement": "Support agreement",
	"title.support_contact_information": "Support contact information",
	"title.support_explanation": "Support Explanation",
	"title.support_information": (a) => `Support contact information ${a[0]}`,
	"title.support_options": "Select a Support option",
	"title.support_tool": "Support Tool",
	"title.support_tools": "Support Tools",
	"title.survey": "Survey",
	"title.system_recommendations": "System recommendations",
	"title.tags": "Tags",
	"title.task_handler": "Task handler",
	"title.task_result": "Task result",
	"title.terms_of_use": "Terms of use",
	"title.test_crash": "Test crash",
	"title.testing_confirmation": "Testing confirmation",
	"title.tfa_authenticate": "Authenticate",
	"title.tfa_management": "Management",
	"title.tfa_request": "Request",
	"title.the_venue": "The Venue",
	"title.title": "Title",
	"title.trainers": "Say hello to our trainers",
	"title.training": "Training",
	"title.translations": "Translations",
	"title.two_factor_authentication": "Two Factor Authentication",
	"title.uc.configura": "Configurans",
	"title.uc.keynote": "Keynotes",
	"title.uc.power_user": "Power users",
	"title.uc.sponsor": "Sponsors",
	"title.upcoming": "Upcoming",
	"title.uploads": "Uploads",
	"title.user": "User",
	"title.user_card_popups": "User card popups",
	"title.user_conference": "User Conference",
	"title.users": "Users",
	"title.uses_cet_designer": "Who uses CET?",
	"title.versions": "Versions",
	"title.video_tutorials": "Video tutorials",
	"title.view_subscription": "View Subscription",
	"title.visibility": "Visibility",
	"title.vote_statistics": "Vote statistics",
	"title.welcome_event": "Welcome event",
	"title.welcome_events": "Welcome events",
	"title.welcome_events_and_cocktail_hour": "Welcome events and cocktail hour",
	"title.welcome_to": "Welcome to ",
	"title.whats_new": "What's new",
	"title.whats_included_tier": "What's included in my tier?",
	"title.whitelist": "Whitelist",
	"title.who_has_access": "Who has access?",
	"title.who_is_the_owner": "Who is the Owner?",
	"title.your_info": "Your info",

	// Demo form - temp fix
	"demo_form.privacy_disclaimer_text1":
		"We will only use the information you provide to answer your question.",
	"demo_form.privacy_disclaimer_text2": "Privacy notice",

	// Info
	"info.active":
		"By activating the Extension users will be able to install it, depending on your settings.",
	"info.add_as_subextension": "Add this edition as a sub-extension of CET Developer.",
	"info.add_your_extension_by_uploading_xml":
		"Add your extension to MyConfigura by uploading your extension.xml file here.",
	"info.adding_manufacturer_requires":
		"Owner permissions are reserved exclusively for the organization's authorized representative. Development and service partners should only have Developer Admin (or lower) access to the MyConfigura Project. Only MyConfigura Owners are authorized to sign agreements and agree to the publishing fees.",
	"info.about_briefcases":
		"Anyone with a MyConfigura account can create their own Briefcases and invite colleagues to join. It is also possible to request an Extension-specific Briefcase that automatically adds and deletes whoever has access to that specific Extension. This is an easy solution to ensure information reaches the right people as the list will always be up-to-date.",
	"info.agree_to_above_agreement":
		"I solemnly agree to the above agreement’s terms and condition.",
	"info.allocate_project_access_license": (a) =>
		`Under the Publishing Program, each manufacturer is granted two project access licenses, assignable to any two individuals. The costs are covered by the program fee. Each of the project access license includes one license to Jira software, and one Sentry license.\n\nPlease add ${a[0]} individual(s) to be assigned with these license(s).`,
	"info.billable_from": "Billable from",
	"info.show_on_marketplace": "Show the edition on the marketplace.",
	"info.show_unsigned_cet_release_version": (a) =>
		`Showing manufacturers that have not sign off <b>${a[0]}</b>.`,
	"info.corresponding_distribution_version": `The corresponding official version for this distribution is: `,
	"info.release_date_share_info": (a) =>
		`<p>Enter the date you intend to release your Extension for this release. It will be published to <a href=${a[0]}>Extension Release Dates</a> on the CET Support page for support specialists and CET users to refer.</p>
		<p>Please note that you are responsible for publishing your Extension to Official Distribution on your planned release date, and that it has do be done within the first two weeks after the CET release date. Please click <a href="${a[1]}">here</a> for more information.</p>
		`,
	"info.branch_name": (a) =>
		`We highly recommend developers to follow the <a href="${a[0]}">Recommended Partner Branch Convention</a>.`,
	"info.briefcase_extension": (a) => `For users of <b>${a[0]}</b>`,
	"info.briefcase_notifications": "Recieve email notifications from Briefcase activity.",
	"info.catalogue_keys": "These keys are used in the Catalogue Creator to publish catalogues.",
	"info.catalogue_sign_up_agreement": (a) =>
		`I have read and agree to the <a href=${a[0]} class="link link--hover" target="_blank" rel="noopener noreferrer">terms of use</a> and is aware that my manufacturer will be billed for the service.`,
	"info.catalouges_summary_pricing": (a) =>
		`<p class="paragraph-1">
			<a class="link link--hover" href=${a[0]} target="_blank" rel="noopener noreferrer">Catalogues is a flexible database system</a> based on OFDA XML, which has been specifically created to improve accessibility of our customers’ products. The CET Designer extension <a class="link link--hover" href=${a[0]} target="_blank" rel="noopener noreferrer">Catalogue Creator</a> is the tool needed to produce, maintain, and publish a Catalogue, containing manufacturers’ product data and simple product logic.
		</p>
		<p class="paragraph-1">
			Customers and partners can create and maintain their own Catalogues, without any need for programming knowledge. It efficiently structures, processes and packages product data.
		</p>
		<p class="paragraph-1">
			To create a Catalogue, you need to have a CET Designer license and the free Catalogue Creator extension.
		</p>
		<p class="paragraph-1">
			You also need a Catalogue account which includes:
		</p>
		<ul class="ut-margin-top-1 paragraph-1">
			<li>Tools to control access and publishing</li>
			<li>The functionality in CET Designer for the users to be able to access the data. This is done either through the Catalogue browser or a customized extension.</li>
			<li>Fast distribution through Amazons network to users worldwide</li>
			<li>Storage on Amazon S3 servers</li>
		</ul>`,
	"info.confirm_paid_services":
		"I am sure that I want to sign for this <strong>billed service</strong> that my manufacturer will be charged for.",
	"info.content_tier": "Please select the level of content for this manufacturer.",
	"info.corresponding_official_version":
		"The corresponding official version for this distribution is: ",
	"info.evaluating_period_ends": "Evaluation period ends",
	"info.escrow_desc": "This information will be used to generate Escrow invoices",
	"info.extension_approval": "Enable approval for the extension that limits visibility.",
	"info.extension_support_1": "Configura Basic Support is included with Publishing Program",
	"info.extension_support_2": "Basic Support provides the extension manufacturer with:",
	"info.extension_support_additional_1":
		"Feedback on the extension, including feature requests, bug reports, and crash reports from end-users.",
	"info.extension_support_additional_2":
		"Quarterly reporting, enabling extension owners to view ticket volumes and trends for different issue types.",
	"info.extension_support_additional_3":
		"Extension owners' access to user logs, drawings, videos, images, and any embedded files related to users' issues.",
	"info.there_is_nothing_here": "There's nothing here",
	"info.editions_availability": "Make your extension available for one or more of these editions",
	"info.qa_release_statistics":
		"Here is a list of CET releases that has at least one major release candidate.",
	"info.qa_official_build":
		"The list below shows active major release candidates and any previously signed major release candidates, grouped by CET release versions",
	"info.share_information": (a) =>
		`By checking this box, you agree to share your email address with all CET Users and Manufacturers. Your email address will be displayed on the <a href="${a[0]}">Extension Release Dates page</a> and could therefore be used to contact you with questions on your Extension release.`,
	"info.qa_summary_action":
		"Summary of action that will be taken. Unblock update will be performed immediately while deactivate and force uninstall will be performed between 02:00-04:00 UTC.",
	"info.show_manufacturer": "This will allow people to see this manufacturer again.",
	"info.submit_extension_for_review_to":
		"In order to publish your Extension on the Marketplace a QA team from Configura will review it and make sure it is up to par. All new Extension releases will go through this process no matter who developed it.",
	"info.for_stage_service": "for our Stage service.",
	"info.extension_released": "Extension released!",
	"info.extension_in_beta_distribution": (a) =>
		"All extensions that are intended to be released in the upcoming version should be added in Beta distribution.",
	"info.favorite_briefcases": "Hit the star on your favorite Briefcase for easy access.",
	"info.full_list_of_activities_shortly":
		"A full list of activities is scheduled to be up on the website shortly.",
	"info.gitlab_sentry_access_slug": `Provide a repository slug to be used for Gitlab & Sentry access. The slug must be a URL-friendly and human readable name. Use only lowercase letters and "-" with maximum length of 25 characters. First character must not be "-".`,
	"info.hi": "Hi",
	"info.hit_tags_to_filter": "Hit the tags to start filtering.",
	"info.manufacturer_authority_declaration":
		"Under the Configura’s Publishing Program, only the authorized representative/owner of the manufacturer has the authority to sign the Configura CET Royalty and Publishing License Agreement (CETRPL). Make sure you possess all the necessary rights, accesses to the manufacturer's content and the decisive authority required. There will be costs incurred for publishing extensions and catalogue on the Configura ecosystem. Please confirm with your accounting counterparts for more details.",
	"info.manufacturer_authorized_representative_owner":
		"Authorized representative/owner of the manufacturer assumes responsibility for signing legal agreements. This individual must possess the requisite rights, access to the manufacturer's content, and the decisive authority necessary for such engagements.",
	"info.manufacturer_not_signed_cetrpl_owner":
		"You have not signed Configura CET Royalty and Publishing License Agreement (CETRPL). Sign to get started",
	"info.manufacturer_not_signed_cetrpl_non_owner":
		"This manufacturer has not signed Configura CET Royalty and Publishing License Agreement (CETRPL) agreement. Please contact the owners to get started.",
	"info.manufacturer_subscription_tier": (a) =>
		`Configura offers different tiers for the manufacturer to custom according to your business needs.`,
	"info.manufacturer_product_support":
		"CET Core Support is included with the purchase of a CET license, while Configura Basic Support is covered as part of the Publishing Program. Configura’s basic support is however not a replacement for manufacturer’s product support, kindly add the contact information for manufacturer’s specific support below.",
	"info.enrollment_for_publishing_program_part_1": (a) =>
		`You are now enrolling in the Publishing Program. Creating an Extension or catalogue incurs a cost for your data to be accessible in the Configura ecosystem. Please reach out to your Configura contact for more information about our content packaging options. If you are uncertain about who your contact is, please use this <a href="${a[0]}" class="link link--hover" target="_blank" rel="noopener noreferrer">form</a> and you will be contacted.`,
	"info.enrollment_for_publishing_program_part_2":
		"All Extensions are subject to approval, and all relevant agreements must be signed before getting access to the MyConfigura Marketplace and official distribution. If all this is not fulfilled, publishing will not be allowed.",
	"info.extend_trial_period":
		"Select a date to extend the trial period. You cannot select an earlier date than the current date",
	"info.search_tool_add":
		"Please enter admin email and confirm by pressing the 'Add Customer' button",
	"info.see_current_support":
		"Here you can see your current support options and any subscriptions running.",
	"info.set_manufacturer_as_partner": "Set this manufacturer as Configura’s Partner",
	"info.jira_project_name":
		"If the manufacturer has an existing Jira project, you can attach the name here.",
	"info.license_agreement":
		"To make our pricing model more predictable and to help you budget for future CET license(s) purchases, we’ve implemented a Configura License Agreement (CLA).  The agreement includes an automated renewal process, a nominal price increase each year for license(s) based on Consumer Price Index (CPI-U) and an updated security clause. All license administrators are required to sign the CLA to renew or purchase any license(s). ",
	"info.latest_event_logs": "These are the latest 50 logs regarding all events.",
	"info.list_of_users_that_will_recieve_and_email":
		"List of users that will recieve an email when a new participant is registered to the event.",
	"info.lost_discounts": "You are about to lose the volume discounts for specific extension(s)",
	"info.losing_grandfathered": `You have selected <b>{{activeQuantity}}</b> license(s) of <b>{{extensionName}}</b> for your next renewal. <br>
	This will cause you to lose your current volume discount at  <b>{{percentage}}%</b>. <br>
	The minimum number of licenses required to maintain the current volume discount is  <b>{{minimumQuantity}}</b>. <br><br>`,
	"info.losing_grandfathered_confirm": `To proceed with the changes, click  <b>"Confirm and Save"</b>. <br> If you wish to reselect the number of licenses to renew, click  <b>"Cancel"</b> <br>`,
	"info.members_are_hidden_in_this_briefcase": "Members are hidden in this Briefcase",
	"info.myconfigura":
		"MyConfigura is your personalized space in Configura's cloud. Here you will manage your account, your licenses and collaborate with customers and colleagues.",
	"info.no_admin": "No admin",
	"info.no_aggregated_usage": "No aggregated usage.",
	"info.no_api_tokens": "No API Tokens.",
	"info.no_briefcases": "No briefcases to show.",
	"info.no_charge_for_internal_implementation":
		"We don't charge partners marked as: Internal or Service and Development partners.",
	"info.no_data": "No data",
	"info.no_events_at_the_moment": "There are no events at the moment.",
	"info.no_manufacturers_signed": "No manufacturer has signed off for this build",
	"info.no_license_group": (a) =>
		`Unfortunately you are not part of any license group. This means we need to handled your claim manually. Best course of action is to contact <a href=${a[0]}>our support</a>`,
	"info.no_official_version": "There is no official version for this CET release yet.",
	"info.no_pending_invites": "There are currently no pending invites.",
	"info.no_selected_favorites":
		"You have not selected any Favorites. Click the Heart symbol on a session to add it as a Favorite.",
	"info.no_favorites": "You don't have any favorite briefcases",
	"info.no_managers": "You are not a manager for any briefcases",
	"info.no_portfolios_in_stage": "No portfolios have been made available for Stage yet.",
	"info.no_portfolios_in_stage_locate_one":
		"No portfolios have been made available for Stage yet. Locate one you want to make available and click the details button.",
	"info.no_access_tokens_in_stage": "No Access Tokens have been created for Stage yet.",
	"info.no_sessions_matching_filter":
		"There are no sessions this day matching the current filter.",
	"info.no_speakers_for_this_event":
		"No speakers have been added to this event yet. You can add them then come back here and add them to this session if you want.",
	"info.no_submissions": "No submissions",
	"info.no_tags_for_this_event":
		"No tags have been added to this event yet. You can add them then come back here and add them to this session if you want.",
	"info.no_training": "You don't have any training briefcases",
	"info.no_unmerged_users": "No available/unmerged users under this campaign.",
	"info.not_complete": "Not complete",
	"info.no_portfolio_description": "The portfolio does not have a description",
	"info.not_registered": "Not registered",
	"info.notice_subscription_next_renewal":
		"Any changes made to subscriptions will be present in the next renewal.",
	"info.nothing_matches_your_filter": "Nothing matches your filter.",
	"info.marketplace_subscriptions_link_description": (a) =>
		`If you need to purchase licenses for immediate use, please find them on our <a href=${a[0]}>Marketplace</a> and Check Out.`,
	"info.only_an_owner_can_sign_up": "Only an Owner can sign up for this services.",
	"info.owner_permission_required": "You have to be an Owner to sign this form",
	"info.cetrpl_agreement": "I acknowledge that I have read and I agree to the above terms.",
	"info.permission_missing_for_action": "Missing permission to perform action.",
	"info.pinned_post": "Hit the bookmark icon for a post to add it here",
	"info.portfolios_connect_manufacturer":
		"Here you can see all the portfolios connected to your manufacturer.",
	"info.pre_registration_required": "Pre-registration required",
	"info.prices_subject_to_cpi": (a) =>
		`* Prices are based on purchased price and are subject to CPI increase. Current CPI is <b>${a[0]}%</b> and is used in this summary`,
	"info.privacy_information":
		"We will only use the information you provide to answer your question.",
	"info.privacy_notice": "Privacy notice",
	"info.proceeded_to_checkout": "Proceeded to checkout",
	"info.project_is_a_subextension": "This project is a subextension.",
	"info.projects_no_access": "You don't have access to any manufacturer.",
	"info.projects_you_have_access_to": "These are the projects you have access to.",
	"info.projects_licenses_to_allocate": (a) =>
		`You have ${a[0]} project access license(s) pending to be allocated.`,
	"info.publishing_program_agreement": (a) =>
		`Carefully read the terms and condition of the Configura CET Royalty and Publishing License Agreement (CETRPL) below and click on the checkbox to sign the agreement. Once the agreement is signed, the manufacturer added can publish their extensions into the Configura ecosystem. <a class="link link--hover" target="_blank" rel="noopener noreferrer" href="${a[0]}">CETRPL Agreement</a>`,
	"info.publishing_program_agreement_notification": (a) =>
		`A notification email to sign the Configura CET Royalty and Publishing License Agreement (CETRPL) has been sent to the authorized representative/owner - <b>${a[0]}</b>. You’ll be notified once the agreement is signed and be designated as the Developer Admin for the project.`,
	"info.publishing_program_fee":
		"Creating an Extension or catalogue incurs a cost for your data to be accessible in the Configura ecosystem. Please contact your Account Manager for more information about our content packaging options.",
	"info.unlimited": "Unlimited",
	"info.registration_is_not_open": "Registration is not open",
	"info.registration_is_also_available":
		"Registration/Check-in is also available between 5-7 p.m. on Oct 15.",
	"info.session_requires_registration":
		"If this session requires a session then choose the corresponding event in the list.",
	"info.services_signed_up_for":
		"These are the services the partner have signed up for and the prices are billed annually.",
	"info.sign_agreement": (a) =>
		`I have read and agree to the <a href=${a[0]} class="link link--hover" target="_blank" rel="noopener noreferrer">agreement</a>`,
	"info.signed_off_summary": (a) =>
		`To access Manufacturer specific contact information, visit our <a href=${a[0]} class="link link--hover" target="_blank" rel="noopener noreferrer">Marketplace</a> and click on the necessary Manufacturer to view their contact information if applicable. In case the Extension's contact can't be found, you can reach out to <a href=${a[1]} class="link link--hover" target="_blank" rel="noopener noreferrer">CET Support</a>.`,
	"info.signed_off_summary_date": `The date given as the Release Date is only an estimated date and may change without notice.`,
	"info.sign_support_agreement": (a) =>
		`I have read and agree to the <a href=${a[0]} class="link link--hove" target="_blank" rel="noopener noreferrer">support agreement</a>.`,
	"info.slot_alternative_mapping":
		"When a session has a pre-registration event use this to map an alternative from the event that matches this slot. This is used to make sure users can't register on a slot that has the same start time.",
	"info.sold_out": "Sold out",
	"info.special_sessions_hearted": "Special sessions can't be hearted.",
	"info.superior_support":
		"I'm interested to learn more about Configura Superior Support upgrade.",
	"info.superior_support_elevate":
		"You can elevate your support experience even further by opting for Superior Support, which offers personalized assistance through an annual retainer. Additional charges apply.",
	"info.support_for_your_extension": "Support for your extension is handled by Configura",
	"info.superior_support_contact":
		"You've opted in to learn more about Configura Superior Support upgrade. Configura Support will reach out to you soon.",
	"info.stage_cost_for_development_partner":
		"Partners marked as Development partners we don't charge an annual fee. They are also allowed to use up to 100 000 requests per month before we charge them.",
	"info.stage_cost_for_internal": "We don't charge partners marked as Internal",
	"info.stage_links": "Here you can see useful links related to the Stage service.",
	"info.stage_sign_up":
		"Integrate a single product configurator into your website or application using Catalogue data.",
	"info.this_will_remove_you_from_the_event": "This will remove you from the event",
	"info.testing_confirmation": (a) =>
		`Smoke test and migration test for ${a[0]} are completed and any issues found were reported.`,
	"info.total": "Total",
	"info.unrenewable_extensions":
		"These Extensions have no specified pricing and can not be renewed:",
	"info.two_factor_auth_desc":
		"The page you are accessing requires secure login access. Please authorize Two Factor Authentication before proceeding.",
	"info.two_factor_auth_locked":
		"Authentication is locked for this user. Contact web to unlock it.",
	"info.you_agreed_to_cetx_policy":
		"You agreed to our CET Experience Registration and Attendance Policy.",
	"info.you_are_not_in_any_briefcase_at_the_moment":
		", you're not in any Briefcases at the moment. You need to be invited by the Manager of the Briefcase.",
	"info.you_can_change":
		"You can change your information or purchase Support from Configura by clicking the Edit button.",
	"info.deadline_for_cetx_event": (a) =>
		`You have until <strong class="red-text">${a[0]}</strong> to change your event details (excluding Hands-on Sessions).`,
	"info.you_must_accept_disclaimer_information":
		"You must accept the disclaimer information before you can continue.",
	"info.your_manufacturer_already_signed": "Your manufacturer has already signed.",
	"info.vote_already_confirmed": "This vote has already been confirmed",
	"info.we_care_about_your_privacy":
		"We care about your privacy and will only use the information you provide to send this email.",
	"info.welcome_to_your_new_briefcase":
		"Welcome to your new Briefcase! Use the new post button to create the first post, or invite new members",
	"info.we_made_changes": "We made some changes on MyConfigura.",
	"info.stage_service_info":
		"Here you can see what Stage services your manufacturer have signed up for.",
	"info.stage_summary_pricing": `<p class="paragraph-1">
			We are providing you with the possibility to have your own single product configurator directly on your website. It is completely based upon Catalogues, which means you can now leverage your content in multiple ways.
		</p>
		<p class="paragraph-1">
			Stage is made up of two building blocks; A Catalogue Web API and a Software Development Kit. With our well-defined API you can easily fetch your Catalogue data to use in applications outside of CET. The SDK lets you work with Catalogue data and enables you to integrate it into your own website or app.
		</p>
		<h2 class="heading-2">Pricing</h2>
		<p class="paragraph-1">
			The price is made up of two parts. First, a fixed subscription price as the base and second a dynamic usage price, based on the number of requests made to the API.
		</p>
		<ul class="ut-margin-top-1 paragraph-1">
			<li>Annual Fixed Subscription Price: $5,000</li>
			<li>Per-Request Dynamic Usage Price: $0.005</li>
		</ul>
		<h2 class="heading-2">Evaluting period</h2>
		<p class="paragraph-1">
			To get you started we offer a 60-day evaluating period. The period starts when signing up and last for 60-days before changing over to the paid service.
		</p>`,
	"info.stage_pro_service_sign_up":
		"I want to sign up for the Stage Pro Service and I are aware that my manufacturer will be billed for the service.",
	"info.stage_pro_summary_pricing": `<p class="paragraph-1">
			Stage Pro Service is an additional service for Stage that lets you make API calls to export and render.
		</p>
		<h2 class="heading-2">Pricing</h2>
		<p class="paragraph-1">
			The price is made up of two parts. First, a fixed subscription price as the base and second a dynamic usage price, based on the number of requests made to the API.
		</p>
		<ul class="ut-margin-top-1 paragraph-1">
			<li>Annual Fixed Subscription Price: $3,000</li>
			<li>Per-Request Dynamic Usage Price for Export: $0.01</li>
			<li>Per-Request Dynamic Usage Price for Render: $0.1</li>
		</ul>`,
	"info.stage_cost_info_title": "About Request Cost",
	"info.stage_cost_info": `<p class="paragraph-1">
		The request costs and their sums are calculated according to the actual values.
		The displayed values in this page are rounded up to the nearest hundredth of a dollar and may appear inconsistent.
	</p>
	`,
	"info.whats_included": "What's included?",
	"info.collab_service_agreement":
		"Thank you for subscribing to our esteemed service. We are thrilled to have you on board and look forward to providing you with an exceptional experience. Before we proceed, we kindly request you to sign up for our service agreement, which outlines the terms and conditions governing our mutual relationship.",
	"info.confirm_sa": (a) =>
		`By clicking Confirm, you agree to our <a target="_blank" href="${a[0]}">Terms and Conditions</a>`,
	"info.sign_agreement_success": "Successfully signed agreeement",
	"info.sign_agreement_failure": "Failed to sign agreement",
	"info.yet_to_sign_agreement": "This customer has yet to sign the service agreement",
	"info.ask_admin_to_sign": "Your admin has to sign the service agreement for you to access this",

	// Standard texts
	"standard.log_in": "Log in",
	"standard.no_posts_at_the_moment": "There are no posts at the moment.",
	"standard.remove_data": (a) => `You can remove <strong>${a[0]}</strong> if you want to.`,
	"standard.remove_data_confirm": (a) => `Type in <strong>${a[0]}</strong> to confirm.`,
	"standard.remove_data_warning":
		"Please note that this is irreversible and the data will be lost forever.",
	"standard.remove_portfolio_from_stage":
		"Please note that removing this from Stage will cause any services and applications that rely on this portfolio to stop working.",

	// Referring section
	"referring.desc.attend_webinar":
		"Attend one of our many webinars, or request a custom webinar, to learn how you can get started with CET.",
	"referring.desc.find_out_how":
		"Find out how your company can use our platform to create, control, market and use Extensions for CET.",
	"referring.desc.how_can_we_help_you":
		"Now that you know who we are, how does Configura correlate to you? Learn more about how we help companies in different industries.",
	"referring.desc.job_opportunities":
		"Want to join our team? We are always looking for skilled individuals who want to help us form the future of tech. Check out our job postings and internships.",
	"referring.desc.service_we_offer":
		"We are here to help you every step of the way. We offer many services to ensure successful implementation of CET into your organization.",
	"referring.desc.uses_cet_designer":
		"The CET user base is rapidly growing all around the world. See all available Extensions and download a trial on our Marketplace.",
};

export const en = (translatable: Translatable) => translate(lang, translatable);
