import { classNames, withDefault } from "cfg-base";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
	class?: string;
	color?: "gray" | "blue" | "green" | "orange" | "yellow" | "turquoise" | "instant";
	expand?: boolean;
	ghost?: boolean;
	href: string;
	icon?: string;
	loading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	onMouseEnter?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
	rel?: string;
	router?: boolean;
	size?: "xs" | "sm" | "lg" | "xl";
	t?: Translator;
	target?: string;
	text: string;
	visualType?: "danger" | "flat" | "primary";
	state?: any;
}

export function AnchorButton(props: Props) {
	const loading = withDefault(props.loading, false);
	let text = props.text;
	if (props.t) {
		text = props.t(text);
	}

	const classes = classNames([
		"button",
		loading && "is-loading",
		props.class && props.class,
		props.color && "mod-" + props.color,
		props.expand && "mod-expand",
		props.ghost && "mod-ghost",
		props.size && "mod-" + props.size,
		props.visualType && "mod-" + props.visualType,
	]);

	let icon;
	if (props.icon) {
		icon = <i className={"svg-icon " + props.icon} />;
	}

	const Tag: React.ElementType = props.router ? Link : "a";
	const TagProps = {
		className: classes,
		...(props.target && { target: props.target }),
		...(props.router && {
			to: !props.state ? props.href : { pathname: props.href, state: props.state },
		}),
		...(props.rel && { rel: props.rel }),
		...(!props.router && { href: props.href }),
		onClick: props.onClick,
		onMouseEnter: props.onMouseEnter,
		onMouseLeave: props.onMouseLeave,
	};

	return (
		<Tag {...TagProps}>
			{icon}
			{text}
		</Tag>
	);
}
