import { debugLog, fetchJSON, generateRandomID } from "cfg-base";
import React from "react";

export interface FormHandler {
	id: string;

	onChangeText: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
	>;
	onChangeCheck: React.ChangeEventHandler<HTMLInputElement>;
	onChangeTextTinyMCE: any;

	disabled: (name?: string) => boolean;
	label: (name: string) => string;
	loading: (name?: string) => boolean;
	validate: (name: string) => Option<string>;
	value: (name: string) => any;
}

export interface FormProps extends React.ClassAttributes<HTMLFormElement> {
	url?: string;
	t: Translator;
	id?: string;
	skipDisable?: boolean;
}

export interface FormState {
	active?: string;
	error?: string;
	fields: string[];
	info?: string;
	loading?: boolean;
	success?: string;
	tArgs?: string[];
	validation?: any;
}

export class Form<P = {}, S = { data?: any }> extends React.Component<P & FormProps, S & FormState>
	implements FormHandler {
	id: string;
	state = {
		fields: [] as Readonly<string[]>,
		validation: {},
	} as Readonly<S & FormState>;

	constructor(props: FormProps & P, context?: any) {
		super(props, context);

		this.id = props.id !== undefined ? props.id : generateRandomID();

		this.onBlur = this.onBlur.bind(this);
		this.onChangeCheck = this.onChangeCheck.bind(this);
		this.onChangeText = this.onChangeText.bind(this);
		this.onError = this.onError.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
		this.onChangeTextTinyMCE = this.onChangeTextTinyMCE.bind(this);
	}

	formData() {
		return this.state.fields.reduce((obj, key) => {
			obj[key] = this.value(key);
			return obj;
		}, {} as { [key: string]: any });
	}

	onSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		return this.submit();
	}

	submit() {
		if (this.state.loading === true) {
			return;
		}

		if (typeof this.props.url !== "string") {
			debugLog("[Form.submit] Trying to submit, but no url is available.");
			return;
		}

		if (!this.props.skipDisable) {
			this.setState({ loading: true } as any);
		}

		const data = this.formData();

		return fetchJSON(this.props.url, data).then(this.onSuccess).catch(this.onError);
	}

	onSuccess(response: any) {
		this.setState({ loading: false } as any);
		if (response.success) {
			this.setState({
				error: undefined,
				info: undefined,
				success: response.success,
				validation: undefined,
			});
		} else if (response.info) {
			this.setState({
				error: undefined,
				info: response.info,
				success: undefined,
				validation: undefined,
			});
		} else if (response.error) {
			this.setState({
				error: response.error,
				info: undefined,
				success: undefined,
				validation: response.validation,
			});
		} else if (!response.debug && !response.data) {
			debugLog("unknown response", response);
		}

		if (response.tArgs) {
			this.setState({ tArgs: response.tArgs });
		}

		if (response.data) {
			this.setState({ data: response.data } as any);
		}

		return response;
	}

	onError(error: string) {
		this.setState({
			error: `An unknown error occured: ${error}`,
			loading: false,
		} as any);
	}

	onChangeText(
		event: React.FormEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
	) {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;

		const state: any = {};
		state[name] = value !== "" ? value : undefined;
		this.setState(state);
	}

	onChangeTextTinyMCE(content: any, editor: any) {
		const name = editor.targetElm.name;

		const state: any = {};
		state[name] = content;
		this.setState(state);
	}

	onChangeCheck(event: React.ChangeEvent<HTMLInputElement>) {
		const name = event.currentTarget.name;
		const value = event.currentTarget.value;
		const checked = event.currentTarget.checked;

		const state: any = {};
		state[name] = checked ? value : undefined;
		this.setState(state);
	}

	onFocus(event: React.FocusEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) {
		this.setState({ active: event.currentTarget.name } as any);
	}

	onBlur(event: React.FocusEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) {
		this.setState({ active: "" } as any);
	}

	validate(name: string) {
		if (this.state.validation !== undefined && this.state.validation[name] !== undefined) {
			return this.props.t(this.state.validation[name]);
		}
	}

	active(name: string) {
		return this.state.active === name;
	}

	value(name: string) {
		return (this.state as any)[name];
	}

	disabled(name?: string) {
		return this.state.loading === true;
	}

	loading(name?: string) {
		return this.state.loading === true;
	}

	label(name: string) {
		return this.props.t(name);
	}
}
