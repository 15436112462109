import * as forms from "cfg-base";
import { fetchJSON, generateRandomID, TypedKeys, urls } from "cfg-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type FormEndpoint = keyof forms.FormRequests & keyof forms.FormResponses;

export type FormRequestData<E extends FormEndpoint> = forms.FormRequests[E];
export type FormResponseData<E extends FormEndpoint> = forms.FormResponses[E];

export type StringArrayKeys<T> = TypedKeys<Array<string>, T>;

export type TypedSubset<V, T> = { [Key in TypedKeys<V, T>]: V };

export type FormFieldName<E extends FormEndpoint> = keyof FormRequestData<E> & string;

export interface FormResponse<RequestData> {
	validation?: { [Key in keyof RequestData]?: Validation<RequestData[Key]> };
	success?: string | TranslatableMessage;
	error?: string | TranslatableMessage;
	errorDetails?: Array<{ error: string | TranslatableMessage }>;
	info?: string;
}

export function submitForm<
	Endpoint extends FormEndpoint,
	RequestData extends FormRequestData<Endpoint>
>(
	endpoint: Endpoint,
	data: RequestData
): Promise<FormResponse<RequestData> | FormResponseData<Endpoint>> {
	return fetchJSON(urls.apiURL(endpoint), data);
}

export type Validation<V> = V extends Array<infer T>
	? Array<{ [K in keyof T]?: Validation<T[K]> }>
	: V extends string
	? string
	: V extends number
	? string
	: V extends boolean
	? string
	: V extends object
	? { [K in keyof V]?: Validation<V[K]> }
	: never;

export type ValidationObject<T> = { [Key in keyof T]?: Validation<T[Key]> };

export interface HookFormRequestValues<Req> {
	id: string;
	loading: boolean;
	values: Req;
	validation: ValidationObject<Req>;
}

export interface HookFormRequestUpdater<Req> extends HookFormRequestValues<Req> {
	setValue: <Key extends keyof Req>(
		key: Key,
		value: Req[Key] | ((prev: Req[Key]) => Req[Key])
	) => void;
}

export interface HookFormRequest<Req> extends HookFormRequestUpdater<Req> {
	setValidation: <Key extends keyof Req>(key: Key, validation: Validation<Req[Key]>) => void;
	setValidations: (validation: ValidationObject<Req>) => void;
	clearValidation: () => void;
	setLoading: (loading: boolean) => void;
}

export interface HookFormSubmit<Req, Res> {
	request: HookFormRequest<Req>;
	response?: Res | FormResponse<Req>;
	submitter: (arg: Req) => Promise<Res | FormResponse<Req> | undefined>;
	setResponse: (response?: Res | FormResponse<Req>) => void;
}

// Backward compatibility
export type HookFormStateUpdater<Endpoint extends FormEndpoint> = HookFormRequestUpdater<
	FormRequestData<Endpoint>
>;
export type HookFormState<Endpoint extends FormEndpoint> = HookFormRequest<
	FormRequestData<Endpoint>
> &
	HookFormSubmit<FormRequestData<Endpoint>, FormResponseData<Endpoint>>;
export function useHookFormState<Endpoint extends FormEndpoint>(
	endpoint: Endpoint,
	initial: FormRequestData<Endpoint>
): HookFormState<Endpoint> {
	const request = useHookFormRequest<FormRequestData<Endpoint>>(initial);
	const submitter = useCallback(
		(arg: FormRequestData<Endpoint>) => submitForm(endpoint, arg),
		[endpoint]
	);
	const submit = useHookFormSubmit(submitter, request);
	const state = useMemo(() => ({ ...request, ...submit }), [request, submit]);

	return state;
}

export function useHookFormRequest<Req>(initial: Req): HookFormRequest<Req> {
	const [formState, setFormState] = useState<HookFormRequest<Req>>((): HookFormRequest<Req> => {
		const id = generateRandomID();
		return {
			id,
			loading: false,
			setLoading: (loading) => {
				setFormState((prev) => ({ ...prev, loading }));
			},
			values: initial,
			setValue: (key, value) => {
				setFormState((prev) => {
					if (prev.loading || prev.values[key] === value) {
						return prev;
					}

					const next =
						typeof value === "function" ? (value as any)(prev.values[key]) : value;
					return {
						...prev,
						values: { ...prev.values, [key]: next },
					};
				});
			},
			validation: {},
			setValidation: (key, validation) => {
				setFormState((prev) => ({
					...prev,
					validation: { ...prev.validation, [key]: validation },
				}));
			},
			setValidations: (validation) => {
				setFormState((prev) => ({ ...prev, validation }));
			},
			clearValidation: () => {
				setFormState((prev) => ({ ...prev, validation: {} }));
			},
		};
	});

	return formState;
}

export function useHookFormSubmit<Req, Res>(
	submitter: (arg: Req) => Promise<Res>,
	request: HookFormRequest<Req>
): HookFormSubmit<Req, Res> {
	const [formState, setFormState] = useState<HookFormSubmit<Req, Res>>(
		(): HookFormSubmit<Req, Res> => {
			return {
				request,
				submitter,
				setResponse: (response) => {
					setFormState((prev) => {
						if (prev.response === response) {
							return prev;
						}
						const validation =
							(response && "validation" in response && response["validation"]) || {};
						request.setValidations(validation);
						return { ...prev, response };
					});
				},
			};
		}
	);

	useEffect(() => {
		setFormState((prev) => {
			const response = submitter === prev.submitter ? prev.response : undefined;
			return { ...prev, request, submitter, response };
		});
	}, [request, submitter]);

	return formState;
}

export interface Props<Req, Res> {
	formState: HookFormSubmit<Req, Res>;
	onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
	className?: string;
	preventEnterSubmit?: boolean;
	disabled?: boolean;
}

export function HookForm<Req, Res>(props: React.PropsWithChildren<Props<Req, Res>>) {
	const { className, onSubmit, preventEnterSubmit, disabled } = props;
	const {
		request: { loading, setLoading, values },
		submitter,
		response,
		setResponse,
	} = props.formState;

	useEffect(() => {
		// set loading to false when a response has been received
		setLoading(false);
	}, [response, setLoading]);

	useEffect(() => {
		let cancelled = false;

		if (!loading) {
			return;
		}

		submitter(values)
			.then((res) => {
				if (cancelled) {
					return;
				}
				setResponse(res);
			})
			.catch((err) => {
				if (cancelled) {
					return;
				}
				setResponse({ error: err instanceof Error ? err : `${err}` });
			});

		return () => {
			cancelled = true;
		};
	}, [submitter, loading, setLoading, setResponse, values]);

	const submit = useMemo(() => {
		if (onSubmit) {
			return onSubmit;
		}

		return (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			setLoading(true);
		};
	}, [onSubmit, setLoading]);

	const disabledState = disabled === undefined ? false : disabled;

	const enterPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
		if (e.key === "Enter") e.preventDefault();
	};

	return (
		<form
			className={className}
			onSubmit={submit}
			onKeyPress={preventEnterSubmit ? enterPress : undefined}
		>
			<fieldset disabled={disabledState}>{props.children}</fieldset>
		</form>
	);
}
