import * as moment from "moment-timezone";
import { Moment } from "moment-timezone";

export function get(date: Date, ampm?: boolean) {
	const now = new Date();
	const diff = now.valueOf() - date.valueOf();

	if (diff < MINUTE) {
		return "Just now";
	}

	// Under 1 hour
	if (diff < HOUR) {
		const minutes = Math.round(diff / MINUTE);
		return minutes + " minutes ago";
	}

	let hours = leadingZero(date.getHours());
	let minutes = leadingZero(date.getMinutes());
	let ampmText = "";
	if (ampm) {
		const hourObj = convertToAMPM(parseInt(hours));
		hours = hourObj.hours.toString();
		ampmText = hourObj.ampm;
	}

	// Today
	if (now.getDate() === date.getDate() && diff < DAY) {
		return `Today, ${hours}:${minutes} ${ampmText}`;
	}

	// Yesterday
	const yesterday = new Date(now.valueOf() - DAY);
	if (yesterday.getDate() === date.getDate() && diff < DAY * 2) {
		return `Yesterday, ${hours}:${minutes} ${ampmText}`;
	}

	const stringMonth = date.toLocaleString("en-us", { month: "short" });
	const dateDigit = leadingZero(date.getDate());

	// Same year
	if (now.getFullYear() === date.getFullYear()) {
		return `${stringMonth} ${dateDigit}, ${hours}:${minutes} ${ampmText}`;
	}

	const month = leadingZero(date.getMonth() + 1);
	const year = date.getFullYear();

	return `${year}-${month}-${dateDigit}, ${hours}:${minutes} ${ampmText}`;
}

export function getAbsolute(date: Date, ampm?: boolean) {
	let hours = leadingZero(date.getHours());
	let minutes = leadingZero(date.getMinutes());
	let seconds = leadingZero(date.getSeconds());

	let ampmText = "";
	if (ampm) {
		const hourObj = convertToAMPM(parseInt(hours));
		hours = hourObj.hours.toString();
		ampmText = " " + hourObj.ampm;
	}

	const dateDigit = leadingZero(date.getDate());
	const month = leadingZero(date.getMonth() + 1);
	const year = date.getFullYear();

	return `${year}-${month}-${dateDigit} ${hours}:${minutes}:${seconds}${ampmText}`;
}

export function getDateTime(date: number) {
	const datetimeObject = convertTimestampToDateTimeStrings(date);
	return `${datetimeObject.date} ${datetimeObject.time}`;
}

export function leadingZero(number: number) {
	return number < 10 ? `0${number}` : `${number}`;
}

export function convertDateTimeInput(date: HTMLInputElement, time: HTMLInputElement) {
	if (date.type !== "date" || date.value === "" || time.type !== "time" || time.value === "") {
		return;
	}

	const [year, month, day] = date.value.split("-").map(Number);
	const [hour, minute] = time.value.split(":").map(Number);

	const milliseconds = new Date(year, month - 1, day, hour, minute).valueOf();
	const unixTimestamp = Math.floor(milliseconds / 1000);

	return unixTimestamp;
}

export function convertTimestampToDateTimeStrings(timestamp: number) {
	const date = new Date(timestamp * 1000);

	const year = date.getFullYear();
	const month = leadingZero(date.getMonth() + 1);
	const day = leadingZero(date.getDate());

	const hours = leadingZero(date.getHours());
	const minutes = leadingZero(date.getMinutes());

	const timezone = new Date().getTimezoneOffset();
	if (timezone < 180) {
		return {
			date: `${year}-${month}-${day}`,
			time: `${hours}:${minutes}`,
		};
	} else {
		const converted = convertToAMPM(parseInt(hours));
		return {
			date: `${year}-${month}-${day}`,
			time: `${converted.hours}:${minutes}${converted.ampm}`,
		};
	}
}

export function convertMomentToMonthDayYear(mom: Moment | undefined) {
	if (mom === undefined) {
		return "";
	}
	const newdate = mom.format("MM/DD/YY");
	return newdate;
}

export function convertMomentToYearMonthDayLong(mom: Moment | undefined) {
	if (mom === undefined) {
		return "";
	}
	const newdate = mom.format("YYYY-MM-DD");
	return newdate;
}

export function convertMomentToMonthDayYearLong(mom: Moment | undefined) {
	if (mom === undefined) {
		return "";
	}
	const newdate = mom.format("MMMM D, YYYY");
	return newdate;
}

export function convertMomentToMonthDayYearTimeLong(mom: Moment | undefined) {
	if (mom === undefined) {
		return "";
	}
	const newdate = mom.format("MMM D, YYYY HH:mm");
	return newdate;
}

export function convertMomentToYear(mom: Moment | undefined) {
	if (mom === undefined) {
		return "";
	}
	const newdate = mom.format("YYYY");
	return newdate;
}

/**
 * day in milliseconds
 */
export const DAY = 86400000;
/**
 * hour in milliseconds
 */
export const HOUR = 3600000;
/**
 * minute in milliseconds
 */
export const MINUTE = 60000;
/**
 * second in milliseconds
 */
export const SECOND = 1000;

export const TZ_SELECT_DATA = [
	{ value: "PT", title: "Pacific Time (UTC-07:00/-08:00)" },
	{ value: "MT", title: "Mountain Time (UTC-06:00/-07:00)" },
	{ value: "CT", title: "Central Time (UTC-05:00/-06:00)" },
	{ value: "ET", title: "Eastern Time (UTC-04:00/-05:00)" },
	{ value: "WET", title: "Western European Time (UTC+00:00/+01:00)" },
	{ value: "CET", title: "Central European Time (UTC+01:00/+02:00)" },
	{ value: "EET", title: "Eastern European Time (UTC+02:00/+03:00)" },
	{ value: "MYT", title: "Malaysia Time (UTC+08:00)" },
];

const TZ_FORMAT: { [key: string]: Option<string> } = {
	PDT: "PST8PDT",
	PST: "PST8PDT",
	PT: "PST8PDT",

	MDT: "MST7MDT",
	MST: "MST7MDT",
	MT: "MST7MDT",

	CDT: "CST6CDT",
	CST: "CST6CDT",
	CT: "CST6CDT",

	EDT: "EST5EDT",
	EST: "EST5EDT",
	ET: "EST5EDT",

	GMT: "GMT",
	UTC: "UTC",

	WEST: "WET",
	WET: "WET",

	CEST: "CET",
	CET: "CET",

	EEST: "EET",
	EET: "EET",

	MYT: "Asia/Kuala_Lumpur",

	"Asia/Shanghai": "Asia/Kuala_Lumpur",
};

export const MONTHS = [
	{ title: "January", value: 0 },
	{ title: "February", value: 1 },
	{ title: "March", value: 2 },
	{ title: "April", value: 3 },
	{ title: "May", value: 4 },
	{ title: "June", value: 5 },
	{ title: "July", value: 6 },
	{ title: "August", value: 7 },
	{ title: "September", value: 8 },
	{ title: "October", value: 9 },
	{ title: "November", value: 10 },
	{ title: "December", value: 11 },
];

export function formatTimezone(tz: string) {
	const match = TZ_FORMAT[tz];
	if (match !== undefined) {
		return match;
	}
	return tz;
}

export function unixToMoment(unix: Option<number>, tz?: string) {
	if (!unix) {
		return;
	}

	const mom = moment.unix(unix);

	if (!mom.isValid()) {
		return;
	}

	mom.tz(formatTimezone(tz ?? moment.tz.guess()));

	return mom;
}

const dateFormat = moment.HTML5_FMT.DATE;
const timeFormat = moment.HTML5_FMT.TIME;
export function momentToInputValues(mom?: moment.Moment) {
	let date = "";
	let time = "";

	if (mom) {
		date = mom.format(dateFormat);
		time = mom.format(timeFormat);
	}

	return {
		date,
		time,
	};
}

const datetimeFormat = `${dateFormat} ${timeFormat}`;
export function inputValuesToMoment(date: string, time: string, tz: string) {
	const mom = moment.tz(`${date} ${time}`, datetimeFormat, true, formatTimezone(tz));

	if (!mom.isValid()) {
		return;
	}

	return mom;
}

export const FORMAT_DATE_TIME_TZ = "YYYY-MM-DD HH:mm z";

const convertToAMPM = (hour: number) => {
	let ampm = hour >= 12 ? "pm" : "am";
	let hours = hour % 12;
	hours = hours ? hours : 12;
	return { hours, ampm };
};
