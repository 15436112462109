export const INITIAL_MENU_STATE: MenuState = {
	index: 0,
	mobile: false,
	visibility: "closed",
};

export function menuReducer(state: MenuState, action: MenuAction): MenuState {
	switch (action.type) {
		case "menu:finishAnimation":
			return handleFinishAnimation(state);
		case "menu:close":
			return handleClose(state);
		case "menu:toggle":
			return handleToggle(state);
		case "menu:clickItem":
			return handleClickItem(state, action.payload.index);
		case "menu:hoverItem":
			return handleHoverItem(state, action.payload.index);
		case "menu:responsive":
			return handleResponsive(state, action.payload);
	}

	return state;
}

export interface MenuState {
	index: number;
	mobile: boolean;
	visibility: MenuVisibility;
}

export type MenuVisibility = "opened" | "opening" | "closed";

export type MenuAction =
	| { type: "menu:finishAnimation" }
	| { type: "menu:close" }
	| { type: "menu:toggle" }
	| { type: "menu:clickItem"; payload: { index: number } }
	| { type: "menu:hoverItem"; payload: { index: number } }
	| { type: "menu:responsive"; payload: { overflowWidth: number; offsetWidth: number } };

function handleFinishAnimation(state: MenuState): MenuState {
	if (state.visibility === "opening") {
		return {
			...state,
			visibility: "opened",
		};
	}

	return state;
}

function handleClose(state: MenuState): MenuState {
	return {
		...state,
		visibility: "closed",
	};
}

function handleToggle(state: MenuState): MenuState {
	return {
		...state,
		visibility: state.visibility !== "closed" ? "closed" : "opened",
	};
}

function handleResponsive(
	state: MenuState,
	dims: { overflowWidth: number; offsetWidth: number }
): MenuState {
	const isMobile = dims.overflowWidth > dims.offsetWidth;
	if (isMobile !== state.mobile) {
		return {
			...state,
			mobile: isMobile,
			visibility: !isMobile || state.visibility === "closed" ? "closed" : "opened",
		};
	}

	return state;
}

function handleClickItem(state: MenuState, index: number): MenuState {
	if (state.visibility === "opened") {
		return {
			...state,
			visibility: "closed",
		};
	} else {
		return {
			...state,
			visibility: "opening",
			index,
		};
	}
}

function handleHoverItem(state: MenuState, index: number): MenuState {
	const visibility = state.visibility !== "opened" ? "opening" : "opened";
	return {
		...state,
		visibility,
		index,
	};
}
