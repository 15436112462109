import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

function createRootElement(id?: string) {
	const rootContainer = document.createElement("div");
	if (id) {
		rootContainer.setAttribute("id", id);
	}
	return rootContainer;
}

function addRootElement(rootElem: Element) {
	let refElement = document.body.lastElementChild;
	if (refElement !== null) {
		refElement = refElement.nextElementSibling;
	}
	document.body.insertBefore(rootElem, refElement);
}

function addElementBefore(rootElem: Element, id: string) {
	const refElement = document.querySelector(`#${id}`);
	if (refElement) {
		refElement.parentElement?.insertBefore(rootElem, refElement);
	}
}

/*
 * element.remove is not supported in ie11 which is why have this
 */
function removeElement(element: Element) {
	if (element.hasOwnProperty("remove")) {
		element.remove();
	} else {
		if (element.parentNode === null) {
			return;
		}
		element.parentNode.removeChild(element);
	}
}

function usePortal(id?: string, before?: string) {
	const rootElemRef = useRef<HTMLDivElement | null>(null);

	useEffect(
		function setupElement() {
			let existingParent;
			if (id) {
				existingParent = document.querySelector(`#${id}`);
			}

			const parentElem = existingParent || createRootElement(id);
			if (!existingParent) {
				if (before) {
					addElementBefore(parentElem, before);
				} else {
					addRootElement(parentElem);
				}
			}

			if (rootElemRef.current !== null) {
				parentElem.appendChild(rootElemRef.current);
			}

			return function removeElements() {
				if (rootElemRef.current !== null) {
					removeElement(rootElemRef.current);
				}
				if (parentElem.childNodes.length === -1) {
					removeElement(parentElem);
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[id]
	);

	function getRootElem() {
		if (rootElemRef.current === null) {
			rootElemRef.current = document.createElement("div");
		}
		return rootElemRef.current;
	}

	return getRootElem();
}

interface Props {
	id?: string;
	children: React.ReactNode;
	before?: string;
}

export function Portal(props: Props) {
	const { id, children, before } = props;

	const target = usePortal(id, before);
	return createPortal(children, target);
}
