import { classNames } from "cfg-base";
import "hammer-timejs";
import * as Hammer from "hammerjs";
import React, { useEffect, useRef, useState } from "react";

interface Props {
	content: React.ReactNode[];
	current: number;
	darkColor?: boolean;
	gutter?: boolean;
	onIndicatorChange?: (index: number, prevCurrent: number) => void;
	onLeftChange: (prevCurrent: number) => void;
	onRightChange: (prevCurrent: number) => void;
	getMcRef?: (mc: HammerManager) => void;
}

export const Carousel: React.FC<Props> = (props) => {
	const {
		current,
		content,
		darkColor,
		gutter,
		onLeftChange,
		onRightChange,
		onIndicatorChange,
		getMcRef,
	} = props;
	const contentLength = content.length;

	const [prevCurrent, setPrevCurrent] = useState(0);
	const [swipe, setSwipe] = useState(0);
	const ref = useRef<HTMLDivElement>(null);
	const mc = useRef<HammerManager>();

	useEffect(() => {
		if (getMcRef && mc && mc.current) {
			getMcRef(mc.current);
		}
	}, [getMcRef]);

	useEffect(() => {
		function handleKeyDown(event: KeyboardEvent) {
			const code = event.keyCode || event.charCode;
			switch (code) {
				case 37:
					onLeftChange(prevCurrent);
					break;
				case 39:
					onRightChange(prevCurrent);
					break;
				default:
					break;
			}
		}

		document.addEventListener("keydown", handleKeyDown);

		return function cleanup() {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [onLeftChange, onRightChange, prevCurrent]);

	useEffect(() => {
		function swipeLeft(event: HammerInput) {
			setSwipe(-1);
			onRightChange(prevCurrent);
		}

		function swipeRight(event: HammerInput) {
			setSwipe(1);
			onLeftChange(prevCurrent);
		}

		if (!ref.current) {
			return;
		}

		if (mc.current === undefined) {
			mc.current = new Hammer.Manager(ref.current);
			mc.current.add(
				new Hammer.Swipe({
					direction: Hammer.DIRECTION_HORIZONTAL,
					pointers: 1,
					threshold: 10,
				})
			);
		}

		const mcCurr = mc.current;

		mcCurr.on("swipeleft", swipeLeft);
		mcCurr.on("swiperight", swipeRight);

		return () => {
			mcCurr.off("swipeleft", swipeLeft);
			mcCurr.off("swiperight", swipeRight);
		};
	}, [prevCurrent, onLeftChange, onRightChange, ref]);

	const classes = classNames(["carousel", !gutter ? "carousel--noGutter" : undefined]);

	return (
		<div className={classes}>
			{contentLength > 1 && (
				<>
					<button
						className="carousel__Prev"
						onClick={(e) => {
							e.preventDefault();
							onLeftChange(prevCurrent);
						}}
						type="button"
					>
						<i className="svg-icon mod-chevron mod-left" />
					</button>
					<button
						className="carousel__Next"
						onClick={(e) => {
							e.preventDefault();
							onRightChange(prevCurrent);
						}}
						type="button"
					>
						<i className="svg-icon mod-chevron mod-right" />
					</button>
					<ol className="carousel__Indicators">
						{onIndicatorChange &&
							content.map((content, i) => {
								const classes = classNames([
									"carousel__Indicator",
									darkColor ? undefined : "carousel__Indicator--white",
								]);
								return i === current ? (
									<li
										key={i}
										className={`${classes} carousel__Indicator--active`}
									/>
								) : (
									<li
										className={classes}
										key={i}
										onClick={() => {
											onIndicatorChange(i, prevCurrent);
											setSwipe(0);
										}}
									/>
								);
							})}
					</ol>
				</>
			)}
			<div ref={ref} className="carousel__Container">
				{content.map((content, i) => (
					<SlideShowContent
						contentLength={contentLength}
						current={current}
						index={i}
						key={i}
						prevCurrent={prevCurrent}
						setPrevCurrent={setPrevCurrent}
						swipe={swipe}
					>
						{content}
					</SlideShowContent>
				))}
			</div>
		</div>
	);
};

interface SlideShowContentProps {
	children?: React.ReactNode;
	contentLength: number;
	current: number;
	index: number;
	prevCurrent: number;
	setPrevCurrent: React.Dispatch<React.SetStateAction<number>>;
	swipe: number;
}

const SlideShowContent: React.FC<SlideShowContentProps> = (props) => {
	const { index, current, prevCurrent, setPrevCurrent, swipe, contentLength } = props;

	let className = "carousel__Content";
	if (current !== prevCurrent) {
		const override1 = current === 0 && prevCurrent === contentLength - 1 && swipe === 1;
		const override2 = current === contentLength - 1 && prevCurrent === 0 && swipe === -1;
		if (index === prevCurrent) {
			if (override1) {
				className += " carousel__Content--outLeft";
			} else if (override2) {
				className += " carousel__Content--outRight";
			} else if (current < prevCurrent) {
				className += " carousel__Content--outRight";
			} else {
				className += " carousel__Content--outLeft";
			}
			className += " carousel__Content--active carousel__Content--willChange";
		} else if (index === current) {
			if (override1) {
				className += " carousel__Content--inRight";
			} else if (override2) {
				className += " carousel__Content--inLeft";
			} else if (current < prevCurrent) {
				className += " carousel__Content--inLeft";
			} else {
				className += " carousel__Content--inRight";
			}
		}
	} else if (index === current) {
		className += " carousel__Content--active carousel__Content--willChange";
	}

	return (
		<div onAnimationEnd={() => setPrevCurrent(current)} className={className}>
			{props.children}
		</div>
	);
};
