import { defined, withDefault } from "cfg-base";
import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";

import { Container } from "../../grid/Container";
import {
	MenuObject,
	MenuOptions,
	MobileMenu,
	NotificationType,
	StaticMenu,
	TopMenu,
	clearCachedMenu,
	useMenu,
} from "../../hooks/useMenu";

import OpenNewIcon from "../../../../myconfigura/src/resources/open_new.svg";
import { Alert } from "../../molecules/Alert";
import { DropdownMenu } from "../../molecules/DropdownMenu";
import { SocialMedia } from "../../molecules/SocialMedia";
import { DevBar } from "../DevBar";
import { TestBar } from "../TestBar";
import { MobileButton, MobileNav } from "./MobileNav";
import { MenuNavList, NavLinkItem, NavTextItem } from "./Navs";
import { NotificationContainer } from "./NotificationContainer";
import { INITIAL_MENU_STATE, MenuAction, MenuState, menuReducer } from "./menuReducer";

export const Menu: React.FC<MenuProps> = ({
	menu: menuName,
	url,
	t,
	callbacks,
	logoSrc,
	router,
}) => {
	let { menu, error } = useMenu(menuName, url);

	const [notification, setNotification] = useState<NotificationType | undefined>(
		menu.notification
	);

	return (
		<>
			<MenuInner
				callbacks={callbacks}
				left={menu.left}
				logoSrc={logoSrc}
				menu={menuName}
				mobileMenu={menu.mobileMenu}
				right={menu.right}
				router={!!router}
				socialIcons={menu.socialIcons}
				static={menu.static}
				staticMobileMenu={menu.staticMobileMenu}
				t={t}
				testMode={menu.testMode}
				devMode={menu.devMode}
				url={url}
			/>

			{notification && (
				<NotificationContainer
					clearCache={() => clearCachedMenu(menuName)}
					setNotification={setNotification}
					notification={notification}
				/>
			)}

			{error && (
				<Alert type={"error"} size="sm">
					<div className="container">
						{t("error.while_loading_menu")}
						<a
							className="link link--border-link--black"
							href="mailto:webrrt@configura.com"
						>
							webrrt@configura.com
						</a>
					</div>
				</Alert>
			)}
		</>
	);
};

interface InnerMenuProps extends MenuProps {
	left: TopMenu[];
	mobileMenu: MobileMenu;
	right: TopMenu[];
	socialIcons: boolean;
	static: StaticMenu[];
	staticMobileMenu: MenuObject[];
	t: Translator;
	router: boolean;
	devMode?: boolean;
	testMode?: boolean;
}

const MenuInner: React.FC<InnerMenuProps> = ({
	left,
	right,
	mobileMenu,
	logoSrc,
	socialIcons,
	static: staticMenu,
	staticMobileMenu,
	router,
	t,
	testMode,
	devMode,
}) => {
	const [state, dispatch] = useReducer(menuReducer, INITIAL_MENU_STATE);

	// We can further break apart the components if desired. The ref hooks are
	// localized within each component (menu bar, foldout, foldout nav) making
	// it easier to componentize. For now, it seems manageable without it.
	const menuBar = useResponsiveMenuBar(dispatch, left, right);
	const menuFoldout = useSlidingFoldout(dispatch, left);
	const menuFoldoutNav = useAutoHeightFoldoutNav(state, left);
	const headerHandlers = useMenuHeaderHandlers(state, dispatch);
	const navHandlers = useMenuNavHandlers(dispatch);

	useFrozenBackground(state);

	const { visibility, mobile, index } = state;

	const isOpen = visibility !== "closed";
	const showFoldout = !!left[0]?.menu;
	const foldoutClass = isOpen && !mobile ? "is-active" : "";
	const closeMenu = () => dispatch({ type: "menu:close" });
	const toggleMenu = () => dispatch({ type: "menu:toggle" });

	return (
		<header
			id="react-menu-header"
			onMouseLeave={headerHandlers.handleMouseLeave}
			onMouseEnter={headerHandlers.handleMouseEnter}
		>
			<div id="menu-bar">
				<Container>
					<nav className="nav" id="menu-bar-nav" ref={menuBar}>
						<div className="nav-left">
							<a
								className="nav-item link link--hover"
								id="configura-header-logo"
								href={
									"https://" +
									window.location.hostname.replace(
										/^(www2|app|.+-app|my)(?=\.)./,
										"www."
									)
								}
							>
								<img src={logoSrc} alt="Configura logotype" />
							</a>
							<ul className={"nav-items-list" + (mobile ? " mod-hide" : "")}>
								{left.map((menu, index) => {
									return renderNavItem(
										menu,
										index,
										state,
										navHandlers,
										router,
										t
									);
								})}
							</ul>
						</div>
						<div className="nav-right">
							<ul className="nav-items-list">
								{right
									.filter((item) => !mobile || !item.hideOnMobile)
									.map((menu, index) => {
										return renderNavItem(
											menu,
											index,
											state,
											navHandlers,
											router,
											t
										);
									})}
							</ul>
							{mobile && <MobileButton onClick={toggleMenu} open={false} />}
						</div>
					</nav>
					{isOpen && mobile && (
						<MobileNav
							onToggle={toggleMenu}
							menu={mobileMenu}
							open={true}
							socialIcons={socialIcons}
							staticMenu={staticMobileMenu}
							inner={right.filter((item) => item.innerOnMobile)}
							t={t}
							router={router}
							onClickLink={closeMenu}
						/>
					)}
				</Container>
				{testMode && <TestBar />}
				{devMode && <DevBar t={t} />}
			</div>
			{showFoldout && (
				<div id="menu-foldout" className={foldoutClass} ref={menuFoldout}>
					<Container>
						<nav id="menu-foldout-nav">
							<div
								id="menu-foldout-nav-primary"
								className="compensate-logo"
								ref={menuFoldoutNav}
							>
								{withDefault(left[index].menu, []).map((item, index) => (
									<MenuNavList
										className="menu-suboptions"
										links={item}
										key={index}
										t={t}
										onSoftNav={closeMenu}
										router={router}
									/>
								))}
							</div>
							<div id="menu-foldout-nav-static">
								{staticMenu.map((item, index) => (
									<MenuNavList
										className={item.class}
										links={item.links}
										key={item.class + index}
										t={t}
										router={router}
									/>
								))}
								{socialIcons && <SocialMedia account="configura" t={t} />}
							</div>
						</nav>
					</Container>
				</div>
			)}
		</header>
	);
};

function useResponsiveMenuBar(
	dispatch: React.Dispatch<MenuAction>,
	left: TopMenu[],
	right: TopMenu[]
): React.RefObject<HTMLElement> {
	const menuBar = useRef<HTMLElement>(null);
	const overflowWidth = useRef<number>();

	useLayoutEffect(() => {
		overflowWidth.current = menuBar.current ? calculateOverflowWidth(menuBar.current) : 0;
		dispatch({
			type: "menu:responsive",
			payload: {
				overflowWidth: overflowWidth.current,
				offsetWidth: menuBar.current?.offsetWidth || 0,
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [left, right]);

	useEffect(() => {
		let unmounted = false;

		const handleResponsive = () => {
			if (!unmounted) {
				dispatch({
					type: "menu:responsive",
					payload: {
						overflowWidth: overflowWidth.current || 0,
						offsetWidth: menuBar.current?.offsetWidth || 0,
					},
				});
			}
		};

		window.addEventListener("resize", handleResponsive);

		return () => {
			window.removeEventListener("resize", handleResponsive);
			unmounted = true;
		};
	}, [dispatch]);

	return menuBar;
}

function useFrozenBackground(state: MenuState) {
	const { visibility } = state;

	useLayoutEffect(() => {
		const display = document.body.style.display;
		if (visibility === "opened") {
			document.body.classList.add("ut-overflow-hidden");
			document.body.style.display = "static";
		}

		return () => {
			if (visibility === "opened") {
				document.body.classList.remove("ut-overflow-hidden");
				document.body.style.display = display;
			}
		};
	}, [visibility]);
}

function useSlidingFoldout(
	dispatch: React.Dispatch<MenuAction>,
	left: TopMenu[]
): React.RefObject<HTMLDivElement> {
	const menuFoldout = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let unmounted = false;

		let foldout = menuFoldout.current;
		let handleTransitionEnd = () => {
			if (!unmounted) {
				dispatch({ type: "menu:finishAnimation" });
			}
		};

		foldout?.addEventListener("transitionend", handleTransitionEnd);

		return () => {
			foldout?.removeEventListener("transitionend", handleTransitionEnd);
			unmounted = true;
		};
	}, [left, dispatch]);

	return menuFoldout;
}

function useAutoHeightFoldoutNav(
	state: MenuState,
	left: TopMenu[]
): React.RefObject<HTMLDivElement> {
	const { visibility, mobile, index } = state;
	const isOpen = visibility !== "closed";

	const foldout = useRef<HTMLDivElement>(null);
	const menuHeights = useRef(new Map<number, number>());

	useEffect(() => {
		menuHeights.current.clear();
	}, [left]);

	useEffect(() => {
		let unmounted = false;

		const clear = () => {
			if (!unmounted) {
				menuHeights.current.clear();
			}
		};

		window.addEventListener("resize", clear);

		return () => {
			window.removeEventListener("resize", clear);
			unmounted = true;
		};
	}, []);

	useLayoutEffect(() => {
		if (!mobile && foldout.current) {
			if (isOpen) {
				let foldoutHeight = menuHeights.current.get(index);
				if (foldoutHeight === undefined) {
					foldoutHeight = calculateFoldoutHeight(foldout.current);
					menuHeights.current.set(index, foldoutHeight);
				}

				foldout.current.style.maxHeight = foldoutHeight + "px";
			}
		}
	}, [isOpen, index, mobile, foldout]);

	return foldout;
}

function useMenuHeaderHandlers(state: MenuState, dispatch: React.Dispatch<MenuAction>) {
	const headerDelayTimeout = useRef<NodeJS.Timeout>();

	const handleMouseEnter = () => {
		if (headerDelayTimeout.current) {
			clearTimeout(headerDelayTimeout.current);
			headerDelayTimeout.current = undefined;
		}
	};
	const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
		if (e.clientY > 0 && !state.mobile) {
			headerDelayTimeout.current = setTimeout(() => {
				dispatch({ type: "menu:close" });
			}, 233 * 2);
		}
	};

	return {
		handleMouseEnter,
		handleMouseLeave,
	};
}

function useMenuNavHandlers(dispatch: React.Dispatch<MenuAction>): NavHandlers {
	const navDelayTimeout = useRef<NodeJS.Timeout>();

	const makeMouseClickNavHandler = (index: number) => () => {
		dispatch({ type: "menu:clickItem", payload: { index } });
	};

	const makeMouseEnterNavHandler = (index: number) => () => {
		navDelayTimeout.current = setTimeout(() => {
			dispatch({ type: "menu:hoverItem", payload: { index } });
		}, 233);
	};

	const handleMouseLeaveNav = () => {
		if (navDelayTimeout.current) {
			clearTimeout(navDelayTimeout.current);
		}
	};

	return {
		makeMouseClickNavHandler,
		makeMouseEnterNavHandler,
		handleMouseLeaveNav,
	};
}

function calculateOverflowWidth(element: HTMLElement): number {
	let overflowWidth = 0;

	// HACK: When reloading in mobile mode, hidden elements have scrollWidth of 0
	const nav = element.querySelector(".nav-items-list.mod-hide");
	nav?.classList.remove("mod-hide");

	const children = element.children;
	for (let i = children.length - 1; i >= 0; i--) {
		overflowWidth += children[i].scrollWidth;
	}

	nav?.classList.add("mod-hide");

	return overflowWidth;
}

function calculateFoldoutHeight(element: HTMLDivElement): number {
	const GUTTER = 24;
	const BOTTOM = 16;
	const bounds: Rect[] = [];
	const primaryItems = element.children;
	const primaryWidth = element.offsetWidth;

	let foldoutHeight = 0;

	/**
	 * Set foldout to maximum of all elements so all will at least fit in one column.
	 * */
	for (let i = primaryItems.length - 1; i >= 0; i--) {
		const b = computeSubmenuRect(primaryItems[i]);
		bounds.push(b);
		foldoutHeight += b.height + BOTTOM;
	}

	/**
	 * On this iteration we try to fit one more item in the available width
	 * If we can we reduce the total height and try again
	 * If we can't we stop and we now have our columns
	 */
	const firstWidth = bounds[bounds.length - 1].width + GUTTER;
	const compareWidth: Rect[] = [];
	let columns = 1;
	for (let i = 0; i < bounds.length; i++) {
		const b = bounds[i];
		compareWidth.push(b);
		const totalWidth = compareWidth.reduce((p, c) => c.width + GUTTER + p, 0) + firstWidth;
		const fits = totalWidth <= primaryWidth;
		if (fits) {
			columns++;
			foldoutHeight = foldoutHeight - b.height - BOTTOM;
		} else {
			break;
		}
	}

	/**
	 * For this iteration we change the foldout height if our first column
	 * doesn't become to small compared to the second one.
	 */
	for (let i = columns - 1; i < bounds.length; i++) {
		const b = bounds[i];
		const height = b.height + BOTTOM;
		const updatedHeight = (foldoutHeight - height) * 1.1;
		const secondHeight = bounds
			.slice(columns - 2, i + 1)
			.reduce((p, c) => c.height + BOTTOM + p, 0);
		if (secondHeight > 0 && secondHeight < updatedHeight) {
			foldoutHeight = updatedHeight;
		} else {
			break;
		}
	}

	/**
	 * Lastly make sure we haven't reduced the height too much by running
	 * through each item just making sure we at least are as tall or
	 * taller than the tallest individual item
	 */
	const height = bounds.reduce((p, c) => {
		if (p < c.height) {
			return c.height;
		}
		return p;
	}, foldoutHeight);
	foldoutHeight = height + BOTTOM;

	return foldoutHeight;
}

/**
 * Helper to render appropriate nav item depending on item configuration.
 */
function renderNavItem(
	item: TopMenu,
	index: number,
	state: MenuState,
	handlers: NavHandlers,
	router: boolean,
	t: Translator
) {
	if (item.extras && item.extras.dropdown) {
		const links = [];
		if (item.menu !== undefined) {
			for (const menu of item.menu) {
				for (const item of menu) {
					const [title, href, extras] = item;
					links.push({
						extras,
						href,
						title,
						type: "link",
					});
				}
			}
		}
		return (
			<li key={index}>
				<DropdownMenu
					altText={item.extras.altText}
					className="nav-item"
					fixedDirection="right"
					image={item.extras.image}
					links={links}
					radioSelected={item.radioSelected}
					t={t}
					title={item.title}
				/>
			</li>
		);
	} else if (item.menu) {
		return (
			<NavTextItem
				index={index}
				item={item}
				key={index}
				onClick={handlers.makeMouseClickNavHandler(index)}
				onMouseEnter={handlers.makeMouseEnterNavHandler(index)}
				onMouseLeave={handlers.handleMouseLeaveNav}
				stateIndex={state.index}
				stateOpen={state.visibility !== "closed"}
				t={t}
			/>
		);
	} else if (item.link) {
		return (
			<li key={index} className="nav-item">
				<a
					target="_blank"
					rel="noreferrer"
					href={item.link!}
					className="ut-align-items-center ut-flex"
				>
					<span style={{ marginRight: "0.3rem" }}>{t(item.title)}</span>
					<img src={OpenNewIcon} alt="Open Analytics Button" style={{ height: "16px" }} />
				</a>
			</li>
		);
	} else {
		const navClass = "nav-item";
		let extras = {} as MenuOptions;
		if (item.extras) {
			extras = JSON.parse(JSON.stringify(item.extras));
		}
		if (!defined(extras.class)) {
			extras.class = navClass;
		} else if (extras.class.indexOf(navClass) === -1) {
			extras.class += ` ${navClass}`;
		}
		return (
			<NavLinkItem
				extras={extras}
				href={withDefault(item.href, "")}
				key={index}
				router={router}
				t={t}
				title={item.title}
			/>
		);
	}
}

function computeSubmenuRect(element: Element): Rect {
	const links = element.querySelectorAll("a");
	let width = 0;
	let height = 16; // margin bottom

	for (let i = 0; i < links.length; i++) {
		let link = links[i];
		width = Math.max(width, link.offsetWidth);
		// Assume the structure is <li><a/></li>
		height += link.parentElement?.offsetHeight || 0;
	}

	return { width, height };
}

interface Rect {
	width: number;
	height: number;
}

interface MenuProps extends React.ClassAttributes<HTMLFormElement> {
	callbacks: Array<() => void>;
	logoSrc: string;
	menu: string;
	router?: boolean;
	t: Translator;
	url: string;
}

interface NavHandlers {
	makeMouseClickNavHandler: (index: number) => () => void;
	makeMouseEnterNavHandler: (index: number) => () => void;
	handleMouseLeaveNav: () => void;
}
