import React from "react";
import { FormHandler } from "../organisms/Form";
import { Input, InputProps } from "./Input";

interface FormInputProps extends InputProps {
	handler: FormHandler;
}

export function FormInput(props: FormInputProps) {
	const name = props.name;
	const id = name + "-" + props.handler.id;

	let value = props.value;
	if (value === undefined) {
		value = props.handler.value(name);
	}

	const message = props.handler.validate(name);
	const required = props.required === undefined ? true : props.required;
	const label = props.label ? props.label : props.handler.label("label." + name);
	const disabled = props.disabled ? props.disabled : props.handler.disabled(name);

	return (
		<Input
			autoComplete={props.autoComplete}
			autoFocus={props.autoFocus}
			disabled={disabled}
			help={props.help}
			icon={props.icon}
			id={id}
			label={label}
			min={props.min}
			max={props.max}
			step={props.step}
			maxLength={props.maxLength}
			message={message}
			name={name}
			onChange={props.handler.onChangeText}
			required={required}
			pattern={props.pattern}
			type={props.type}
			value={value}
		/>
	);
}
