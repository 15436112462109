import React from "react";
import { Container } from "../../grid/Container";
import { MenuObject, MobileMenu, TopMenu } from "../../hooks/useMenu";
import { DropdownMenu } from "../../molecules/DropdownMenu";
import { SocialMedia } from "../../molecules/SocialMedia";
import { MenuNavList } from "./Navs";

export const MobileNav: React.FC<MobileNavProps> = ({
	t,
	inner,
	menu,
	onToggle,
	onClickLink,
	open,
	socialIcons,
	staticMenu,
	router,
}) => {
	const menuLength = Object.keys(menu).length;

	return (
		<div id="menu-foldout-nav-mobile">
			<Container>
				<MobileButton onClick={onToggle} open={open} />
				{inner.map((item, index) => {
					if (!item.extras || !item.extras.dropdown) return <></>;

					const links = [];
					if (item.menu !== undefined) {
						for (const menu of item.menu) {
							for (const item of menu) {
								const [title, href, extras] = item;
								links.push({
									extras,
									href,
									title,
									type: "link",
								});
							}
						}
					}
					return (
						<DropdownMenu
							key={index}
							className="nav-item-inner"
							links={links}
							t={t}
							title={item.title}
							radioSelected={item.radioSelected}
						/>
					);
				})}

				<ul className="mobile-columns">
					{Object.keys(menu).map((key, index) => {
						return (
							<li
								className={"mobile-group" + (menuLength === 1 ? " mod-one" : "")}
								key={key}
							>
								{t(key)}
								<MenuNavList
									icon={false}
									isMobile={true}
									links={menu[key]}
									t={t}
									router={router}
									onSoftNav={onClickLink}
								/>
							</li>
						);
					})}
				</ul>
				{staticMenu.length > 0 && (
					<MenuNavList
						className="mobile-static"
						icon={false}
						isMobile={true}
						links={staticMenu}
						t={t}
						router={router}
						onSoftNav={onClickLink}
					/>
				)}
				{socialIcons && <SocialMedia account="configura" className="mobile-social" t={t} />}
			</Container>
		</div>
	);
};

export const MobileButton: React.FC<MobileButtonProps> = ({ onClick, open }) => {
	return (
		<button
			aria-label="Menu"
			className="nav-item mod-mobile-button"
			onClick={onClick}
			type="button"
		>
			<i className={"svg-icon mod-black-svg " + (open ? "mod-cross" : "mod-menu")} />
		</button>
	);
};

export interface MobileNavProps {
	onToggle: React.MouseEventHandler<HTMLButtonElement>;
	menu: MobileMenu;
	open: boolean;
	socialIcons: boolean;
	staticMenu: MenuObject[];
	t: Translator;
	inner: TopMenu[];
	onClickLink?: () => void;
	router?: boolean;
}

export interface MobileButtonProps {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	open: boolean;
}
