import { Form, FormButton, FormInput } from "components";
import React from "react";

export class SubscribeForm extends Form {
	render() {
		const t = this.props.t;

		return (
			<form id="subscribe-form" action={this.props.url} method="POST">
				<FormInput label={t("footer.email")} name="EMAIL" handler={this} type="email" />
				<input
					readOnly
					type="hidden"
					value="1"
					name="group[9597][1]"
					id="mce-group[9597]-9597-0"
				/>
				<input
					readOnly
					type="hidden"
					value="2"
					name="group[9597][2]"
					id="mce-group[9597]-9597-1"
				/>
				<input
					readOnly
					type="hidden"
					value="4"
					name="group[9597][4]"
					id="mce-group[9597]-9597-2"
				/>
				<input
					readOnly
					type="hidden"
					value={window.location.pathname}
					name="SOURCE"
					id="SOURCE"
				/>
				<div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
					<input
						name="b_274cbd31f2421263cc2d550eb_0bc0c64c32"
						tabIndex={-1}
						type="text"
						defaultValue=""
					/>
				</div>
				<FormButton
					color="gray"
					handler={this}
					icon="mod-send mod-white"
					text=""
					type="submit"
				/>
			</form>
		);
	}
}
