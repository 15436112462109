import { useEffect } from "react";

export function useBodyClasses(classes: string[]) {
	useEffect(() => {
		document.body.classList.add(...classes);

		return () => {
			document.body.classList.remove(...classes);
		};
	}, classes); // eslint-disable-line react-hooks/exhaustive-deps
}
