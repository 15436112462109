import React from "react";

interface Props {
	accept?: string;
	disabled: boolean;
	id: string;
	multiple?: boolean;
	name: string;
	onChange: (files: FileList | null) => void;
}

interface State {
	dragging: boolean;
}

export class FileInput extends React.Component<Props, State> {
	constructor(props: Readonly<Props>, context?: any) {
		super(props, context);
		this.state = {
			dragging: false,
		};
	}

	onDragOver = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (!this.state.dragging) {
			e.dataTransfer.dropEffect = "copy";
			const types = e.dataTransfer.types;
			for (const type of types) {
				if (type === "Files") {
					this.setState({ dragging: true });
					return;
				}
			}
		}
	};

	onDragLeave = (e: React.DragEvent<HTMLInputElement>) => {
		const x = e.clientX;
		const y = e.clientY;
		const rect = e.currentTarget.getBoundingClientRect();
		if (x < rect.left || x > rect.right || y < rect.top || y > rect.bottom) {
			this.setState({ dragging: false });
		}
	};

	onDrop = (e: React.DragEvent<HTMLInputElement>) => {
		e.preventDefault();
		this.setState({ dragging: false });
		this.props.onChange(e.dataTransfer.files);
	};

	onChange = (event: React.FormEvent<HTMLInputElement>) => {
		this.props.onChange(event.currentTarget.files);
		if (event.currentTarget.value !== "") {
			event.currentTarget.value = "";
		}
	};

	render() {
		const { dragging } = this.state;
		const { name, disabled, id, accept, multiple } = this.props;

		let formClass = "form-upload";
		let labelClass = "button";
		if (disabled) {
			formClass += " disabled";
			labelClass += " disabled";
		}

		let inputClass = "";
		if (dragging) {
			formClass += " dragging";
			inputClass += " dragging";
		}
		return (
			<div className={formClass}>
				<input
					className={inputClass}
					accept={accept}
					disabled={disabled}
					id={id}
					name={name}
					multiple={multiple}
					onChange={this.onChange}
					onDragLeave={this.onDragLeave}
					onDragOver={this.onDragOver}
					onDrop={this.onDrop}
					type="file"
				/>
				<i className="svg-icon mod-upload mod-xl" />
				<div>
					{dragging ? "Drop" : "Drag"} your {multiple ? "files" : "file"} here
				</div>
				<div>or</div>
				<label className={labelClass} htmlFor={id}>
					Browse
				</label>
			</div>
		);
	}
}
