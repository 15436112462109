import { classNames } from "cfg-base";
import React from "react";

interface Props {
	className?: string;
	children: React.ReactNode;
}

export function Container(props: Props) {
	const { children, className } = props;
	const colClass = classNames(["container", className && className]);

	return <div className={colClass}>{children}</div>;
}
