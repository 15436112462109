import { api, urls } from "cfg-base/src";
import { SearchRequest, SearchResponse } from "cfg-base/src/api";
import { SearchEndpoints, SearchTypes } from "cfg-base/src/types/search";
import { APP_URL } from "cfg-base/src/urls";

export const LOG_VISIT = "log-visit";

export const searchUserURL = (uid: number) => {
	return urls.myURL({
		page: "support",
		section: "user",
		uid: uid.toString(),
		logvisit: "true",
	});
};

export const searchCustomerURL = (customerId: number) => {
	return `${APP_URL}/my/internal/support-tool/customer/${customerId.toString()}?${LOG_VISIT}=true`;
};

export const searchLicenseURL = (licenseId: number) => {
	return urls.myURL({
		page: "support",
		section: "license",
		license_id: licenseId.toString(),
		logvisit: "true",
	});
};

export const masqueradeLoginURL = (uid: number) => {
	return urls.apiURL(`auth/start-masquerading/${uid.toString()}`);
};

export const searchInvoiceURL = (invoiceId: number) => {
	return `${urls.getMyConfiguraOrigin()}/invoice/${invoiceId.toString()}?logvisit=true`;
};

export function searchCall<T extends keyof SearchTypes>(
	endpoint: SearchEndpoints[T],
	data: SearchRequest<T>
): Promise<SearchResponse<T> | Error> {
	return api.call(endpoint, data) as any;
}
