import { Alert, LoadingSpinner } from "components";
import React from "react";

type MapToNotLoadingOrError<T> = { [K in keyof T]: NotLoadingOrError<T[K]> };
type NotLoadingOrError<T> = T extends Error ? never : T extends "loading" ? never : T;

interface ApiResultProps<T extends any[]> {
	results: T;
	renderSuccess: (...args: MapToNotLoadingOrError<T>) => React.ReactElement<any, any> | null;
	t: Translator;
	renderLoading?: () => React.ReactElement<any, any> | null;
	renderError?: (e: Error, t: Translator) => React.ReactElement<any, any> | null;
}

export function ApiResult<T1>(props: ApiResultProps<[T1]>): React.ReactElement<any, any> | null;
export function ApiResult<T1, T2>(
	props: ApiResultProps<[T1, T2]>
): React.ReactElement<any, any> | null;
export function ApiResult<T1, T2, T3>(
	props: ApiResultProps<[T1, T2, T3]>
): React.ReactElement<any, any> | null;
export function ApiResult<T1, T2, T3, T4>(
	props: ApiResultProps<[T1, T2, T3, T4]>
): React.ReactElement<any, any> | null;
export function ApiResult<T1, T2, T3, T4, T5>(
	props: ApiResultProps<[T1, T2, T3, T4, T5]>
): React.ReactElement<any, any> | null;
export function ApiResult<T1, T2, T3, T4, T5>(
	props: ApiResultProps<[T1, T2?, T3?, T4?, T5?]>
): React.ReactElement<any, any> | null {
	let {
		renderError = defaultRenderError,
		renderLoading = defaultRenderLoading,
		renderSuccess,
		t,
	} = props;

	let results = props.results as [any, any?, any?, any?, any?]; // hack to please typescript

	for (const res of results) {
		if (res instanceof Error) {
			return renderError(res, t);
		} else if (res === "loading") {
			return renderLoading();
		}
	}

	return renderSuccess(...results);
}

function defaultRenderLoading() {
	return (
		<div className="ut-flex-center">
			<LoadingSpinner color="black" />
		</div>
	);
}

function defaultRenderError(error: Error, t: Translator) {
	return <Alert type="error">{t(error)}</Alert>;
}
