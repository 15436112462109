import React from "react";
import { Button, ButtonBaseProps, ButtonType } from "../atoms/Button";
import { HookFormRequestUpdater } from "./HookForm";

export interface HookFormButtonProps<Request extends HookFormRequestUpdater<any>>
	extends ButtonBaseProps {
	type: ButtonType;
	t: Translator;
	name: string;
	formState: Request;
}

export function HookFormButton<Request extends HookFormRequestUpdater<any>>(
	props: React.PropsWithChildren<HookFormButtonProps<Request>>
): React.ReactElement {
	return (
		<Button
			ariaLabel={props.ariaLabel}
			className={props.className}
			color={props.color}
			disabled={props.disabled || props.formState.loading}
			expand={props.expand}
			icon={props.icon}
			loading={props.loading}
			name={props.name}
			size={props.size}
			t={props.t}
			text={props.text || `button.${props.name}`}
			type={props.type}
			visualType={props.visualType}
		/>
	);
}
