import { generateRandomID, StringKeys } from "cfg-base";
import React, { useCallback } from "react";
import { HookFormRequestUpdater } from "./HookForm";

interface HookFormTextareaProps<
	Request extends HookFormRequestUpdater<any>,
	Name extends StringKeys<Request["values"]> & string
> extends TextareaProps {
	formState: Request;
	name: Name;
	onActiveOrFilledLabelClasses?: string;
	t: Translator;
}

export const HookFormTextarea = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & StringKeys<Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<HookFormTextareaProps<Request, Name>>
): React.ReactElement => {
	const { name, t } = props;

	// here we are using `any` to cast the request into a shape we know it has because we have
	// constrained the type of `Name` to only the keys with `string` type values
	const request = props.formState as any as HookFormRequestUpdater<{ [_ in Name]: string }>;
	const { setValue } = request;

	const onChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
		(e) => setValue(name, e.currentTarget.value),
		[name, setValue]
	);

	const message = request.validation[name];
	const required = props.required === undefined ? true : props.required;

	return (
		<TextArea
			autoFocus={props.autoFocus}
			minHeight={props.minHeight}
			className={props.className}
			onActiveOrFilledLabelClasses={props.onActiveOrFilledLabelClasses}
			disabled={props.disabled || request.loading}
			help={props.help}
			id={`${request.id}-${name}`}
			label={props.label || t("label." + name)}
			maxLength={props.maxLength}
			message={typeof message === "string" ? t(message) : undefined}
			name={name}
			onChange={onChange}
			required={required}
			value={request.values[name]}
		></TextArea>
	);
};

interface TextareaProps {
	autoFocus?: boolean;
	className?: string;
	disabled?: boolean;
	help?: string;
	label?: string;
	maxLength?: number;
	message?: string;
	minHeight?: number;
	name: string;
	required?: boolean;
	value?: string;
	onActiveOrFilledLabelClasses?: string;
}

interface Props extends TextareaProps {
	id?: string;
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

interface State {
	active: boolean;
	forceHasData: boolean;
	id: string;
}

export class TextArea extends React.Component<Props, State> {
	textarea?: HTMLTextAreaElement;

	constructor(props: TextareaProps) {
		super(props);
		this.state = {
			active: false,
			forceHasData: false,
			id: generateRandomID(),
		};
	}

	setTextarea = (element: HTMLTextAreaElement | null) => {
		if (element) {
			this.textarea = element;
		}
	};

	componentDidMount() {
		if (this.textarea) {
			this.textarea.addEventListener("animationstart", this.handleAnimationStart);
		}
	}

	componentWillUnmount() {
		if (this.textarea) {
			this.textarea.removeEventListener("animationstart", this.handleAnimationStart);
		}
	}

	handleAnimationStart = (event: Event) => {
		const animationEvent = event as AnimationEvent;
		switch (animationEvent.animationName) {
			case "onAutoFillStart":
				return this.onAutoFillStart();

			case "onAutoFillEnd":
				return this.onAutoFillEnd();
		}
	};

	onAutoFillEnd = () => {
		this.setState({ forceHasData: false });
	};

	onAutoFillStart = () => {
		this.setState({ forceHasData: true });
	};

	onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		this.setState({ active: true });
	};

	onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		this.setState({ active: false });
	};

	render() {
		const style: React.CSSProperties = {};
		if (this.textarea instanceof HTMLTextAreaElement) {
			let height = this.textarea.offsetHeight;
			if (this.textarea.scrollHeight > height) {
				height = this.textarea.scrollHeight + 2;
			}

			height = Math.max(height, this.props.minHeight || 0);
			style.height = height + "px";
		}

		const props = this.props;
		const name = props.name;
		const id = props.id || this.state.id;

		let value = props.value;
		if (value === undefined) {
			value = "";
		}

		const message = props.message;
		const error = message ? <small className="form-message">{message}</small> : "";
		const help = props.help ? <small>{props.help}</small> : "";

		let fieldClass = "form-field";
		if (error !== "") {
			fieldClass += " mod-error";
		}

		let wrapperClass = "form-textarea-wrapper mod-react";
		if ((value !== undefined && value !== "") || this.state.forceHasData) {
			wrapperClass += " has-data";
		}
		if (this.state.active) {
			wrapperClass += " is-active";
		}

		const onActiveOrFilledLabelClasses =
			this.state.active || value !== "" ? props.onActiveOrFilledLabelClasses : "";

		return (
			<div className={fieldClass}>
				<div className={wrapperClass}>
					<label className={`form-label ${onActiveOrFilledLabelClasses}`} htmlFor={id}>
						{props.label}
					</label>
					<textarea
						autoFocus={props.autoFocus}
						className={`form-textarea mod-no-resize mod-overflow-hidden ${props.className}`}
						disabled={props.disabled}
						id={id}
						maxLength={props.maxLength}
						name={name}
						onBlur={this.onBlur}
						onChange={props.onChange}
						onFocus={this.onFocus}
						ref={this.setTextarea}
						required={props.required}
						style={style}
						value={value}
					/>
				</div>
				{error}
				{help}
			</div>
		);
	}
}
