import { api, isError } from "cfg-base/src";
import { Alert, Col, Container, LoadingSpinner, Row, showOverlay } from "components/src";
import * as React from "react";
import ReactDOM from "react-dom";
import { useInternalSearcher } from "../molecules/InternalSearcher";
import {
	DEFAULT_CET_LICENSES_SEARCHER_CONFIG,
	DEFAULT_CUSTOMER_SEARCHER_CONFIG,
	DEFAULT_INVOICES_SEARCHER_CONFIG,
	DEFAULT_USER_SEARCHER_CONFIG,
} from "../organisms/InternalSearchDefaults";
import {
	InternalSearchFilter,
	InternalSearchInput,
	InternalSearchList,
} from "../organisms/InternalSearcher";
import { RecentSearchList } from "../organisms/RecentSearchList";

interface InternalSearcherOverlayProps {
	t: Translator;
	hidden: boolean;
}

export const InternalSearcherOverlay: React.FC<InternalSearcherOverlayProps> = (props) => {
	const { t } = props;

	const [hidden, setHidden] = React.useState(props.hidden);
	const [user, setUser] = React.useState<Loading<Option<User>>>("loading");
	const [error, setError] = React.useState<Option<Error>>();
	const [searchValueIsEmpty, setSearchValueIsEmpty] = React.useState(true);

	const hiddenClass = hidden ? "mod-hidden" : "";
	const searchBarClass = "";

	const userSearcher = useInternalSearcher(DEFAULT_USER_SEARCHER_CONFIG, t);
	const customerSearcher = useInternalSearcher(DEFAULT_CUSTOMER_SEARCHER_CONFIG, t);
	const invoiceSearcher = useInternalSearcher(DEFAULT_INVOICES_SEARCHER_CONFIG, t);
	const cetLicensesSearcher = useInternalSearcher(DEFAULT_CET_LICENSES_SEARCHER_CONFIG, t);

	React.useEffect(() => {
		async function fetchUser() {
			const result = await api.call("user/get-logged-in");

			if (isError(result)) {
				setUser(undefined);
				setError(result);
				return;
			} else if ("user" in result) {
				setUser(result.user);
			} else {
				setError(new Error("error.user_not_found"));
			}
		}

		fetchUser();
	}, []);

	const hideSearch = React.useCallback(() => {
		setHidden(true);
		document.body.style.overflow = "initial";
		const element = document.getElementById("react-overlay-myconfigura-container");
		if (element) {
			ReactDOM.unmountComponentAtNode(element);
		}
	}, []);

	const wrapper = React.useCallback(
		(children: JSX.Element) => {
			return (
				<div className={"react-search-myconfigura " + hiddenClass}>
					<div className={"search-bar-container" + searchBarClass}>
						<Container>
							<div className="search-header">
								{error !== undefined && <Alert type="error">{t(error)}</Alert>}
								<h1 className="heading-1 heading-1--uppercase search-title">
									Search
								</h1>
								<button className="close-search" onClick={hideSearch}>
									<i className="svg-icon mod-black-svg mod-cross" />
								</button>
							</div>
							{children}
						</Container>
					</div>
				</div>
			);
		},
		[error, hiddenClass, hideSearch, t]
	);

	if (user === "loading") {
		return wrapper(
			<Row className="ut-margin-top-1">
				<Col className="is-12">
					<LoadingSpinner size="lg" color="black" />
				</Col>
			</Row>
		);
	}

	if (user === undefined) {
		return wrapper(
			<Row className="ut-margin-top-1">
				<Col className="is-12">
					<Alert type="error">{t("not_logged_in")}</Alert>
				</Col>
			</Row>
		);
	}

	const internalSearchers = [
		userSearcher,
		customerSearcher,
		invoiceSearcher,
		cetLicensesSearcher,
	];

	return wrapper(
		<React.Fragment>
			<Row className="ut-margin-top-1">
				<Col className="is-12">
					<InternalSearchInput
						setSearchValueIsEmpty={setSearchValueIsEmpty}
						internalSearchers={internalSearchers}
						t={t}
					/>
				</Col>
			</Row>
			{searchValueIsEmpty ? (
				<Row>
					<Col className="is-12">
						<RecentSearchList t={t} />
					</Col>
				</Row>
			) : (
				<React.Fragment>
					<Row className="ut-margin-bottom-1">
						<Col className="is-12">
							<InternalSearchFilter internalSearchers={internalSearchers} t={t} />
						</Col>
					</Row>
					<Row className="ut-margin-bottom-1">
						<Col className="is-12">
							<InternalSearchList
								key={"internal-search-list"}
								internalSearchers={internalSearchers}
								user={user}
								t={t}
							/>
						</Col>
					</Row>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export function showInternalSearcherOverlay(event: React.MouseEvent<HTMLElement>, t: Translator) {
	showOverlay(event, <InternalSearcherOverlay hidden={false} t={t} />);
}
