import React from "react";
import { Link } from "react-router-dom";
import { showInternalSearcherOverlay } from "../../../../myconfigura/src/myconfigura/pages/InternalSearcherOverlay";
import { MenuObject, MenuOptions, TopMenu } from "../../hooks/useMenu";
import { CollaborationMenu } from "../CollaborationMenu";
import { showFeedbackModal } from "../FeedbackForm";

export const MenuNavList: React.FC<MenuNavListProps> = ({
	className,
	links,
	icon,
	isMobile,
	t,
	router,
	onSoftNav,
}) => {
	const filteredLinks = links.filter((l) => {
		const [, , extras] = l;
		if (extras && extras.mobileOnly && !isMobile) {
			return false;
		}
		return true;
	});

	if (filteredLinks.length === 0) {
		return null;
	}

	return (
		<ul className={className}>
			{filteredLinks.map((link, index) => {
				const [title, href, extras] = link;
				const isRouter = !!extras?.router && !!router;

				return (
					<React.Fragment key={index}>
						<NavLinkItem
							extras={extras || {}}
							href={href}
							icon={icon}
							t={t}
							title={title}
							router={isRouter}
							onClick={onSoftNav}
						/>
						{extras?.addCollaborationProjects && (
							<CollaborationMenu t={t} onNavigate={onSoftNav} />
						)}
					</React.Fragment>
				);
			})}
		</ul>
	);
};

export const NavTextItem: React.FC<NavTextItemProps> = ({
	index,
	item,
	onClick,
	onMouseEnter,
	onMouseLeave,
	stateIndex,
	stateOpen,
	t,
}) => {
	let className = "nav-item";
	if (stateOpen && stateIndex === index) {
		className += " is-active";
	}

	return (
		<li
			className={className}
			data-index={index}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{t(item.title)}
		</li>
	);
};

export function NavLinkItem(props: {
	extras: MenuOptions;
	href: string;
	icon?: boolean;
	router?: boolean;
	t: Translator;
	title: string;
	onClick?: () => void;
}) {
	const { extras, href, t, title } = props;
	const router = !!extras.router && props.router;

	let useIcon = props.icon;
	if (useIcon == null) {
		useIcon = true;
	}
	const onClick = (extras.onClick && getOnClick(extras.onClick, t)) || props.onClick;

	const dataProps: MenuOptions = {};
	if (extras["data-bits"]) {
		dataProps["data-bits"] = extras["data-bits"];
	}

	if (extras["data-popup"]) {
		dataProps["data-popup"] = extras["data-popup"];
	}

	const Tag: React.ElementType = router ? Link : "a";
	const TagProps = {
		className: extras.class,
		onClick,
		...(!router && { href }),
		...(router && { to: relativeUrl(href) }),
	};

	return (
		<li>
			<Tag {...TagProps}>
				{extras.icon && useIcon && <i className={"svg-icon " + extras.icon} />}
				{title ? t(title) : title}
			</Tag>
		</li>
	);
}

function getOnClick(key: string, t: Translator) {
	switch (key) {
		case "feedback":
			return (event: React.MouseEvent<HTMLElement>) => {
				showFeedbackModal(event, t);
			};
		case "search":
			return (event: React.MouseEvent<HTMLElement>) => {
				showInternalSearcherOverlay(event, t);
			};
		default:
			return () => {};
	}
}

function relativeUrl(url: string) {
	// FIXME: This hack is to support legacy pages still needing the full url
	// Note: Look behind regex is not supported in safari
	let match = url.match(/^(?:(?:https?)?:\/\/[^/]+)?(\/.*)/);
	return match?.pop() || "/";
}

export interface MenuNavListProps {
	className?: string;
	icon?: boolean;
	isMobile?: boolean;
	links: MenuObject[];
	t: Translator;
	router?: boolean;
	onSoftNav?: () => void;
}

export interface NavTextItemProps {
	index: number;
	item: TopMenu;
	onClick: React.MouseEventHandler<HTMLLIElement>;
	onMouseEnter: React.MouseEventHandler<HTMLLIElement>;
	onMouseLeave: React.MouseEventHandler<HTMLLIElement>;
	stateIndex: number;
	stateOpen: boolean;
	t: Translator;
}

export interface NavLinkItemProps {
	extras: MenuOptions;
	href: string;
	icon?: boolean;
	router?: boolean;
	t: Translator;
	title: string;
	onClick?: () => void;
}
