import { cookies, urls } from "cfg-base";
import jstz from "jstz";

function ensureTimezoneCookie() {
	const offsetCookie = urls.isDevDomain() ? `${cookies.NAMES.TIMEZONE_OFFSET}-dev` : cookies.NAMES.TIMEZONE_OFFSET;
	const timezoneCookie = urls.isDevDomain() ? `${cookies.NAMES.TIMEZONE}-dev` : cookies.NAMES.TIMEZONE;

	const timezoneOffset = -new Date().getTimezoneOffset();
	const oldOffset = cookies.get(offsetCookie);

	if (oldOffset === undefined || oldOffset === "" || timezoneOffset !== parseInt(oldOffset, 10)) {
		cookies.set(offsetCookie, timezoneOffset, 365, urls.getCookieDomain());
	}

	const oldTimezone = cookies.get(timezoneCookie);
	const timezone = jstz.determine();
	if (oldTimezone === "" || timezone.name() !== oldTimezone) {
		cookies.set(timezoneCookie, timezone.name(), 365, urls.getCookieDomain());
	}
}

function getTimezone() {
	const timezoneCookie = urls.isDevDomain() ? `${cookies.NAMES.TIMEZONE}-dev` : cookies.NAMES.TIMEZONE;
	const timezone = cookies.get(timezoneCookie);

	if (timezone) {
		return timezone;
	}
}

export { ensureTimezoneCookie, getTimezone };
