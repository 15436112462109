import { subscribeSqlInfo, unsubscribeSqlInfo } from "cfg-base/src/debug";
import { useEffect, useState } from "react";

export function useSqlLogs(): SQLInfo[] {
	const [sqlInfo, setSqlInfo] = useState<SQLInfo[]>([]);

	useEffect(() => {
		let unmounted = false;

		const listener = (info: SQLInfo) => {
			if (!unmounted) {
				setSqlInfo((prev) => [...prev, info]);
			}
		};

		if (process.env.NODE_ENV !== "production") {
			subscribeSqlInfo(listener);
		}

		return () => {
			if (process.env.NODE_ENV !== "production") {
				unsubscribeSqlInfo(listener);
			}
			unmounted = true;
		};
	}, []);

	return sqlInfo;
}

export interface SQLInfo {
	ajax: boolean;
	logs: Array<{
		totalexecutiontime: number;
		numbers: number;
	}>;
}
