import { api, urls } from "cfg-base";
import React from "react";
import { Button } from "../../atoms/Button";
import { Container } from "../../grid/Container";
import { NotificationType } from "../../hooks/useMenu";
import { Alert } from "../../molecules/Alert";

const CONFIGURA_URL = urls.getConfiguraOrigin();

export function NotificationContainer(props: NotificationContainerProps) {
	if (props.notification === "policy") {
		return (
			<Alert
				type="info"
				size="sm"
				onClose={() => {
					props.setNotification();
					api.call("myconfigura/accept-policy");
					props.clearCache();
				}}
			>
				<Container>
					With the General Data Protection Regulation (GDPR) coming into force on May 25,
					2018, we've updated our{" "}
					<a
						className="link link--hover"
						href={`${CONFIGURA_URL}/privacy-notice`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Notice
					</a>
					,{" "}
					<a
						className="link link--hover"
						href={`${CONFIGURA_URL}/legal-terms`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Legal Terms
					</a>{" "}
					and{" "}
					<a
						className="link link--hover"
						href={`${CONFIGURA_URL}/terms-of-use`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Terms of Use
					</a>
					. We encourage that you take some time to read our updated terms.
				</Container>
			</Alert>
		);
	} else if (props.notification === "CLA") {
		return (
			<Alert type="info" size="sm">
				<Container>
					Please note that we made minor adjustments to the Configura License Agreement.
					The changes regard the license renewal process and clarifications on our
					customer services. You do not need to sign the CLA again.
					<div className="ut-margin-top-1">
						<Button
							onClick={() => {
								props.setNotification();
								api.call("customer/agreements/update-cla");
								props.clearCache();
							}}
							text={"I understand"}
							className="ut-margin-right-1"
						/>
						<a
							className="link link--hover"
							href={urls.LICENSE_AGREEMENT_URL}
							target="_blank"
							rel="noopener noreferrer"
						>
							Read the updated CLA
						</a>
					</div>
				</Container>
			</Alert>
		);
	}

	return <></>;
}

interface NotificationContainerProps {
	clearCache: () => void;
	notification: NotificationType;
	setNotification: (notification?: NotificationType) => void;
}
