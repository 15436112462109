import { classNames } from "cfg-base";
import React from "react";
import { Portal } from "../atoms/Portal";

interface Props {
	active?: boolean;
	children?: React.ReactNode;
}

interface State {}

const BODY_CLASS = "details-card-present";
const BODY_CLASS_ACTIVE = "details-card-present-active";

export class DetailsCard extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		if (this.props.active) {
			document.body.classList.add(BODY_CLASS_ACTIVE);
		} else {
			document.body.classList.remove(BODY_CLASS_ACTIVE);
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
		if (prevProps.active !== this.props.active) {
			if (this.props.active) {
				document.body.classList.add(BODY_CLASS_ACTIVE);
			} else {
				document.body.classList.remove(BODY_CLASS_ACTIVE);
			}
		}
	}

	componentDidMount(): void {
		document.body.classList.add(BODY_CLASS);
		if (this.props.active) {
			document.body.classList.add(BODY_CLASS_ACTIVE);
		}
	}

	componentWillUnmount(): void {
		document.body.classList.remove(BODY_CLASS);
		document.body.classList.remove(BODY_CLASS_ACTIVE);
	}

	render() {
		const classes = classNames(["details-card", this.props.active && "active"]);

		return (
			<Portal>
				<div className={classes}>{this.props.children}</div>
			</Portal>
		);
	}
}
