// tslint:disable:no-console forin max-classes-per-file
import { debug, en } from "cfg-base";
import { BasicModal, DebugModal, showModal } from "components";
import * as React from "react";

function showDebugModal(data: debug.DebugData) {
	showModal(
		<BasicModal
			title={data.error}
			removeOnClose={true}
			className="debug"
			t={en}
			fullsize={true}
		>
			<DebugModal data={data} t={en} />
		</BasicModal>,
		"react-debug-modal"
	);
}

export function setupDebug() {
	debug.registerDebugHandler(showDebugModal);
}
