import { classNames, generateRandomID } from "cfg-base";
import React from "react";

export interface InputProps {
	autoComplete?: "on" | "off";
	autoFocus?: boolean;
	disabled?: boolean;
	fieldClass?: string;
	help?: string;
	icon?: string;
	label?: string;
	min?: string | number;
	max?: string | number;
	step?: string | number;
	maxLength?: number;
	message?: string;
	preventMessageSubmit?: boolean;
	name: string;
	required?: boolean;
	type: "text" | "email" | "number" | "password" | "date" | "time" | "range";
	value?: string;
	noValidate?: boolean;
	pattern?: string;
	inputMode?: "decimal" | "email" | "none" | "numeric" | "search" | "tel" | "text" | "url";
	errorMessage?: string;
}

interface Props extends InputProps {
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
	onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
	onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
	onMouseUp?: React.MouseEventHandler<HTMLInputElement>;
	id?: string;
}

interface State {
	active: boolean;
	forceHasData: boolean;
	id: string;
}

export class Input extends React.PureComponent<Props, State> {
	input?: HTMLInputElement;

	constructor(props: Props) {
		super(props);

		this.state = {
			active: false,
			forceHasData: false,
			id: generateRandomID(),
		};
	}

	componentDidMount() {
		if (this.input) {
			this.input.addEventListener("animationstart", this.handleAnimationStart);

			if (this.props.errorMessage) {
				const validityState = this.input.validity;

				if (validityState.valueMissing) {
					this.input.setCustomValidity(this.props.errorMessage);
				}

				this.input.reportValidity();
			}
		}
	}

	componentDidUpdate(prev: Readonly<Props>) {
		if (
			this.input &&
			this.props.errorMessage &&
			prev.errorMessage === this.props.errorMessage
		) {
			const validityState = this.input.validity;

			if (validityState.valueMissing) {
				this.input.setCustomValidity(this.props.errorMessage);
			}

			this.input.reportValidity();
		}
	}

	componentWillUnmount() {
		if (this.input) {
			this.input.removeEventListener("animationstart", this.handleAnimationStart);
		}
	}

	handleAnimationStart = (event: Event) => {
		const animationEvent = event as AnimationEvent;
		switch (animationEvent.animationName) {
			case "onAutoFillStart":
				return this.onAutoFillStart();

			case "onAutoFillEnd":
				return this.onAutoFillEnd();
		}
	};

	onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		this.setState({ active: true });
	};

	onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		this.setState({ active: false });
		this.props.onBlur?.(event);
	};

	onAutoFillEnd = () => {
		this.setState({ forceHasData: false });
	};

	onAutoFillStart = () => {
		this.setState({ forceHasData: true });
	};

	setInput = (element: HTMLInputElement | null) => {
		if (element !== null) {
			this.input = element;
		}
	};

	render() {
		const { message, preventMessageSubmit, help, icon, type, pattern } = this.props;
		const id = this.props.id || this.state.id;

		let value = this.props.value;
		if (value === undefined) {
			value = "";
		}

		this.input?.setCustomValidity(preventMessageSubmit && message ? message : "");

		const fieldClass = classNames([
			"form-field",
			message && "mod-error",
			this.props.fieldClass && this.props.fieldClass,
		]);

		let wrapperClass = "form-input-wrapper";
		if (value || type === "date" || type === "time" || this.state.forceHasData) {
			wrapperClass += " has-data";
		}

		if (this.state.active) {
			wrapperClass += " is-active";
		}

		let i;
		if (icon) {
			wrapperClass += " mod-icon";
			i = <i className={"svg-icon " + icon} />;
		}

		return (
			<div className={fieldClass}>
				<div className={wrapperClass}>
					{i}
					<label className="form-label" htmlFor={id}>
						{this.props.label}
					</label>
					<input
						autoComplete={this.props.autoComplete || this.props.name}
						autoFocus={this.props.autoFocus}
						className="form-input"
						disabled={this.props.disabled}
						id={id}
						min={this.props.min}
						max={this.props.max}
						step={this.props.step}
						maxLength={this.props.maxLength}
						name={this.props.name}
						onBlur={this.onBlur}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
						onKeyUp={this.props.onKeyUp}
						onKeyPress={this.props.onKeyPress}
						onKeyDown={this.props.onKeyDown}
						onMouseUp={this.props.onMouseUp}
						ref={this.setInput}
						required={this.props.required}
						formNoValidate={this.props.noValidate}
						type={type}
						value={value}
						pattern={pattern}
						inputMode={this.props.inputMode}
					/>
				</div>
				{message && <small className="form-message">{message}</small>}
				{help && <small>{help}</small>}
			</div>
		);
	}
}
