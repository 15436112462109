import React, { useEffect, useState } from "react";
import { Input } from "../atoms/Input";

interface Props<T> {
	autoFocus?: boolean;
	className?: string;
	data: T[];
	disableLowerCase?: boolean;
	help?: string;
	label?: string;
	icon?: string;
	t: Translator;

	dataCallback: (filtered: Array<Readonly<T>>) => void;
	filterCallback?: (value: string, item: Readonly<T>) => boolean;
	mapCallback?: (value: string, item: Readonly<T>) => T;
}

export function FilterInput<T>(props: Props<T>) {
	const {
		autoFocus,
		className,
		data,
		disableLowerCase,
		help,
		label,
		icon,
		t,
		dataCallback,
		filterCallback,
		mapCallback,
	} = props;
	const [value, setValue] = useState("");

	function filter() {
		let tmpValue = value;
		if (!disableLowerCase) {
			tmpValue = tmpValue.toLocaleLowerCase();
		}

		let tmpData = data;
		if (filterCallback !== undefined) {
			tmpData = tmpData.filter((item) => filterCallback(tmpValue, item));
		}

		if (mapCallback !== undefined) {
			tmpData = tmpData.map((item) => mapCallback(tmpValue, item));
		}

		dataCallback(tmpData);
	}

	useEffect(() => {
		filter();
	}, [value, data]);

	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		const value = e.currentTarget.value;
		setValue(value);
	}

	return (
		<Input
			autoFocus={autoFocus}
			fieldClass={className}
			help={help || t("label.filter_help")}
			label={label || t("label.filter")}
			name="value"
			onChange={handleChange}
			type="text"
			value={value}
			icon={icon}
		/>
	);
}
