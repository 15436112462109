import React from "react";
import { FormHandler } from "../organisms/Form";

interface Props {
	className?: string;
	disabled?: boolean;
	handler: FormHandler;
	helpText?: string;
	label?: string;
	minHeight?: number;
	name: string;
	required?: boolean;
	value?: string;
}

interface State {
	active: boolean;
	forceHasData: boolean;
}

export class Textarea extends React.Component<Props, State> {
	textarea?: HTMLTextAreaElement;
	state: Readonly<State> = {
		active: false,
		forceHasData: false,
	};

	setTextarea = (element: HTMLTextAreaElement | null) => {
		if (element) {
			this.textarea = element;
		}
	};

	componentDidMount() {
		if (this.textarea) {
			this.textarea.addEventListener("animationstart", this.handleAnimationStart);
		}
	}

	componentWillUnmount() {
		if (this.textarea) {
			this.textarea.removeEventListener("animationstart", this.handleAnimationStart);
		}
	}

	handleAnimationStart = (event: Event) => {
		const animationEvent = event as AnimationEvent;
		switch (animationEvent.animationName) {
			case "onAutoFillStart":
				return this.onAutoFillStart();

			case "onAutoFillEnd":
				return this.onAutoFillEnd();
		}
	};

	onAutoFillEnd = () => {
		this.setState({ forceHasData: false });
	};

	onAutoFillStart = () => {
		this.setState({ forceHasData: true });
	};

	onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		this.setState({ active: true });
	};

	onBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		this.setState({ active: false });
	};

	render() {
		const style: React.CSSProperties = {};
		if (this.textarea instanceof HTMLTextAreaElement) {
			let height = this.textarea.scrollHeight + 2;
			const minHeight = this.props.minHeight;
			if (minHeight && height < minHeight) {
				height = minHeight;
			}

			style.height = height + "px";
		}

		const props = this.props;
		const name = props.name;
		const id = name + "-" + props.handler.id;

		let value = props.value;
		if (value === undefined) {
			value = props.handler.value(name);
		}
		if (value === undefined) {
			value = "";
		}

		const disabled =
			props.disabled === undefined ? props.handler.disabled(name) : props.disabled;
		const label =
			props.label === undefined ? props.handler.label("label." + name) : props.label;
		const required = props.required === undefined ? true : props.required;

		const message = props.handler.validate(name);
		const error = message ? <small className="form-message">{message}</small> : "";
		const help = props.helpText ? <small>{props.helpText}</small> : "";

		let fieldClass = "form-field";
		if (error !== "") {
			fieldClass += " mod-error";
		}

		let wrapperClass = "form-textarea-wrapper mod-react";
		if ((value !== undefined && value !== "") || this.state.forceHasData) {
			wrapperClass += " has-data";
		}
		if (this.state.active) {
			wrapperClass += " is-active";
		}

		return (
			<div className={fieldClass}>
				<div className={wrapperClass}>
					<label className="form-label" htmlFor={id}>
						{label}
					</label>
					<textarea
						className={`form-textarea mod-no-resize mod-overflow-hidden ${props.className}`}
						disabled={disabled}
						id={id}
						name={name}
						onBlur={this.onBlur}
						onChange={props.handler.onChangeText}
						onFocus={this.onFocus}
						ref={this.setTextarea}
						required={required}
						style={style}
						value={value}
					/>
				</div>
				{error}
				{help}
			</div>
		);
	}
}
