/*********************************************************
 * Dom ready
 *********************************************************/

export function domReady(callback: () => void) {
	document.readyState === "interactive" || document.readyState === "complete"
		? callback()
		: document.addEventListener("DOMContentLoaded", callback);
}

/*********************************************************
 * Attribute
 *********************************************************/
export function dataAttr(element: Element, name: string, defaultValue = "") {
	return element.getAttribute("data-" + name) || defaultValue;
}

/*********************************************************
 * continent
 *********************************************************/

export function guessContinent() {
	const timezoneOffset = new Date().getTimezoneOffset();

	let timeZone = "US";
	if (timezoneOffset <= -180) {
		timeZone = "AS";
	} else if (timezoneOffset <= 0) {
		timeZone = "EU";
	}

	return timeZone;
}
