import { classNames, withDefault } from "cfg-base";
import React from "react";

export type ButtonType = "button" | "submit" | "reset" | { submit: { form: string } };

export interface ButtonBaseProps {
	ariaLabel?: string;
	autoFocus?: boolean;
	className?: string;
	color?: "gray" | "blue" | "green" | "orange" | "turquoise" | "yellow" | "instant";
	disabled?: boolean;
	expand?: boolean;
	ghost?: boolean;
	icon?: string;
	title?: string;
	loading?: boolean;
	name?: string;
	size?: "xs" | "sm" | "lg" | "xl" | "xxl";
	t?: Translator;
	text?: React.ReactElement | string;
	type?: ButtonType;
	visualType?: "danger" | "flat" | "primary";
}

interface Props extends ButtonBaseProps {
	text: React.ReactElement | string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function Button(props: Props) {
	const disabled = withDefault(props.disabled, false);
	const loading = withDefault(props.loading, false);
	let text = props.text;
	let title = props.title;

	if (props.t) {
		text = typeof text === "string" ? props.t(text) : text;
		title = title && props.t(title);
	}

	const classes = classNames([
		"button",
		props.className && props.className,
		loading && "is-loading",
		props.expand && "mod-expand",
		props.ghost && "mod-ghost",
		props.color && "mod-" + props.color,
		props.size && "mod-" + props.size,
		props.visualType && "mod-" + props.visualType,
		props.icon && props.text === "" && "mod-only-icon",
	]);

	let icon;
	if (props.icon) {
		icon = <i className={"svg-icon " + props.icon} />;
	}

	const type = typeof props.type === "object" ? "submit" : withDefault(props.type, "button");

	return (
		<button
			aria-label={props.ariaLabel}
			onClick={props.onClick}
			className={classes}
			disabled={disabled}
			name={props.name}
			type={type}
			autoFocus={props.autoFocus}
			title={title}
			form={typeof props.type === "object" ? props.type.submit.form : undefined}
		>
			{icon}
			{text}
		</button>
	);
}
