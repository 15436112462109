import { api } from "cfg-base";
import { useEffect, useState } from "react";

/**
 * @deprecated This hook is deprecated and will be removed in the future. Use useApi instead.
 */
export default function useApiCall<K extends api.Endpoint, T extends {}>(
	endpoint: K,
	request?: T,
	skipDebug?: boolean
) {
	const [response, setResponse] = useState<Loading<api.Responses[K] | Error>>("loading");

	useEffect(() => {
		let cancelled = false;

		setResponse("loading");

		api.call(endpoint, request, skipDebug).then((response) => {
			if (cancelled) {
				return;
			}
			setResponse(response);
		});

		return () => {
			cancelled = true;
		};
	}, [endpoint, request]);

	return response;
}
