// tslint:disable:no-console forin max-classes-per-file
import { debug } from "cfg-base";
import { DebugDict } from "cfg-base/src/debug";
import * as React from "react";
import { Button } from "../atoms/Button";

class StackFrame extends React.Component<
	{ frame: debug.StackFrameData; t: Translator },
	{ showArgs: boolean }
> {
	state = { showArgs: false };

	toggleArgs = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		this.setState({ showArgs: !this.state.showArgs });
	};

	render() {
		const { frame, t } = this.props;
		const pre = frame.pre_context || [];
		const post = frame.post_context || [];
		const showArgs = this.state.showArgs;
		return (
			<div className="stackframe">
				<p>
					in <strong>{frame.filename}</strong> at <strong>{frame.caller}</strong> line{" "}
					<strong>{frame.line}</strong>
				</p>
				<pre className="context">
					{pre[0]}
					{pre[1]}
					<strong>{frame.context_line}</strong>
					{post[0]}
					{post[1]}
				</pre>
				{frame.args && (
					<Button
						onClick={this.toggleArgs}
						size="sm"
						t={t}
						text={t(showArgs ? "button.hide_arguments" : "button.show_arguments")}
					/>
				)}
				{showArgs && <pre className="context">{frame.args}</pre>}
			</div>
		);
	}
}

// tslint:disable-next-line:max-classes-per-file
export class DebugModal extends React.Component<
	{ data: debug.DebugData; t: Translator },
	{ showAll: boolean }
> {
	render() {
		const { data, t } = this.props;

		const note = data.note;
		const note_data = data.note_data;
		const culprit = data.culprit;
		const level = data.level;

		const user = data.user;
		const request = data.request;
		const env = data.env;
		const env_variables = data.env_variables;

		let frames = data.stack_trace || [];

		return (
			<>
				{culprit}
				<>
					<h2>{level}</h2>
					<pre className="context">{note.trim()}</pre>
				</>
				{note_data && (
					<>
						<h2>Data</h2>
						<pre className="context">{note_data}</pre>
					</>
				)}
				{frames.length > 0 && (
					<>
						<h2>Stacktrace</h2>
						{frames.map((frame, i) => (
							<StackFrame frame={frame} key={i} t={t} />
						))}
					</>
				)}
				<DebugTable title="User" values={user} />
				<DebugTable title="Request" values={request} />
				<DebugTable title="Environment" values={env} />
				<DebugTable title="Variables" values={env_variables} />
			</>
		);
	}
}

interface DebugTableProps {
	title: string;
	values?: DebugDict;
}
export function DebugTable(props: DebugTableProps) {
	const { title, values } = props;
	if (!values) {
		return <></>;
	}

	return (
		<>
			<h2>{title}</h2>
			<table className="table mod-striped">
				<tbody>
					{Object.entries(values).map(([key, value]) => {
						let val;
						if (!!value && typeof value === "object") {
							val = value.map(function (v, i) {
								return <p key={i}>{v}</p>;
							});
						} else {
							val = value;
						}
						return (
							<tr key={key}>
								<td>{key}</td>
								<td>{val}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
