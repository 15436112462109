import React from "react";
import { HookFormButton, HookFormTextarea } from "..";
import { Alert } from "../molecules/Alert";
import { HookForm, useHookFormState } from "./HookForm";
import { HookFormRadioInput } from "./HookFormRadioInput";
import { BasicModal, showModal } from "./Modal";

interface Props {
	t: Translator;
}

const FeedbackForm: React.FC<Props> = ({ t }) => {
	const hookFormState = useHookFormState("myconfigura/feedback", {
		good_or_bad: "",
		what_happened: "",
	});

	const { response } = hookFormState;

	if (response?.success) {
		return (
			<Alert type="success">
				<strong className="alert-text">{t(response.success)}</strong>
			</Alert>
		);
	}

	return (
		<>
			<p>
				Tell us about your experience using MyConfigura. We'd like to know, good or bad. How
				would you rate it?
			</p>
			{response?.error && <Alert type="error">{t(response.error)}</Alert>}
			<HookForm formState={hookFormState}>
				<HookFormRadioInput
					formState={hookFormState}
					t={t}
					name="good_or_bad"
					value="good"
					label="I like it"
					required
				/>
				<HookFormRadioInput
					formState={hookFormState}
					t={t}
					name="good_or_bad"
					value="bad"
					label="Not so much"
					required
				/>
				<HookFormTextarea t={t} formState={hookFormState} name="what_happened" />
				<div className="form-controls">
					<HookFormButton
						formState={hookFormState}
						color="blue"
						type="submit"
						name="send"
						t={t}
					/>
				</div>
			</HookForm>
		</>
	);
};

export function showFeedbackModal(event: React.MouseEvent<HTMLElement>, t: Translator) {
	event.preventDefault();

	showModal(
		<BasicModal className="phoenix-forms" title={"leave_feedback"} t={t} removeOnClose={true}>
			<FeedbackForm t={t} />
		</BasicModal>,
		"react-feedback-modal"
	);
}
