import {
	datetime,
	getDecimalSeparator,
	isOk,
	StringKeys,
	TranslatableError,
	TypedKeys,
} from "cfg-base";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, InputProps } from "../atoms/Input";
import { HookFormRequestUpdater } from "./HookForm";
export interface Converter<T, V> {
	into(value: T): V;
	from(value: V): Result<T>;
}

export interface HookFormInputProps<
	T,
	Request extends HookFormRequestUpdater<any>,
	Name extends TypedKeys<T, Request["values"]> & string
> extends InputProps {
	formState: Request;
	name: Name;
	t: Translator;
	converter: Converter<T, string>;
	mask?: (input: string) => string | undefined;
	onChange?: (value: T) => void;
}

export const HookFormInput = <
	T,
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<T, Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<HookFormInputProps<T, Request, Name>>
): React.ReactElement => {
	const { name, converter, mask, t } = props;

	// here we are using `any` to cast the request into a shape we know it has because we have
	// constrained the type of `Name` to only the keys with `string` type values
	const request = props.formState as any as HookFormRequestUpdater<{ [_ in Name]: T }>;
	const setFormValue = request.setValue;

	const [value, setValue] = useState<string>(() => converter.into(request.values[name]));
	const [parsed, setParsed] = useState<Result<T>>(() => request.values[name]);

	useEffect(() => {
		// Allow alternate text as the display string. E.g. let user type "1e2" and not letting it reformat to "100"
		if (parsed === request.values[name]) {
			return;
		}

		setValue(converter.into(request.values[name]));
		setParsed(request.values[name]);
	}, [request.values[name], converter]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (parsed !== request.values[name] && isOk(parsed)) {
			setFormValue(name, parsed);
		}
	}, [parsed]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			const input = mask ? mask(e.target.value) : e.target.value;
			if (input === undefined) {
				return;
			}
			const convertedValue = converter.from(input);
			if (props.onChange && isOk(convertedValue)) props.onChange(convertedValue);
			setValue(input);
			setParsed(convertedValue);
		},
		[converter, mask]
	);

	const required = props.required === undefined ? true : props.required;
	const validation = request.validation[name];
	const message =
		(typeof validation === "string" && t(validation)) ||
		(parsed instanceof Error && t(parsed)) ||
		undefined;
	const preventMessageSubmit = parsed instanceof Error;

	return (
		<Input
			name={name}
			value={value}
			onChange={onChange}
			autoComplete={props.autoComplete}
			autoFocus={props.autoFocus}
			disabled={props.disabled || request.loading}
			fieldClass={props.fieldClass}
			help={props.help}
			icon={props.icon}
			id={`${request.id}-${name}`}
			label={props.label || t("label." + name)}
			min={props.min}
			max={props.max}
			step={props.step}
			maxLength={props.maxLength}
			message={message}
			preventMessageSubmit={preventMessageSubmit}
			required={required}
			pattern={props.pattern}
			type={props.type}
			inputMode={props.inputMode}
			errorMessage={props.errorMessage}
		/>
	);
};

type InputTypes = "text" | "email" | "number" | "password" | "date" | "time";

export interface HookFormTypedInputProps<
	T,
	InputType extends InputTypes,
	Request extends HookFormRequestUpdater<any>,
	Name extends TypedKeys<T, Request["values"]> & string
> extends InputProps {
	formState: Request;
	name: Name;
	type: InputType;
	t: Translator;
	onChange?: (value: T) => void;
}

/*********************************************************
 * Text input
 *********************************************************/
export const textConverter: Converter<string, string> = {
	into(value: string): string {
		return value;
	},
	from(value: string): string {
		return value;
	},
};

export const HookFormTextInput = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & StringKeys<Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<HookFormTypedInputProps<string, InputTypes, Request, Name>>
): React.ReactElement => <HookFormInput {...props} converter={textConverter} />;

/*********************************************************
 * Number input
 *********************************************************/
function getNumberConverter(
	min?: string | number,
	max?: string | number
): Converter<number | undefined, string> {
	let minValue = typeof min === "string" ? Number(min) : min;
	minValue = Number.isNaN(minValue) ? undefined : minValue;

	let maxValue = typeof max === "string" ? Number(max) : max;
	maxValue = Number.isNaN(maxValue) ? undefined : maxValue;

	return {
		into(value: number | undefined): string {
			return value !== undefined ? value.toString(10) : "";
		},
		from(value: string): Result<number | undefined> {
			if (value === "") {
				return undefined;
			}
			let res = Number(value);

			if (minValue !== undefined && res < minValue) {
				return new TranslatableError("error.number_less_than_min", [minValue]);
			}
			if (maxValue !== undefined && res > maxValue) {
				return new TranslatableError("error.number_more_than_max", [maxValue]);
			}

			return Number.isNaN(res) ? new TranslatableError("error.invalid_number", [value]) : res;
		},
	};
}

const integerMask = (input: string) => {
	const match = input.match(/^(?:[1-9]\d*|0)?(?:e|e[1-9]\d*|e0)?$/i);
	return match?.length ? match[0] : undefined;
};

const floatMask = (input: string) => {
	const match = input.match(/^(?:[1-9]\d*|0)?(?:\.\d*)?(?:e-?|e-?[1-9]\d*|e0)?$/i);
	return match?.length ? match[0] : undefined;
};

// NOTE: input type="text" is used because of the HTML spec. When users enter invalid number in type="number",
//       the browser will return onChange value as "". We won't be able to tell between cleared vs invalid input.
//       As compensation for mobile users, we will use inputMode="numeric" so they get the numbers keyboard.

export const HookFormIntegerInput = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<number | undefined, Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<
		HookFormTypedInputProps<number | undefined, "number", Request, Name>
	>
): React.ReactElement => {
	const converter = useMemo(
		() => getNumberConverter(props.min, props.max),
		[props.min, props.max]
	);

	return (
		<HookFormInput
			{...props}
			converter={converter}
			type="text"
			mask={integerMask}
			inputMode="numeric"
		/>
	);
};

export const HookFormFloatInput = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<number | undefined, Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<
		HookFormTypedInputProps<number | undefined, "number", Request, Name>
	>
): React.ReactElement => {
	const converter = useMemo(
		() => getNumberConverter(props.min, props.max),
		[props.min, props.max]
	);
	const step = props.step || "any"; // or else will be invalid in Chrome

	return (
		<HookFormInput
			{...props}
			converter={converter}
			type="text"
			mask={floatMask}
			inputMode="numeric"
			step={step}
		/>
	);
};

/*********************************************************
 * Date input
 *********************************************************/
export const dateConverter: Converter<number | undefined, string> = {
	into(value: number | undefined): string {
		if (value === undefined) {
			return "";
		}

		const date = new Date(value * 1000);

		// NOTE: browser datetime input is very picky, many need YYYY-MM-DD format
		return (
			date.getUTCFullYear() +
			"-" +
			datetime.leadingZero(date.getUTCMonth() + 1) +
			"-" +
			datetime.leadingZero(date.getUTCDate())
		);
	},
	from(value: string): Result<number | undefined> {
		if (value === "") {
			return undefined;
		}

		const date = new Date(value);
		return !Number.isNaN(date.valueOf())
			? date.valueOf() / 1000
			: new TranslatableError("error.invalid_date", [value]);
	},
};

export const HookFormDateInput = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<number | undefined, Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<
		HookFormTypedInputProps<number | undefined, "date", Request, Name>
	>
): React.ReactElement => <HookFormInput {...props} converter={dateConverter} />;

/*********************************************************
 * Currency input
 *
 * The currency input takes in integer input already
 * multiplied by 100 and output in same format.
 *
 * Numbers are displayed after dividing them by 100
 *********************************************************/
function getCurrencyConverter(
	min?: string | number,
	max?: string | number
): Converter<number | undefined, string> {
	let minValue = typeof min === "string" ? Number(min) : min;
	minValue = Number.isNaN(minValue) ? undefined : minValue;
	const minValue100 = minValue ? minValue * 100 : minValue;

	let maxValue = typeof max === "string" ? Number(max) : max;
	maxValue = Number.isNaN(maxValue) ? undefined : maxValue;
	const maxValue100 = maxValue ? maxValue * 100 : maxValue;

	return {
		into(value: number | undefined): string {
			return typeof value === "number" ? (value / 100).toFixed(2) : "";
		},
		from(value: string): Result<number | undefined> {
			if (value === "") {
				return undefined;
			}
			let res = Number(value) * 100;

			if (minValue100 !== undefined && res < minValue100) {
				return new TranslatableError("error.number_less_than_min", [minValue?.toFixed(2)]);
			}
			if (maxValue100 !== undefined && res > maxValue100) {
				return new TranslatableError("error.number_more_than_max", [maxValue?.toFixed(2)]);
			}

			return Number.isNaN(res) ? new TranslatableError("error.invalid_number", [value]) : res;
		},
	};
}

const separator = `\\${getDecimalSeparator()}`;

const currencyMask = (input: string) => {
	const pattern = new RegExp("^(?:[1-9]\\d*|0)?(?:" + separator + "\\d{0,2})?$");
	const match = input.match(pattern);
	return match?.length ? match[0] : undefined;
};

export const HookformCurrencyInput = <
	Request extends HookFormRequestUpdater<any>,
	Name extends string & TypedKeys<number | undefined, Request["values"]> = any // `any` is needed here for sane error mesages
>(
	props: React.PropsWithChildren<
		HookFormTypedInputProps<number | undefined, "number", Request, Name>
	>
): React.ReactElement => {
	const converter = useMemo(
		() => getCurrencyConverter(props.min, props.max),
		[props.min, props.max]
	);

	return (
		<HookFormInput
			{...props}
			converter={converter}
			type="text"
			mask={currencyMask}
			inputMode="numeric"
		/>
	);
};
