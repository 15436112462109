import { api, debugLog, isError, urls } from "cfg-base";
import React, { useEffect } from "react";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import { showOverlay } from "../atoms/overlay";
import { Container } from "../grid/Container";
import { useSqlLogs } from "../hooks/useSqlLogs";
import { FilterInput } from "../molecules/FilterInput";

interface DevBarProps {
	t: Translator;
}

const HAS_TEST_BAR_CLASS = "has-test-bar";
const GROUPS = ["webteam", "cet", "configura", "instructors", "core", "test"];

export function DevBar(props: DevBarProps) {
	const sqlLogs = useSqlLogs();
	const pageLoadSQL = sqlLogs.find((sqlInfo) => !!sqlInfo.ajax === false)?.logs[0];
	const latestAJAXSQL = sqlLogs.filter((sqlInfo) => !!sqlInfo.ajax).slice(-1)[0]?.logs[0];

	function handleClick(event: any) {
		event.preventDefault();

		showOverlay(event, <SwitchUserList t={props.t} />);
	}

	useEffect(() => {
		const loginAsBar = document.getElementById("logged-in-bar");
		if (!loginAsBar) {
			const pageContainer =
				document.getElementById("page-container") ||
				document.getElementById("background-container");
			if (pageContainer) {
				pageContainer.classList.add(HAS_TEST_BAR_CLASS);
			}
		} else {
			document.body.classList.add("developer-mode");
		}
	}, []);

	return (
		<div className="phoenix-base ut-bg-light-gray ut-text-center test-bar">
			<span>
				SQL Queries:{" "}
				{pageLoadSQL &&
					`${pageLoadSQL.numbers} (${(pageLoadSQL.totalexecutiontime * 1000).toFixed(
						2
					)})`}{" "}
				{latestAJAXSQL &&
					`| AJAX SQL Queries: ${latestAJAXSQL.numbers} (${(
						latestAJAXSQL.totalexecutiontime * 1000
					).toFixed(2)})`}{" "}
				|{" "}
				<a href="#" onClick={handleClick}>
					Switch User
				</a>{" "}
				| <a href="/my/debug">CBB Debug</a>
			</span>
		</div>
	);
}

interface SwitchUserListState {
	hidden?: boolean;
	data?: any;
	filtered?: any;
	scrolled: boolean;
}

interface SwitchUserListProps {
	t: Translator;
}

class SwitchUserList extends React.Component<SwitchUserListProps, SwitchUserListState> {
	state: SwitchUserListState = {
		hidden: false,
		scrolled: false,
	};

	componentDidMount() {
		this.loadAdmins();
		document.addEventListener("keydown", this.handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyDown);
	}

	handleKeyDown = (event: KeyboardEvent) => {
		const code = event.keyCode || event.charCode;
		if (code === 27) {
			this.hide();
		}
	};

	loadAdmins = async () => {
		const response = await api.call("dev/list-admins");

		if (isError(response)) {
			debugLog("loading admins failed");
			return;
		}

		this.setState({ data: [response.data] });
	};

	closeHandler = (event: any) => {
		event.preventDefault();
		this.hide();
	};

	hide = () => {
		this.setState({ hidden: true });
		document.body.style.overflow = "initial";
	};

	handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
		const scrollTop = event.currentTarget.scrollTop;
		if (scrollTop > 10 && !this.state.scrolled) {
			this.setState({
				scrolled: true,
			});
		} else if (scrollTop < 10 && this.state.scrolled) {
			this.setState({
				scrolled: false,
			});
		}
	};

	dataCallback = (filtered: Readonly<any>) => {
		this.setState({ filtered: filtered[0] });
	};

	mapCallback = (value: string, filter: Readonly<any>) => {
		const filtered = {} as any;
		for (const group in filter) {
			if (filter.hasOwnProperty(group)) {
				filtered[group] = filter[group].filter((admin: any) => {
					return (
						(admin.firstname && admin.firstname.toLocaleLowerCase().includes(value)) ||
						(admin.lastname && admin.lastname.toLocaleLowerCase().includes(value)) ||
						(admin.manufacturer &&
							admin.manufacturer.toLocaleLowerCase().includes(value))
					);
				});
			}
		}
		return filtered;
	};

	render() {
		const t = this.props.t;
		const { hidden, data, scrolled, filtered } = this.state;
		let hiddenClass;
		if (hidden) {
			hiddenClass = "mod-hidden";
		}
		let searchBarClass = "";
		if (scrolled) {
			searchBarClass = " mod-elevated";
		}

		return (
			<div className={"react-search-myconfigura " + hiddenClass}>
				{!data && (
					<Container>
						<LoadingSpinner color="black" />
					</Container>
				)}
				{data && (
					<>
						<div className={"search-bar-container" + searchBarClass}>
							<Container>
								<div className="search-header">
									<h1 className="search-title">Search Admins</h1>
									<a
										href="#"
										className="close-search"
										onClick={this.closeHandler}
									>
										<i className="svg-icon mod-black-svg mod-cross" />
									</a>
								</div>
								<div className="form-search">
									<FilterInput
										data={data}
										dataCallback={this.dataCallback}
										mapCallback={this.mapCallback}
										t={t}
									/>
								</div>
							</Container>
						</div>
						{filtered && (
							<div className="search-info-container" onScroll={this.handleScroll}>
								<Container>
									{GROUPS.map(
										(group) =>
											filtered[group] && (
												<div key={group}>
													<h2 className="search-info-container-title">
														{t("label.dev_" + group)}
													</h2>
													<ul className="ut-margin-left-0">
														{filtered[group].map(
															(admin: any, index: number) => (
																<li key={index}>
																	{" "}
																	<a
																		href={urls.apiURL(
																			`dev/login/${admin.email}`
																		)}
																	>
																		<strong>{`${admin.firstname} ${admin.lastname}`}</strong>
																		{admin.manufacturer &&
																			` (${admin.manufacturer})`}
																	</a>
																</li>
															)
														)}
													</ul>
												</div>
											)
									)}
								</Container>
							</div>
						)}
					</>
				)}
			</div>
		);
	}
}
