import React from "react";

interface Props {
	text: string;
	search?: string;
}

export function Highlight(props: Props) {
	const { text, search } = props;
	if (!search) {
		return <>{text}</>;
	}

	const trimmedSearch = search.replace(/(\s)/, "");
	const trimmedText = text.replace(/(\s)/, "");
	const index = trimmedText.toLocaleLowerCase().indexOf(trimmedSearch.toLocaleLowerCase());
	if (index === -1 || trimmedSearch.length === 0) {
		return <>{text}</>;
	}
	const length = search.length;

	return (
		<>
			{text.slice(0, index)}
			<span style={{ background: "yellow" }}>{text.slice(index, index + length)}</span>
			{text.slice(index + length)}
		</>
	);
}
