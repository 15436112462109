import { classNames } from "cfg-base";
import React from "react";

interface Props {
	filled?: boolean;
	color?: "orange" | "blue" | "yellow" | "green";
	text: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	t?: Translator;
}

export const FilterTag: React.FC<Props> = (props) => {
	const { filled, color = "orange", onClick, text, t } = props;

	const classes = classNames(["tag", `tag-${color}`, filled && "tag-filled"]);

	return (
		<button className={classes} onClick={onClick} type="button">
			{t ? t(text) : text}
		</button>
	);
};
