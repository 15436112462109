import { cookies, urls } from "cfg-base";
import React from "react";
import { Button } from "../atoms/Button";
import { Input } from "../atoms/Input";
import { Container } from "../grid/Container";

interface TestBarState {
	testEmail?: string;
	testPasscode?: string;
	showForm: boolean;
}

const { getCookieDomain } = urls;

const HAS_TEST_BAR_CLASS = "has-test-bar";

export class TestBar extends React.Component<{}, TestBarState> {
	state: TestBarState = { testPasscode: "", testEmail: "", showForm: false };

	onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			testEmail: event.target.value,
		});

		cookies.setMyConfiguraCookie(
			cookies.NAMES.MYCONFIGURA_TEST_MODE_EMAIL,
			event.target.value,
			1,
			getCookieDomain()
		);
	};

	onPasscodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			testPasscode: event.target.value,
		});

		cookies.setMyConfiguraCookie(
			cookies.NAMES.MYCONFIGURA_TEST_MODE_PASSCODE,
			event.target.value,
			1,
			getCookieDomain()
		);
	};

	componentDidMount() {
		const email = cookies.getMyConfiguraCookie(cookies.NAMES.MYCONFIGURA_TEST_MODE_EMAIL) || "";
		const passCode =
			cookies.getMyConfiguraCookie(cookies.NAMES.MYCONFIGURA_TEST_MODE_PASSCODE) || "";

		const pageContainer = document.getElementById("page-container");
		if (pageContainer) {
			pageContainer.classList.add(HAS_TEST_BAR_CLASS);
		}

		this.setState({
			testEmail: email,
			testPasscode: passCode,
		});
	}

	toggleForm = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.setState({ showForm: !this.state.showForm });
	};

	render() {
		const { showForm, testEmail } = this.state;
		return (
			<div className="phoenix-base ut-bg-light-gray test-bar">
				<Button
					visualType="primary"
					className="mod-expand"
					size="sm"
					onClick={this.toggleForm}
					text={showForm ? "Hide test settings" : "Show test settings"}
				/>
				<Container>
					{showForm && (
						<div className="phoenix-forms test-bar-settings">
							<div className="row">
								<div className="col">
									<Input
										label={"Test Email Address"}
										name="email"
										onChange={this.onEmailChange}
										required={true}
										type="email"
										value={this.state.testEmail}
									/>
								</div>
								<div className="col">
									<Input
										label={"Pass Code"}
										name="passcode"
										onChange={this.onPasscodeChange}
										required={true}
										type="password"
										value={this.state.testPasscode}
									/>
								</div>
							</div>
						</div>
					)}
				</Container>
			</div>
		);
	}
}
